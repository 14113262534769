<div class="label-and-hint">
  <label [attr.for]="_control?.id" [attr.aria-owns]="_control?.id" [class.visually-hidden]="!showLabel">
    <ng-content select="glxy-label" />

    @if (requiredState && !hideRequiredLabel) {
      <span class="label-required" [class.required-has-error]="hasInputRequiredError">
        {{ 'GALAXY.INPUT.CORE.REQUIRED' | translate }}
      </span>
    }
  </label>
  <ng-content select="glxy-label-hint" />
</div>

<div class="input-and-hints">
  <div class="color-input">
    <ng-content select="input[type='color']" />
  </div>
  <div class="input-wrapper">
    <!-- Prefixes -->
    @if (prefixText) {
      <div class="prefix-text">{{ prefixText | translate }}</div>
    }

    @if (prefixIcon) {
      <mat-icon inline="true" class="prefix-icon">
        {{ prefixIcon }}
      </mat-icon>
    }

    <ng-content select="glxy-prefix, [glxyPrefix], mat-prefix, [matPrefix]" />

    <!-- Inputs -->
    <ng-content
      select="input, select, mat-select, mat-chip-list, mat-chip-grid, textarea, div[contenteditable], mat-date-range-input, mat-date-range-picker, [insideInputWrapper], glxy-tags-input"
    />

    <!-- Suffixes -->
    @if (suffixIcon) {
      <mat-icon inline="true" class="suffix-icon">
        {{ suffixIcon }}
      </mat-icon>
    }

    @if (suffixText) {
      <div class="suffix-text">{{ suffixText | translate }}</div>
    }

    <ng-content select="glxy-suffix, [glxySuffix], mat-suffix, [matSuffix]" />
  </div>

  <div class="multi-control-wrapper">
    <ng-content select="mat-radio-group, mat-checkbox, mat-slider, [insideMultiControlWrapper]" />
  </div>

  <div class="general-content-wrapper">
    <ng-content />
  </div>

  <ng-content select="glxy-hint" />
  <ng-content select="glxy-error" />

  <!-- Initial attempt at built-in `requrired` error -->
  <!-- <glxy-error *ngIf="
    this._control?.ngControl?.control?.hasError('required')
    && !this._control?.ngControl?.control?.untouched
  ">
  {{ 'GALAXY.INPUT.CORE.VALIDATION_ERROR_REQ' | translate }}
</glxy-error> -->
</div>
