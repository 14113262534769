import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ProjectMilestonesComponent } from './project-milestones.component';
import { VaIconModule } from './../va-icon/icon.module';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressBarModule,
    VaIconModule,
    MarkdownModule,
  ],
  declarations: [ProjectMilestonesComponent],
  exports: [ProjectMilestonesComponent, MarkdownModule],
})
export class ProjectMilestonesModule {}
