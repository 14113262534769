import { Injectable } from '@angular/core';
import { Filters } from '../va-filter/filters/filter';
import { Breadcrumbs } from '../va-breadcrumbs/va-breadcrumbs';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map, filter, shareReplay } from 'rxjs/operators';
import { VaProductNavBadges } from './va-product-badges';
import { ActionButton } from './action-button';
import { VaProductNavItem } from './va-product-nav-item';
import { PageInformation } from './page-information';

@Injectable()
export class VaProductNavService {
  private filters$$ = new BehaviorSubject<Filters | null>(null);
  private infoSidebar$$ = new BehaviorSubject<PageInformation[] | null>(null);
  private primaryActionButton$$ = new BehaviorSubject<ActionButton | null>(null);
  private secondaryActionButton$$ = new BehaviorSubject<ActionButton | null>(null);
  private breadcrumbs$$: BehaviorSubject<Breadcrumbs[]> = new BehaviorSubject<Breadcrumbs[]>([]);
  private navBadges$$: BehaviorSubject<VaProductNavBadges> = new BehaviorSubject<VaProductNavBadges>({});
  private search$$ = new BehaviorSubject<string | null>(null);
  private showInfoIcon$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _navItems: VaProductNavItem[];
  private scroll$$ = new BehaviorSubject<Event | null>(null);
  private toggleNav$$: Subject<null> = new Subject<null>();

  constructor(private router: Router) {}

  isSelected(navItem: VaProductNavItem, isChild: boolean): boolean {
    if (navItem.children && navItem.children.length > 0) {
      navItem.children.forEach((child) => (child.selected = this.isSelected(child, true)));
      return navItem.children.find((ch) => ch.selected) != null;
    } else {
      return this.router.isActive(navItem.url, isChild);
    }
  }

  setNavItems(navItems: VaProductNavItem[]): void {
    this._navItems = navItems;
    this.navItems.map((item) => {
      item.selected = this.isSelected(item, false);
      return item;
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() =>
          this.navItems.map((item) => {
            item.selected = this.isSelected(item, false);
          }),
        ),
      )
      .subscribe();
  }

  setFilters(filters: Filters | null): void {
    this.filters$$.next(filters);
  }

  setInfoSidebar(pageInfo: PageInformation | PageInformation[] | null): void {
    if (pageInfo === null) {
      pageInfo = [];
    } else if (!Array.isArray(pageInfo)) {
      pageInfo = [pageInfo];
    }
    this.infoSidebar$$.next(pageInfo);
  }

  setPrimaryActionButton(actionButton: ActionButton | null): void {
    this.primaryActionButton$$.next(actionButton);
  }

  setSecondaryActionButton(actionButton: ActionButton | null): void {
    this.secondaryActionButton$$.next(actionButton);
  }

  setBreadcrumbs(breadcrumbs: Breadcrumbs[]): void {
    this.breadcrumbs$$.next(breadcrumbs);
  }

  setNavBadges(navBadges: VaProductNavBadges): void {
    this.navBadges$$.next(navBadges);
  }

  setSearch(search: string | null): void {
    this.search$$.next(search);
  }

  setShowInfoIcon(flag: boolean): void {
    this.showInfoIcon$$.next(flag);
  }

  setScroll(scrollEvent: Event): void {
    this.scroll$$.next(scrollEvent);
  }

  toggleNav(): void {
    this.toggleNav$$.next(null);
  }

  get navItems(): VaProductNavItem[] {
    return this._navItems;
  }

  get breadcrumbs$(): Observable<Breadcrumbs[]> {
    return this.breadcrumbs$$.asObservable();
  }

  get primaryActionButton$(): Observable<ActionButton | null> {
    return this.primaryActionButton$$.asObservable();
  }

  get secondaryActionButton$(): Observable<ActionButton | null> {
    return this.secondaryActionButton$$.asObservable();
  }

  get infoSidebar$(): Observable<PageInformation[] | null> {
    return this.infoSidebar$$.asObservable();
  }

  get filters$(): Observable<Filters | null> {
    return this.filters$$.asObservable();
  }

  get navBadges$(): Observable<VaProductNavBadges> {
    return this.navBadges$$.asObservable();
  }

  get search$(): Observable<string | null> {
    return this.search$$.asObservable();
  }

  get showInfoIcon$(): Observable<boolean> {
    return this.showInfoIcon$$.asObservable();
  }

  get scroll$(): Observable<Event | null> {
    return this.scroll$$.asObservable().pipe(shareReplay(1));
  }

  get toggleNav$(): Observable<boolean | null> {
    return this.toggleNav$$.pipe(map((value) => value));
  }
}
