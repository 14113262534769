import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert.component';
export { AlertComponent, AlertTypes } from './alert.component';
export { AlertExtendedDirective } from './directives/alert-extended.directive';

import { AlertExtendedDirective } from './directives/alert-extended.directive';

export const MODULE_IMPORTS = [CommonModule, MatIconModule, MatButtonModule, RouterModule];

export const MODULE_DECLARATIONS = [AlertComponent, AlertExtendedDirective];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  exports: [AlertComponent, AlertExtendedDirective],
  imports: MODULE_IMPORTS,
})
export class GalaxyAlertModule {}
