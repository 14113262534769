import { Injectable } from '@angular/core';
import { ColumnDefinition, ColumnInfo, GroupDefinition, GroupInfo, Pinned } from './advanced-column-organizer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AdvancedColumnOrganizerService {
  private searchTerm$$ = new BehaviorSubject<string>('');
  searchTerm$: Observable<string> = this.searchTerm$$.asObservable();

  private columnDefs$$ = new BehaviorSubject<ColumnDefinition[]>([]);
  columnDefs$: Observable<ColumnDefinition[]> = this.columnDefs$$.asObservable();

  private groupDefs$$ = new BehaviorSubject<GroupDefinition[]>([]);
  groupDefs$: Observable<GroupDefinition[]> = this.groupDefs$$.asObservable();

  private selectedOrderedColumns$$ = new BehaviorSubject<string[]>([]);
  selectedOrderedColumns$: Observable<string[]> = this.selectedOrderedColumns$$.asObservable();

  columnDefsByID$: Observable<{ [id: string]: ColumnDefinition }>;
  selectedColumns$: Observable<ColumnInfo[]>;
  searchedGroups$: Observable<GroupInfo[]>;
  pinnedLeftColumns$: Observable<ColumnInfo[]>;
  pinnedRightColumns$: Observable<ColumnInfo[]>;

  translator: TranslateService;

  constructor(translator: TranslateService) {
    this.translator = translator;
    this.columnDefsByID$ = this.columnDefs$.pipe(
      map((columnDefs) => {
        const columnDefsByID: { [id: string]: ColumnDefinition } = {};
        columnDefs.map((cd) => {
          columnDefsByID[cd.id] = cd;
        });
        return columnDefsByID;
      }),
    );

    this.selectedColumns$ = this.getSelectedColumns$();
    this.searchedGroups$ = this.getSearchedGroups$();
    this.pinnedLeftColumns$ = this.getPinnedLeftColumns$();
    this.pinnedRightColumns$ = this.getPinnedRightColumns$();
  }

  configure(
    saveKey: string,
    colDefs: ColumnDefinition[],
    groupDefs: GroupDefinition[],
    useI18NTranslations: boolean,
  ): void {
    if (useI18NTranslations) {
      colDefs = (colDefs || []).map((cd) => {
        cd.displayName = this.translator.instant(cd.displayName);
        return cd;
      });
      groupDefs = (groupDefs || []).map((gd) => {
        gd.name = this.translator.instant(gd.name);
        return gd;
      });
    }

    this.columnDefs$$.next(colDefs);
    this.groupDefs$$.next(groupDefs);
    let selectedOrderedColumns: string[] = this.columnDefs$$.value
      .filter((c) => !c.pinned && !c.hidden)
      .map((c) => c.id);
    const storedConfigJson: string | null = localStorage.getItem(`${saveKey}-visible-column-ids`);
    if (storedConfigJson) {
      selectedOrderedColumns = JSON.parse(storedConfigJson);
    }
    this.selectedOrderedColumns$$.next(selectedOrderedColumns);
  }

  getPinnedLeftColumns$(): Observable<ColumnInfo[]> {
    return this.pinnedColumns$(Pinned.PINNED_LEFT);
  }

  getSelectedColumns$(): Observable<ColumnInfo[]> {
    return combineLatest([this.selectedOrderedColumns$, this.columnDefsByID$]).pipe(
      map(([selectedColumnIDs, keyedColumns]): ColumnInfo[] => {
        return selectedColumnIDs.map((id: string): ColumnInfo => {
          return {
            id: id,
            name: keyedColumns[id].displayName,
            isVisible: !keyedColumns[id]?.hidden,
            pinned: Pinned.PINNED_UNSET,
          };
        });
      }),
    );
  }

  getPinnedRightColumns$(): Observable<ColumnInfo[]> {
    return this.pinnedColumns$(Pinned.PINNED_RIGHT);
  }

  pinnedColumns$(pinSide: Pinned): Observable<ColumnInfo[]> {
    return this.columnDefs$.pipe(
      map((columnDefs: ColumnDefinition[]): ColumnInfo[] => {
        const pinnedColumns = columnDefs.filter((columnDef) => columnDef.pinned === pinSide);
        return pinnedColumns.map(
          (columnDef: ColumnDefinition): ColumnInfo => ({
            id: columnDef.id,
            name: columnDef.displayName,
            isVisible: true,
            pinned: pinSide,
          }),
        );
      }),
    );
  }

  getSearchedGroups$(): Observable<GroupInfo[]> {
    return combineLatest([
      this.groupDefs$,
      this.searchTerm$,
      this.selectedOrderedColumns$,
      this.columnDefsByID$,
      this.columnDefs$,
    ]).pipe(
      map(([groupDefs, searchTerm, selectedOrderedColumns, keyedColumns, allColumns]) => {
        if (!groupDefs || groupDefs.length <= 0) {
          groupDefs = [
            {
              name: this.translator.instant('FRONTEND.UIKIT.VA_MAT_TABLE.COLUMNS'),
              id: 'allColumns',
              columns: allColumns.map((c) => c.id),
            },
          ];
        }
        const groups: GroupInfo[] = groupDefs.map(
          (groupDef: GroupDefinition): GroupInfo => ({
            id: groupDef.id,
            name: groupDef.name,
            columns: groupDef.columns.map(
              (id: string): ColumnInfo => ({
                id: id,
                name: keyedColumns[id].displayName,
                isVisible:
                  selectedOrderedColumns.indexOf(id) !== -1 ||
                  keyedColumns[id].pinned === Pinned.PINNED_LEFT ||
                  keyedColumns[id].pinned === Pinned.PINNED_RIGHT,
                pinned: keyedColumns[id].pinned,
              }),
            ),
          }),
        );
        return this.filterGroupsBySearchTerm(groups, searchTerm);
      }),
    );
  }

  updateSearchTerm(st: string): void {
    this.searchTerm$$.next(st);
  }

  filterGroupsBySearchTerm(groups: GroupInfo[], searchTerm: string): GroupInfo[] {
    const columnMatchesSearchTerm = (column, st) => column.name.toLowerCase().indexOf(st.toLowerCase()) !== -1;
    if (!searchTerm) {
      return groups;
    }
    return groups
      .filter((group) => {
        return group.columns.some((column) => columnMatchesSearchTerm(column, searchTerm));
      })
      .map(
        (group: GroupInfo): GroupInfo => ({
          ...group,
          columns: group.columns.filter((column) => columnMatchesSearchTerm(column, searchTerm)),
        }),
      );
  }

  setColumnVisibility(id: string, isVisible: boolean): void {
    const cols = this.selectedOrderedColumns$$.value;
    const index = cols.indexOf(id);
    if (isVisible) {
      if (index === -1) {
        cols.push(id);
      }
    } else {
      if (index !== -1) {
        cols.splice(index, 1);
      }
    }
    this.selectedOrderedColumns$$.next(cols);
  }

  save(saveName: string): void {
    if (!localStorage) {
      return;
    }

    if (saveName) {
      const selectedOrderedColumns: string[] = this.selectedOrderedColumns$$.getValue();
      localStorage.setItem(`${saveName}-visible-column-ids`, JSON.stringify(selectedOrderedColumns));
    }
  }

  selectAll(columnGroup: GroupInfo): void {
    columnGroup.columns
      .filter((column) => !column.pinned)
      .map((column) => {
        this.setColumnVisibility(column.id, true);
      });
  }

  deselectAll(columnGroup: GroupInfo): void {
    columnGroup.columns
      .filter((column) => !column.pinned)
      .map((column) => {
        this.setColumnVisibility(column.id, false);
      });
  }

  columnDroppedInList(prevIndex: number, curIndex: number): void {
    const columns: string[] = this.selectedOrderedColumns$$.getValue();
    moveItemInArray(columns, prevIndex, curIndex);
    this.selectedOrderedColumns$$.next(columns);
  }

  getSelectedColumns(): string[] {
    return this.selectedOrderedColumns$$.value;
  }
}
