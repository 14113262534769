<label
  class="atlas-navbar__checkbox__layout"
  [class.atlas-navbar__checkbox--disabled]="disabled"
>
  <div class="atlas-navbar__checkbox__container">
    <div
      class="atlas-navbar__checkbox__inner-container"
      [class.atlas-navbar__checkbox--checked]="value"
      [class.atlas-navbar__checkbox--default]="color === 'default'"
      [class.atlas-navbar__checkbox--primary]="color === 'primary'"
      [class.atlas-navbar__checkbox--secondary]="color === 'secondary'"
    >
      <div class="atlas-navbar__checkbox__overlay"></div>
      <input
        class="atlas-navbar__checkbox__input"
        type="checkbox"
        [checked]="value"
        (change)="value = !value"
        [disabled]="disabled"
      />
      <div class="atlas-navbar__checkbox__checkbox-frame"></div>
      <div class="atlas-navbar__checkbox__checkbox-background">
        <svg
          xml:space="preserve"
          class="atlas-navbar__checkbox__checkmark"
          focusable="false"
          version="1.1"
          viewBox="0 0 24 24"
        >
          <path
            class="atlas-navbar__checkbox__checkmark__path"
            d="M4.1,12.7 9,17.6 20.3,6.3"
            fill="none"
            stroke="white"
          ></path>
        </svg>
        <div class="atlas-navbar__checkbox__mixedmark"></div>
      </div>
    </div>
  </div>
  <span class="atlas-navbar__checkbox__label">
    <ng-content></ng-content>
  </span>
</label>
