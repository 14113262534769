import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaSearchSelectModule } from '../va-search-select/va-search-select.module';
import { ItemsAndStatusComponent } from './va-items-and-status.component';

@NgModule({
  imports: [CommonModule, VaSearchSelectModule],
  declarations: [ItemsAndStatusComponent],
  exports: [ItemsAndStatusComponent],
})
export class VaItemsAndStatusModule {}
