{
  "FRONTEND": {
    "UIKIT": {
      "VA_FILTER": {
        "FILTERS": "Filters",
        "EXPORT_FILTER": "Export filter",
        "IMPORT_THIS_STRING": "Import this string to apply your current filters:",
        "COPY_TO_CLIPBOARD": "Copy to clipboard",
        "IMPORT_FILTER": "Import filter",
        "PASTE_YOUR_FILTER_STRING": "Paste your filter string to apply its filters",
        "APPLY": "Apply",
        "MANAGE_YOUR_SAVED_FILTERS": "Manage your saved filters",
        "CHOOSE_FILTER_NAME": "Choose a name for your filter",
        "SAVE_YOUR_FILTER": "Save your filter",
        "SAVE_THIS_FILTER": "Save this filter",
        "YOUR_BOOKMARKED_FILTERS": "Your bookmarked filters",
        "MANAGE_FILTERS": "Manage filters"
      },
      "VA_MAT_TABLE": {
        "COLUMNS": "Columns"
      }
    },
    "DATE_RANGE_SELECTOR": {
      "FILTERS": {
        "DATE": {
          "SEVEN_DAYS": "Last 7 days",
          "THIRTY_DAYS": "Last 30 days",
          "NINETY_DAYS": "Last 90 days",
          "SIX_MONTHS": "Last 6 months",
          "TWELVE_MONTHS": "Last 12 months",
          "CUSTOM": "Custom",
          "MONTH": "Month",
          "QUARTER": "Quarter",
          "CHIP": "Date"
        },
        "QUARTER": {
          "FIRST_QUARTER": "Q1",
          "SECOND_QUARTER": "Q2",
          "THIRD_QUARTER": "Q3",
          "FOURTH_QUARTER": "Q4"
        }
      },
      "COMMON": {
        "ACTION_LABELS": {
          "CANCEL": "Cancel",
          "APPLY": "Apply"
        }
      },
      "TITLE": "Selected Date Range",
      "DATE_PICKER": {
        "START_DATE": "Start date",
        "END_DATE": "End date"
      },
      "COMPARE_TO": "Compare to",
      "PERIOD": "Period"
    }
  }
}
