import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UpgradeCTADialogComponent } from './get-pro-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
  ],
  exports: [UpgradeCTADialogComponent],
  declarations: [UpgradeCTADialogComponent],
  providers: [],
})
export class UpgradeCTADialogModule {}
