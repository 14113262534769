import { Component, Inject } from '@angular/core';
import { AdvancedColumnOrganizerService } from './advanced-column-organizer.service';
import { GroupInfo, ColumnInfo, ColumnDefinition, GroupDefinition } from './advanced-column-organizer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

// @dynamic
@Component({
  selector: 'va-mat-table-advanced-column-organizer',
  templateUrl: './advanced-column-organizer.component.html',
  styleUrls: ['./advanced-column-organizer.component.scss'],
  providers: [AdvancedColumnOrganizerService],
})
export class AdvancedColumnOrganizerComponent {
  saveName: string;
  activeTab: string | null;
  selectedColumns: string[];

  constructor(
    private advancedColumnOrganizerService: AdvancedColumnOrganizerService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      saveName: string;
      columnConfigs: ColumnDefinition[];
      groupConfigs: GroupDefinition[];
      useI18NTranslations: boolean;
    },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<AdvancedColumnOrganizerComponent>,
  ) {
    this.saveName = dialogData.saveName;
    this.loadColumns();
    this.getActiveTab();
  }

  get selectedColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.selectedColumns$;
  }

  get pinnedLeftColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.pinnedLeftColumns$;
  }

  get pinnedRightColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.pinnedRightColumns$;
  }

  get searchedGroups$(): Observable<GroupInfo[]> {
    return this.advancedColumnOrganizerService.searchedGroups$;
  }

  get searchTerm$(): Observable<string> {
    return this.advancedColumnOrganizerService.searchTerm$;
  }

  updateSearchTerm(event: string): void {
    this.advancedColumnOrganizerService.updateSearchTerm(event);
  }

  setColumnVisibility(id: string, isVisible: boolean): void {
    this.advancedColumnOrganizerService.setColumnVisibility(id, isVisible);
  }

  columnDroppedInList(event: CdkDragDrop<any>): void {
    this.advancedColumnOrganizerService.columnDroppedInList(event.item.data.columnIndex, event.currentIndex);
  }

  save(): void {
    this.selectedColumns = this.advancedColumnOrganizerService.getSelectedColumns();
    this.dialogRef.close(true);
    this.advancedColumnOrganizerService.save(this.saveName);
  }

  loadColumns(): void {
    this.advancedColumnOrganizerService.configure(
      this.saveName,
      this.dialogData.columnConfigs,
      this.dialogData.groupConfigs,
      this.dialogData.useI18NTranslations,
    );
  }

  selectAll(columnGroup: GroupInfo): void {
    this.advancedColumnOrganizerService.selectAll(columnGroup);
  }

  deselectAll(columnGroup: GroupInfo): void {
    this.advancedColumnOrganizerService.deselectAll(columnGroup);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isMobileScreen(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 820px)');
  }

  setActiveTab(currentTab: string): void {
    sessionStorage.setItem('columnManagerCurrentTab', currentTab);
  }
  getActiveTab(): void {
    this.activeTab = sessionStorage.getItem('columnManagerCurrentTab');
  }
}
