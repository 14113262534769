import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

import { CardStencilComponent } from './card-stencil.component';
import { CardListStencilComponent } from './card-list-stencil.component';
import { SearchBarStencilComponent } from './search-bar-stencil.component';
import { ListStencilComponent } from './list-stencil.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
  ],
  declarations: [CardStencilComponent, CardListStencilComponent, SearchBarStencilComponent, ListStencilComponent],
  exports: [CardStencilComponent, CardListStencilComponent, SearchBarStencilComponent, ListStencilComponent],
})
export class VaStencilsModule {}
