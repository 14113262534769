import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmptyStateComponent } from './empty-state.component';
export { EmptyStateComponent } from './empty-state.component';

import { EmptyStateHeroDirective } from './directives/empty-state-hero.directive';
import { EmptyStateTitleDirective } from './directives/empty-state-title.directive';
import { EmptyStateActionsDirective } from './directives/empty-state-actions.directive';
import { EmptyStateFootnoteDirective } from './directives/empty-state-footnote.directive';
export { EmptyStateHeroDirective } from './directives/empty-state-hero.directive';
export { EmptyStateTitleDirective } from './directives/empty-state-title.directive';
export { EmptyStateActionsDirective } from './directives/empty-state-actions.directive';
export { EmptyStateFootnoteDirective } from './directives/empty-state-footnote.directive';

export const MODULE_IMPORTS = [CommonModule];

export const MODULE_DECLARATIONS = [
  EmptyStateComponent,
  EmptyStateHeroDirective,
  EmptyStateTitleDirective,
  EmptyStateActionsDirective,
  EmptyStateFootnoteDirective,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: [MODULE_IMPORTS],
  exports: [
    EmptyStateComponent,
    EmptyStateHeroDirective,
    EmptyStateTitleDirective,
    EmptyStateActionsDirective,
    EmptyStateFootnoteDirective,
  ],
})
export class GalaxyEmptyStateModule {}
