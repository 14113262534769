import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  VaNestedSelectorComponent,
  VaNestedSelectorTitleComponent,
  VaNestedSelectorNestComponent,
  VaNestedSelectorNestItemComponent,
  VaHideDirective,
} from './nested-selector';

@NgModule({
  imports: [CommonModule],
  exports: [
    VaNestedSelectorComponent,
    VaNestedSelectorTitleComponent,
    VaNestedSelectorNestComponent,
    VaNestedSelectorNestItemComponent,
    VaHideDirective,
  ],
  declarations: [
    VaNestedSelectorComponent,
    VaNestedSelectorTitleComponent,
    VaNestedSelectorNestComponent,
    VaNestedSelectorNestItemComponent,
    VaHideDirective,
  ],
})
export class VaNestedSelectorModule {}
