import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonPipesModule } from './common-pipes/common-pipes.module';
import { UpgradeCTADialogModule } from './get-pro-dialog/get-pro-dialog.module';
import { VaGoalTableModule } from './goal-table/goal-table.module';
import { ProjectMilestonesModule } from './project-milestones/project-milestones.module';
import { VaSafeHtmlModule } from './safe-html/safe-html.module';
import { VaSearchBoxModule } from './search-box/va-search-box.module';
import { VaActionMenuModule } from './va-action-menu/va-action-menu.module';
import { VaBadgeModule } from './va-badge/va-badge.module';
import { VaBreadcrumbsModule } from './va-breadcrumbs/va-breadcrumbs.module';
import { VaCardsModule } from './va-cards/va-cards.module';
import { VaFilterChipsModule } from './va-filter-chips/va-filter-chips.module';
import { VaFilterModule } from './va-filter/filter.module';
import { VaIconModule } from './va-icon/icon.module';
import { VaImageGalleryComponent } from './va-image-gallery';
import { VaItemsAndStatusModule } from './va-items-and-status/va-items-and-status.module';
import { VaListModule } from './va-list/va-list.module';
import { VaMaterialTableModule } from './va-mat-table/va-mat-table.module';
import { VaMultiSelectModule } from './va-multi-select/va-multi-select.module';
import { VaNavItemListModule } from './va-nav/nav-item-list/nav-item-list.module';
import { VaNavItemModule } from './va-nav/nav-item/nav-item.module';
import { VaNavModule } from './va-nav/nav.module';
import { VaNestedSelectorModule } from './va-nested-selector/va-nested-selector.module';
import { VaProductNavModule } from './va-product-nav/va-product-nav.module';
import { VaSearchSelectModule } from './va-search-select/va-search-select.module';
import { VaStatusBannerModule } from './va-status-banner/va-status-banner.module';
import { VaStencilsModule } from './va-stencils/stencils.module';
import { VaTableModule } from './va-table/table.module';
import { WelcomeModule } from './va-welcome/welcome.module';
import { VaButtonModule } from './wrappers/va-button/va-button.module';
import { VaCheckboxModule } from './wrappers/va-checkbox/va-checkbox.module';
import { VaChipModule } from './wrappers/va-chip/va-chip.module';
import { VaExpandModule } from './wrappers/va-expand/va-expand.module';
import { VaMenuModule } from './wrappers/va-menu/va-menu.module';
import { VaMultilineModule } from './wrappers/va-multiline/va-multiline.module';
import { VaSwitchModule } from './wrappers/va-switch/va-switch.module';

@NgModule({
  imports: [
    VaCardsModule,
    CommonModule,
    VaSearchBoxModule,
    VaIconModule,
    VaTableModule,
    VaButtonModule,
    VaCheckboxModule,
    VaMenuModule,
    VaExpandModule,
    VaSwitchModule,
    VaMultilineModule,
    VaFilterModule,
    VaItemsAndStatusModule,
    VaMultiSelectModule,
    VaSearchSelectModule,
    VaChipModule,
    VaSafeHtmlModule,
    VaImageGalleryComponent,
    VaListModule,
    VaActionMenuModule,
    VaNestedSelectorModule,
    VaBreadcrumbsModule,
    VaNavModule,
    VaNavItemModule,
    VaNavItemListModule,
    VaProductNavModule,
    VaStencilsModule,
    CommonPipesModule,
    VaBadgeModule,
    UpgradeCTADialogModule,
    WelcomeModule,
    VaMaterialTableModule,
    VaFilterChipsModule,
    VaStatusBannerModule,
    ProjectMilestonesModule,
    VaGoalTableModule,
  ],
  exports: [
    VaCardsModule,
    CommonModule,
    VaSearchBoxModule,
    VaIconModule,
    VaTableModule,
    VaButtonModule,
    VaCheckboxModule,
    VaMenuModule,
    VaExpandModule,
    VaSwitchModule,
    VaMultilineModule,
    VaFilterModule,
    VaItemsAndStatusModule,
    VaMultiSelectModule,
    VaSearchSelectModule,
    VaChipModule,
    VaSafeHtmlModule,
    VaImageGalleryComponent,
    VaListModule,
    VaActionMenuModule,
    VaNestedSelectorModule,
    VaBreadcrumbsModule,
    VaNavModule,
    VaNavItemModule,
    VaNavItemListModule,
    VaProductNavModule,
    VaStencilsModule,
    CommonPipesModule,
    VaBadgeModule,
    UpgradeCTADialogModule,
    WelcomeModule,
    VaMaterialTableModule,
    VaFilterChipsModule,
    VaStatusBannerModule,
    ProjectMilestonesModule,
    VaGoalTableModule,
  ],
})
export class UIKitModule {}
