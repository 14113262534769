import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { VaFilterChipContainerComponent } from './va-filter-chip-container/va-filter-chip-container.component';
import { VaFilterChipComponent } from './va-filter-chip/va-filter-chip.component';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  declarations: [VaFilterChipContainerComponent, VaFilterChipComponent],
  exports: [VaFilterChipContainerComponent, VaFilterChipComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    CommonPipesModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule,
  ],
})
export class VaFilterChipsModule {}
