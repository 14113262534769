import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CustomUserDropdownItem } from '../user-dropdown/user-dropdown.interface';

@Injectable({ providedIn: 'root' })
export class AtlasTopBarService {
  private hideCenters$$ = new BehaviorSubject<boolean>(false);
  public hideCenters$ = this.hideCenters$$.asObservable();

  private hideNotifications$$ = new BehaviorSubject<boolean>(false);
  public hideNotifications$ = this.hideNotifications$$.asObservable();

  private hideUserDropdown$$ = new BehaviorSubject<boolean>(false);
  public hideUserDropdown$ = this.hideUserDropdown$$.asObservable();

  private dropdownItems$$ = new BehaviorSubject<CustomUserDropdownItem[]>([]);
  public dropdownItems$ = this.dropdownItems$$.asObservable();

  private hideLanguageSelector$$ = new BehaviorSubject<boolean>(false);
  public hideLanguageSelector$ = this.hideLanguageSelector$$.asObservable();

  public setCenterVisibility(visibility: boolean): void {
    this.hideCenters$$.next(!visibility);
  }

  public setNotificationsVisibility(visibility: boolean): void {
    this.hideNotifications$$.next(!visibility);
  }

  public setUserDropdownVisibility(visibility: boolean): void {
    this.hideUserDropdown$$.next(!visibility);
  }

  public setDropdownItems(dropdownItems: CustomUserDropdownItem[]): void {
    this.dropdownItems$$.next(dropdownItems);
  }

  public setLanguageSelectorVisibility(visibility: boolean): void {
    this.hideLanguageSelector$$.next(!visibility);
  }
}
