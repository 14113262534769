import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';
import { VaSearchBoxModule } from '../search-box/va-search-box.module';
import { AdvancedColumnOrganizerButtonComponent } from './actions/advanced-column-organizer/advanced-column-organizer-button.component';
import { AdvancedColumnOrganizerComponent } from './actions/advanced-column-organizer/advanced-column-organizer.compontent';
import { ColumnSorterComponent } from './actions/column-sorter/column-sorter.component';
import { VaMaterialTableComponent } from './va-mat-table.component';

@NgModule({
  declarations: [
    VaMaterialTableComponent,
    ColumnSorterComponent,
    AdvancedColumnOrganizerButtonComponent,
    AdvancedColumnOrganizerComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatCheckboxModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    MatDialogModule,
    VaSearchBoxModule,
    MatTabsModule,
  ],
  exports: [
    VaMaterialTableComponent,
    ColumnSorterComponent,
    AdvancedColumnOrganizerComponent,
    AdvancedColumnOrganizerButtonComponent,
  ],
})
export class VaMaterialTableModule {}
