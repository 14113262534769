import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GetMultiPartnerHealthResponseInterface,
  GetSalesConfigResponseInterface,
  ListPartnerHealthResponseInterface,
  ListTrialResponse,
  PartnerHealthInterface,
  PartnerHealthState,
  PartnerHealthSubState,
  SalesConfigInputsInterface,
  SetSalesConfigResponseInterface,
  TrialInterface,
} from '@vendasta/partner';

export const PartnerServiceInterfaceToken: InjectionToken<PartnerServiceInterface> =
  new InjectionToken<PartnerServiceInterface>('PartnerServiceInterface');

export interface PartnerServiceInterface {
  getPartnerId(): Observable<string>;
}

export interface PartnerTrialServiceInterface {
  createTrial(partnerId: string, subscriptionId: string, start: Date, end: Date): Observable<boolean>;
  getTrial(partnerId: string): Observable<TrialInterface>;
  cancelTrial(partnerId: string, trialId: string): Observable<boolean>;
  listTrial(partnerId: string, pageSize: number, cursor: string): Observable<ListTrialResponse>;
}

export interface PartnerHealthsServiceInterface {
  set(
    partnerId: string,
    state: PartnerHealthState,
    subState: PartnerHealthSubState,
    reason: string,
    inRetention: boolean,
  ): Observable<boolean>;
  get(partnerId: string): Observable<PartnerHealthInterface>;
  list(partnerId: string, pageSize: number, cursor: string): Observable<ListPartnerHealthResponseInterface>;
  getMulti(partnerIds: string[]): Observable<GetMultiPartnerHealthResponseInterface>;
}

export interface PartnerSalesServiceInterface {
  setConfiguration(
    partnerId: string,
    marketId: string,
    config: SalesConfigInputsInterface,
  ): Observable<SetSalesConfigResponseInterface>;
  getConfiguration(partnerId: string, marketId: string): Observable<GetSalesConfigResponseInterface>;
}
