import { Component, HostBinding } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'EXP__glxy-wrap',
  template: '<span class="galaxy-wrapped"><ng-content  /></span>',
  styleUrls: ['./galaxy-wrap-overrides.scss'],
})
export class GalaxyWrapComponent {
  @HostBinding('class') class = 'EXP__glxy-wrap';
}
