@if (notificationsEnabled$ | async) {
  <atlas-notifications
    [useNewTopBar]="useNewTopBar()"
    [userId]="dataService.userId$ | async"
    [partnerId]="configService.partnerId$ | async"
  ></atlas-notifications>
}
@if ((topbarService.hideCenters$ | async) === false) {
  <atlas-center-selector
    [showNewMenu]="useNewTopBar()"
    [centerData]="dataService.centerData$ | async"
    [serviceProviderId]="configService.serviceProviderId$ | async"
  ></atlas-center-selector>
}
@if ((hideUserDropdown$ | async) === false) {
  <atlas-user-dropdown
    [username]="dataService.username$ | async"
    [email]="dataService.email$ | async"
    [menuItems]="dataService.userData$ | async"
    [signOutUrl]="dataService.signoutUrl$ | async"
    [extraMenuItems]="topbarService.dropdownItems$ | async"
    [showLanguageSelector]="(topbarService.hideLanguageSelector$ | async) === false"
    [impersonateeUsername]="dataService.impersonateeUsername$ | async"
    [userSwitcherData]="dataService.userSwitcherData$ | async"
    [partnerName]="dataService.partnerName$ | async"
    [avatarButtonUrl]="userPic$ | async"
    [useNewProfileDropdown]="useNewTopBar()"
  ></atlas-user-dropdown>
}
