import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { VaMultiSelectComponent } from './va-multi-select.component';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    GalaxyWrapModule,
    MatSelectModule,
    MatInputModule,
    GalaxyFormFieldModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
  ],
  declarations: [VaMultiSelectComponent],
  exports: [VaMultiSelectComponent],
})
export class VaMultiSelectModule {}
