<div
  class="atlas-navbar__language-list-item__container"
  [class.atlas-navbar__language-list-item__container--current-language]="isCurrentLanguage | async"
>
  <a *ngIf="(isCurrentLanguage | async) === false" class="atlas-navbar__language-list-item" (click)="selectLanguage()">
    <ng-content *ngTemplateOutlet="item"></ng-content>
  </a>

  <div *ngIf="isCurrentLanguage | async" class="atlas-navbar__language-list-item">
    <ng-content *ngTemplateOutlet="item"></ng-content>
  </div>

  <i class="material-icons atlas-navbar__language-list-item__icon" *ngIf="isCurrentLanguage | async">check</i>
</div>

<ng-template #item>
  <i class="atlas-navbar__language-list-item__icon material-icons" *ngIf="!language.flag">language</i>
  <span class="atlas-navbar__language-language-list-item__flag" *ngIf="language?.flag">
    {{ language.flag }}
  </span>
  <div class="atlas-navbar__language-list-item__language-details" *ngIf="languageLabels$ | async as labels">
    <div class="atlas-navbar__language-list-item__language-details__target-language">
      {{ labels.target | translate }}
    </div>
    <div
      class="atlas-navbar__language-list-item__language-details__current-language"
      [ngClass]="{ promote: !labels.target }"
    >
      {{ labels.current | translate }}
    </div>
  </div>
</ng-template>
