<div class="atlas-navbar__button-container">
  <button
    class="atlas-navbar__button"
    [disabled]="loading || disabled"
    [class.atlas-navbar__button--raised]="raised"
    [class.atlas-navbar__button--loading]="loading"
    [class.atlas-navbar__button--disabled]="loading || disabled"
    [class.atlas-navbar__button--color-primary]="color === 'primary'"
    [class.atlas-navbar__button--color-secondary]="color === 'secondary'"
    (click)="onButtonClick($event)"
  >
    <ng-content></ng-content>
  </button>
  <div
    class="atlas-navbar__button__overlay"
    [class.atlas-navbar__button__overlay--disabled]="loading || disabled"
  ></div>
  <atlas-loader
    class="atlas-navbar__button__loader"
    [size]="24"
    *ngIf="loading"
  ></atlas-loader>
</div>
