import { Injectable } from '@angular/core';

export interface Storage {
  get(name: string): string | null;

  set(name: string, value: string): void;

  remove(name: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  get(name: string): string | null {
    return this.browserSupport ? localStorage.getItem(name) : null;
  }

  set(name: string, value: string): string | null {
    if (this.browserSupport) {
      localStorage.setItem(name, value);
      return value;
    } else {
      return null;
    }
  }

  remove(name: string): void {
    if (this.browserSupport) {
      localStorage.removeItem(name);
    }
  }

  get browserSupport(): boolean {
    return !!typeof Storage;
  }
}
