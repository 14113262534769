import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

const SEARCH_TERM_FILTER = 'search-term-filter';
@Injectable({ providedIn: 'root' })
export class SearchBoxService {
  constructor(private localStorageService: LocalStorageService) {}

  saveSearchTerm(contextKey: string, input: string): void {
    this.localStorageService.set(contextKey || '' + SEARCH_TERM_FILTER, input);
  }

  getSearchTerm(contextKey: string): string | null {
    return this.localStorageService.get(contextKey || '' + SEARCH_TERM_FILTER);
  }
}
