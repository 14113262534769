<div
  class="atlas-navbar__loading-ring"
  [style.height.px]="size"
  [style.width.px]="size"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
