import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';
import { UpgradeCTADialogModule } from '../get-pro-dialog/get-pro-dialog.module';
import { WelcomeComponent, WelcomeCustomActionDirective } from './welcome.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatRippleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    UpgradeCTADialogModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  declarations: [WelcomeComponent, WelcomeCustomActionDirective],
  exports: [WelcomeComponent, WelcomeCustomActionDirective],
})
export class WelcomeModule {}
