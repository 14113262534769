import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';

import { FormFieldComponent } from './form-field.component';
export { FormFieldComponent };

import { FormRowComponent } from './form-row/form-row.component';
export { FormRowComponent };

import { LabelDirective } from './directives/label';
export { LabelDirective };

import { LabelHintDirective } from './directives/label-hint';
export { LabelHintDirective };

import { HintDirective } from './directives/hint';
export { HintDirective };

import { ErrorDirective } from './directives/error';
export { ErrorDirective };

import { ExtendedDirective } from './directives/extended';
export { ExtendedDirective };

import { PrefixDirective } from './directives/prefix';
export { PrefixDirective };

import { SuffixDirective } from './directives/suffix';
export { SuffixDirective };

export const MODULE_IMPORTS = [
  CommonModule,
  MatIconModule,
  MatTooltipModule,
  FormsModule,
  TranslateModule,
  GalaxyI18NModule,
];

export const MODULE_DECLARATIONS = [
  FormFieldComponent,
  FormRowComponent,
  LabelDirective,
  LabelHintDirective,
  HintDirective,
  ErrorDirective,
  ExtendedDirective,
  SuffixDirective,
  PrefixDirective,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: MODULE_DECLARATIONS,
})
export class GalaxyFormFieldModule {}
