import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export interface SnackbarOptions {
  duration: number;
}

export interface SnackbarConfig {
  text: string;
  actionText: string;
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private visible$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public visible$: Observable<boolean> = this.visible$$.asObservable();
  private config$$: BehaviorSubject<SnackbarConfig> = new BehaviorSubject<SnackbarConfig>({ text: '', actionText: '' });
  private config$: Observable<SnackbarConfig> = this.config$$.asObservable();
  public text$: Observable<string>;
  public actionText$: Observable<string>;

  private timeoutHandle: number;

  constructor() {
    this.text$ = this.config$.pipe(
      map((config: SnackbarConfig) => config.text),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.actionText$ = this.config$.pipe(
      map((config: SnackbarConfig) => config.actionText),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  show(text: string, actionText: string, config?: SnackbarOptions): void {
    if (this.visible$$.getValue()) {
      return;
    }
    this.config$$.next({ text: text, actionText: actionText });
    this.visible$$.next(true);
    this.timeoutHandle = window.setTimeout(this.close.bind(this), config && config.duration ? config.duration : 2000);
  }

  close(): void {
    if (!this.visible$$.getValue()) {
      return;
    }
    window.clearTimeout(this.timeoutHandle);
    this.visible$$.next(false);
  }
}
