import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AtlasConfigService, AtlasDataService, AtlasLanguageService, Language } from '../../core/src';
import { NotificationsService } from './vbc_sdk/notifications.service';
import { GetSectionsResponse, SectionContainer } from './vbc_sdk/_internal/objects/notifications';

@Injectable({ providedIn: 'root' })
export class AtlasAccountNotificationService {
  public notificationSections$: Observable<SectionContainer[] | null>;
  public notificationSectionsErrors$: Observable<any>;

  constructor(
    private dataService: AtlasDataService,
    private config: AtlasConfigService,
    private languageService: AtlasLanguageService,
    private notificationsService: NotificationsService,
  ) {
    const errors$$ = new BehaviorSubject<any>(null);
    this.notificationSectionsErrors$ = errors$$;
    this.notificationSections$ = combineLatest([
      this.dataService.email$,
      this.config.partnerId$,
      this.config.accountId$,
      this.languageService.language$,
    ]).pipe(
      switchMap(([email, partnerId, accountGroupId, language]: [string, string, string, Language]) => {
        return this.notificationsService.sections$(accountGroupId, email, partnerId, language).pipe(
          // Clear any errors if we succeed
          tap(() => errors$$.next(null)),
          map((resp: GetSectionsResponse) => resp.data),
          catchError((err: any) => {
            errors$$.next(err);
            return of(null);
          }),
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
