import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BrandingV2Service } from '@galaxy/partner';
import { TranslateService } from '@ngx-translate/core';
import { HostService } from '@vendasta/atlas';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AtlasConfigService, AtlasDataService } from '../../../core/src';
import { AtlasEmailVerificationService } from './email-verification.service';
import { DateFormat } from '@vendasta/galaxy/utility/date-utils';

@Component({
  selector: 'atlas-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent {
  static EMAIL_VERIFICATION_PARAM_ID = 'open_email_verification';
  DateFormat = DateFormat;
  sendingVerificationEmail$$ = new BehaviorSubject<boolean>(false);
  sendingVerificationEmail$: Observable<boolean> = this.sendingVerificationEmail$$.asObservable();
  email$: Observable<string> = this.dataService.email$;
  partnerId$: Observable<string> = this.configService.partnerId$;
  logoURL$ = this.partnerId$.pipe(
    switchMap((partnerId) => {
      return this.brandingService.getBranding(partnerId, '').pipe(
        take(1),
        map((branding) => branding.logoUrl),
      );
    }),
  );

  dialogRef = inject(MatDialogRef<EmailVerificationComponent>);
  verificationDeadline = new Date(Date.UTC(2023, 4, 16, 0, 0, 0));

  constructor(
    private dataService: AtlasDataService,
    private configService: AtlasConfigService,
    private emailVerificationService: AtlasEmailVerificationService,
    private translationService: TranslateService,
    private snackbarService: SnackbarService,
    private brandingService: BrandingV2Service,
    private http: HttpClient,
    private hostService: HostService,
  ) {}

  sendVerificationEmail(): void {
    this.sendingVerificationEmail$$.next(true);

    combineLatest([this.email$, this.partnerId$])
      .pipe(
        take(1),
        switchMap(([email, partnerId]) =>
          this.emailVerificationService.sendEmailVerificationEmail(email, partnerId, window.location.href, ''),
        ),
        switchMap(() => this.remindMeLaterEmailVerification()),
      )
      .subscribe(
        () => {
          this.sendingVerificationEmail$$.next(false);
          this.translationService.stream('EMAIL_VERIFICATION.EMAIL_SENT').subscribe((translation) => {
            this.snackbarService.openSuccessSnack(translation);
            this.dialogRef.close();
          });
        },
        () => {
          this.sendingVerificationEmail$$.next(false);
          this.translationService.stream('COMMON.ERROR_TRY_AGAIN').subscribe((translation) => {
            this.snackbarService.openErrorSnack(translation);
            this.dialogRef.close();
          });
        },
      );
  }

  remindMeLater(): void {
    this.remindMeLaterEmailVerification().subscribe(() => {
      this.dialogRef.close();
    });
  }

  private remindMeLaterEmailVerification() {
    return this.http.post<null>(this.hostService.hostWithScheme + '/extend-email-verification-display-date', null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      observe: 'response',
    });
  }
}
