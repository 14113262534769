import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { VaNavItemListComponent } from './nav-item-list.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [VaNavItemListComponent],
  exports: [VaNavItemListComponent],
})
export class VaNavItemListModule {}
