import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export interface Config {
  serviceProviderId: string;
  partnerId: string;
  marketId: string;
  accountId: string;
  groupPath?: string;
  appId: string;
}

@Injectable({ providedIn: 'root' })
export class AtlasConfigService {
  private config$$: ReplaySubject<Config> = new ReplaySubject<Config>(1);
  public config$ = this.config$$.asObservable().pipe(
    // Wait until changes stop before emitting
    debounceTime(10),
    // Check for changes based on actual contents rather than reference equality
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    shareReplay(1),
  );

  serviceProviderId$ = this.config$.pipe(
    map((config) => config.serviceProviderId),
    distinctUntilChanged(),
    shareReplay(1),
  );

  partnerId$ = this.config$.pipe(
    map((config) => config.partnerId),
    distinctUntilChanged(),
    shareReplay(1),
  );

  accountId$ = this.config$.pipe(
    map((config) => config.accountId),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public initialize(
    serviceProviderId: string,
    partnerId: string,
    marketId: string,
    accountId: string,
    appId: string,
    groupPath: string,
  ): void {
    this.config$$.next({
      serviceProviderId: serviceProviderId,
      partnerId: partnerId,
      marketId: marketId,
      accountId: accountId,
      groupPath: groupPath,
      appId: appId,
    });
  }
}
