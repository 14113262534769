import { NgModule } from '@angular/core';
import { InternationalPhoneNumberFormatPipe, PhoneNumberFormatPipe } from './phone-number.pipe';
import { DisplayValuePipe } from './display-value.pipe';

@NgModule({
  declarations: [InternationalPhoneNumberFormatPipe, PhoneNumberFormatPipe, DisplayValuePipe],
  exports: [InternationalPhoneNumberFormatPipe, PhoneNumberFormatPipe, DisplayValuePipe],
  providers: [InternationalPhoneNumberFormatPipe, PhoneNumberFormatPipe, DisplayValuePipe],
})
export class CommonPipesModule {}
