<atlas-modal>
  <atlas-modal-header>
    {{ 'NOTIFICATIONS.TITLE' | translate }}
  </atlas-modal-header>
  <atlas-modal-content>
    <div class="atlas-navbar__settings-modal__content-container">
      <div class="atlas-navbar__settings-modal__content-container__contents">
        <atlas-tab-group (selectionChange)="setSelected($event)">
          <atlas-tab label="Personal" *ngIf="showPersonalTab$ | async">
            <atlas-notification-settings-form
              [partnerId]="partnerId$ | async"
              [userId]="userId$ | async"
              #settingsForm
            ></atlas-notification-settings-form>
          </atlas-tab>
          <atlas-tab
            label="Account"
            class="account-config-button"
            *ngIf="(showAccountsTab$ | async) && (redirectUrl$ | async)"
          >
            <atlas-button color="primary">
              <a href="{{ redirectUrl$ | async }}">
                {{ 'NOTIFICATIONS.ACTIONS.CONFIGURE_SMB_SETTING' | translate }}
              </a>
            </atlas-button>
          </atlas-tab>
          <atlas-tab label="empty" class="empty-state" *ngIf="showEmptyState$ | async">
            <glxy-empty-state>
              <glxy-empty-state-title>
                {{ 'NOTIFICATIONS.EMPTY_NOTIFICATION_SETTING.TITLE' | translate }}
              </glxy-empty-state-title>
              <p>{{ 'NOTIFICATIONS.EMPTY_NOTIFICATION_SETTING.TEXT' | translate }}</p>
            </glxy-empty-state>
          </atlas-tab>
        </atlas-tab-group>
      </div>
    </div>
  </atlas-modal-content>
  <atlas-modal-footer>
    <atlas-button (onClick)="reset()" [disabled]="isSaving() || disableActions()" color="primary">
      <i class="material-icons">refresh</i>
      {{ 'NOTIFICATIONS.ACTIONS.DISCARD_CHANGES' | translate }}
    </atlas-button>
    <atlas-button
      (onClick)="save()"
      [disabled]="disableActions()"
      [loading]="isSaving()"
      [raised]="true"
      color="secondary"
    >
      {{ 'NOTIFICATIONS.ACTIONS.SAVE' | translate }}
    </atlas-button>
  </atlas-modal-footer>
</atlas-modal>
