// *********************************
// Code generated by sdkgen - edited by you!
//
// This module contains any hand-written services or other things your module
// needs to share with applications that use it.
// *********************************
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';
import {
  LEXICON_BASE_TRANSLATION,
  LEXICON_COMPONENT_NAME,
  LEXICON_DISABLE_OTW,
  LEXICON_FILE_FORMAT,
  LEXICON_FILE_FORMAT_OPTION,
  LEXICON_PARTNER_ID,
  LEXICON_ROOT_COMPONENT,
} from './constants';
import { LexiconLoader } from './lexicon.loader';
import { LexiconMissingTranslationHandler } from './lexicon.missing-translation-handler';

declare const window: {
  deployment: string;
};

export interface LexiconModuleConfig extends TranslateModuleConfig {
  componentName?: string;
  baseTranslation?: any;
  disableOtw?: boolean;
  fileFormat?: LEXICON_FILE_FORMAT_OPTION;
}

@NgModule({
  imports: [TranslateModule],
  exports: [TranslateModule],
})
export class LexiconModule {
  public static forRoot(config: LexiconModuleConfig = {}): ModuleWithProviders<LexiconModule> {
    return {
      ngModule: LexiconModule,
      providers: [
        config.loader || {
          provide: TranslateLoader,
          useClass: LexiconLoader,
        },
        { provide: LexiconLoader, useExisting: TranslateLoader },
        config.missingTranslationHandler || {
          provide: MissingTranslationHandler,
          useClass: LexiconMissingTranslationHandler,
        },
        config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
        TranslateStore,
        { provide: USE_STORE, useValue: true },
        { provide: USE_DEFAULT_LANG, useValue: true },
        { provide: USE_EXTEND, useValue: false },
        { provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage || 'en' },
        { provide: LEXICON_ROOT_COMPONENT, useValue: config.componentName || '' },
        { provide: LEXICON_COMPONENT_NAME, useValue: config.componentName || '', multi: true },
        { provide: LEXICON_BASE_TRANSLATION, useValue: config.baseTranslation || {}, multi: true },
        { provide: LEXICON_PARTNER_ID, useValue: '' },
        {
          provide: LEXICON_DISABLE_OTW,
          useValue: config.disableOtw !== false && (typeof window === 'undefined' || window.deployment === undefined),
        },
        { provide: LEXICON_FILE_FORMAT, useValue: config.fileFormat || LEXICON_FILE_FORMAT_OPTION.NESTED_JSON },
        TranslateService,
      ],
    };
  }

  public static forChild(config: LexiconModuleConfig = {}): ModuleWithProviders<LexiconModule> {
    return {
      ngModule: LexiconModule,
      providers: [
        config.loader || {
          provide: TranslateLoader,
          useClass: LexiconLoader,
        },
        { provide: LexiconLoader, useExisting: TranslateLoader },
        config.missingTranslationHandler || {
          provide: MissingTranslationHandler,
          useClass: LexiconMissingTranslationHandler,
        },
        config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
        { provide: LEXICON_COMPONENT_NAME, useValue: config.componentName || '', multi: true },
        { provide: LEXICON_BASE_TRANSLATION, useValue: config.baseTranslation || {}, multi: true },
        TranslateService,
      ],
    };
  }
}
