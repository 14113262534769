import { Component, EventEmitter, OnInit, output, Output, signal } from '@angular/core';
import { ModalService } from '../../core-components/modal/modal.service';
import { AtlasMenuService } from '../../menu/menu.service';
import { SettingsComponent } from '../settings/settings.component';
import { Capacitor } from '@capacitor/core';
import { pushNotificationsEnabled, openAppNotificationSettings } from '@vendasta/shared';

@Component({
  selector: 'atlas-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss'],
})
export class FlyoutComponent implements OnInit {
  @Output() markAllAsFollowed: EventEmitter<void> = new EventEmitter();
  closed = output();

  readonly showNotificationsDisabledBanner = signal(false);

  constructor(
    private menuService: AtlasMenuService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    if (Capacitor.isNativePlatform()) {
      pushNotificationsEnabled().then((enabled) => this.showNotificationsDisabledBanner.set(!enabled));
    }
  }

  close(): void {
    this.closed.emit();
    this.menuService.closeMenu('');
  }

  markAllAsFollowedEvent(): void {
    this.markAllAsFollowed.emit();
  }

  openSettings(): void {
    this.modalService.openList(SettingsComponent);
  }

  async openAppSettings(): Promise<void> {
    await openAppNotificationSettings();
  }
}
