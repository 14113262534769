import { Component, EventEmitter, Input, Output, input, signal, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UITheme, UserNavigationItem, UserSwitcherData } from '@vendasta/atlas';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { AtlasDataService, AtlasLanguageService, Language, LanguageMap, LanguageMapping } from '../../../core/src';
import { ModalService } from '../core-components/modal/modal.service';
import { AtlasLanguageSelectorModalComponent } from '../language-selector/modal/language-selector-modal.component';
import { UserSwitcherModalComponent } from '../user-switcher-modal/user-switcher-modal.component';
import { CustomUserDropdownItem } from './user-dropdown.interface';

@Component({
  selector: 'atlas-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent {
  @Input() username: string;
  @Input() impersonateeUsername: string;
  @Input() email: string;
  @Input() emailVerified: boolean;
  @Input() signOutUrl?: string;
  readonly menuItems = input<UserNavigationItem[]>([]);
  readonly extraMenuItems = input<CustomUserDropdownItem[]>([]);
  readonly showLanguageSelector = input<boolean>(false);
  @Input() userSwitcherData?: UserSwitcherData[];
  @Input() partnerName?: string;
  readonly avatarButtonUrl = input<string>('');
  readonly useNewProfileDropdown = input<boolean>(false);

  @Output() event: EventEmitter<string> = new EventEmitter<string>();
  currentLanguage$: Observable<LanguageMapping>;
  languages: LanguageMapping[] = LanguageMap;
  languageLabel$: Observable<string>;

  protected readonly dataService = inject(AtlasDataService);

  protected readonly UITheme = UITheme;

  readonly open = signal(false);

  constructor(
    private languageService: AtlasLanguageService,
    private translateService: TranslateService,
    public apiService: AtlasDataService,
    private modalService: ModalService,
  ) {
    this.currentLanguage$ = combineLatest([this.languageService.language$, this.languageService.languageList$]).pipe(
      map(([currentLang, languageList]: [Language, LanguageMapping[]]) => {
        // toLowerCase to prevent miss matching between fr-CA with fr-ca
        const currentLangLower = currentLang.toLowerCase();
        return languageList?.find((l) => l.code.toLowerCase() === currentLangLower) || null;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.languageLabel$ = combineLatest([this.currentLanguage$, this.translateService.stream('LANGUAGE')]).pipe(
      withLatestFrom(this.languageService.language$),
      map(([[language, translations], fallbackCode]) => {
        if (language !== null) {
          return translations[language.code.toLowerCase()] || language.label;
        } else {
          return translations[fallbackCode];
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  // If a callback is provided, perform it. Otherwise navigate to the url provided.
  itemClick(menuItem: CustomUserDropdownItem): void {
    if (menuItem.callback) {
      menuItem.callback();
    } else if (menuItem.eventId) {
      this.event.emit(menuItem.eventId);
    }
  }

  setLanguage(language: LanguageMapping): void {
    const updateConfig = this.email && !this.impersonateeUsername;
    this.languageService.setLanguage(language.code, updateConfig);
  }

  intercept(event: Event): void {
    event.stopPropagation();
  }

  showSwitchLanguageDialog(): void {
    this.modalService.openList(AtlasLanguageSelectorModalComponent);
  }

  openUserSwitcher(): void {
    this.modalService.openInformational(UserSwitcherModalComponent);
  }
}
