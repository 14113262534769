import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ButtonLoadingIndicatorComponent } from './button-loading-indicator.component';
export { ButtonLoadingIndicatorComponent };

export const MODULE_IMPORTS = [CommonModule, MatProgressSpinnerModule];

export const MODULE_DECLARATIONS = [ButtonLoadingIndicatorComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [ButtonLoadingIndicatorComponent],
})
export class GalaxyButtonLoadingIndicatorModule {}
