<div
  class="atlas-navbar__menu__item atlas-navbar__transition"
  (click)="clicked()"
  [class.atlas-navbar__menu__item--disabled]="disabled"
>
  <!-- TODO: remove adding a target property based on the url. -->
  <a
    [href]="url"
    *ngIf="!!url"
    target="{{ isConquerLocalLink(url) ? '_blank' : '' }}"
    [attr.rel]="isConquerLocalLink(url) ? 'noopener' : null"
  >
    <i *ngIf="!!icon" class="material-icons">{{ icon }}</i>
    {{ label }}
  </a>
  <a [routerLink]="route" *ngIf="!!route">
    <i *ngIf="!!icon" class="material-icons">{{ icon }}</i>
    {{ label }}
  </a>
  <span *ngIf="!url && !route && !!label">
    <i *ngIf="!!icon" class="material-icons">{{ icon }}</i>
    {{ label }}
  </span>
  <ng-content></ng-content>
</div>
