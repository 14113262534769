<div
  class="atlas-navbar__tab-header__label-container"
  *ngIf="labels.length > 1"
>
  <div class="atlas-navbar__tab-header__tab-list">
    <div class="atlas-navbar__tab-header__tab-list__labels">
      <div
        class="atlas-navbar__tab-header__tab"
        *ngFor="let label of labels"
        [class.atlas-navbar__tab-header__tab--active]="
          label === (activeLabel$$ | async)
        "
        (click)="setActive(label)"
        [attr.id]="'atlas-navbar__tab__' + label"
      >
        <div class="atlas-navbar__tab-header__tab__label">
          {{ label }}
        </div>
      </div>
    </div>
    <atlas-ink-bar></atlas-ink-bar>
  </div>
</div>
