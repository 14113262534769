import { Component, computed } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatListItem } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { StyleService } from '@vendasta/galaxy/style-service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atlas-theme-switcher',
  standalone: true,
  imports: [MatIcon, MatIconButton, MatTooltip, TranslateModule, MatListItem],
  templateUrl: './theme-switcher.component.html',
  styleUrl: './theme-switcher.component.scss',
})
export class ThemeSwitcherComponent {
  currentTheme = toSignal(this.styleService.activeTheme$);

  icon = computed(() => {
    return this.currentTheme() === 'glxy-light-theme' ? 'brightness_2' : 'wb_sunny';
  });

  text = computed(() => {
    return this.currentTheme() === 'glxy-light-theme'
      ? 'THEME_SWITCHER.SWITCH_TO_DARK'
      : 'THEME_SWITCHER.SWITCH_TO_LIGHT';
  });
  constructor(private styleService: StyleService) {}

  toggleColorScheme(): void {
    this.styleService.toggleLightDark('business-center-client');
  }
}
