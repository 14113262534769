import { DialogRef } from '@angular/cdk/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'atlas-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  dialogRef = inject(DialogRef);
  observer = inject(BreakpointObserver);

  ngOnInit() {
    this.observer
      .observe('(max-width: 768px)')
      .pipe(takeUntil(this.dialogRef.closed))
      .subscribe((result) => {
        if (result.matches) {
          this.dialogRef.updateSize('100vw', '100vh');
          const surface = this.dialogRef.overlayRef.overlayElement.querySelector<HTMLElement>('.mdc-dialog__surface');
          surface.style.borderRadius = '0';
        }
      });
  }
}
