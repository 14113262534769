import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'uikit-list-stencil',
  template: `
    <mat-card appearance="outlined" class="list-container" [ngClass]="matElevation">
      <div *ngIf="showHeader" class="list-header stencil-shimmer" style=""></div>
      <div *ngFor="let _ of rows">
        <div class="list-item-row" [ngStyle]="listItemRowStyles">
          <div class="stencil-shimmer" style="margin: 0; padding: 0; width: 100%; height: 80%;"></div>
        </div>
      </div>
    </mat-card>
  `,
  styles: [
    `
      .list-container {
        padding: 0;
      }
      .list-header {
        height: 40px;
      }
      .list-item-row {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    `,
  ],
})
export class ListStencilComponent implements OnInit {
  @Input() showHeader = true;
  @Input() numRows = 3;
  @Input() rowHeight = '51px'; // defaults to height of partner center business tab height (95px for marketplace)
  @Input() sidePadding = '17px'; // defaults to padding on partner center business padding (38px for marketplace)
  @Input() matElevation = 'mat-elevation-z2';

  rows: number[] = []; // this is to turn the number of rows into an arbitrary list so we can loop through them
  listItemRowStyles: any;

  ngOnInit(): void {
    for (let n = 0; n < this.numRows; n++) {
      this.rows.push(n);
    }

    // if you think this is gross i agree
    // if you think this is gross and actually have a solution hit me up otherwise just enjoy the magic.
    // determine the height of each row for the table

    this.listItemRowStyles = {
      height: `${this.rowHeight}`,
      padding: `0 ${this.sidePadding}`,
    };
  }
}
