import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxyTooltipDirective } from './directive/tooltip.directive';
import { TooltipComponent } from './tooltip.component';

export const MODULE_IMPORTS = [CommonModule, OverlayModule, GalaxyPopoverModule, TranslateModule];

export const MODULE_DECLARATIONS = [TooltipComponent, GalaxyTooltipDirective];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TooltipComponent, GalaxyTooltipDirective],
})
export class GalaxyTooltipModule {}
