import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HostService } from '../host.service';
import {
  GetByAccountGroupIdRequestInterface,
  GetByAccountGroupIdResponseInterface,
  GetSectionsRequestInterface,
  GetSectionsResponseInterface,
  SaveSectionsRequestInterface,
} from './interfaces/notifications.interface';
import {
  GetByAccountGroupIdRequest,
  GetByAccountGroupIdResponse,
  GetSectionsRequest,
  GetSectionsResponse,
  SaveSectionsRequest,
} from './objects/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  constructor(private http: HttpClient, private hostService: HostService) {}

  getSections(r: GetSectionsRequest | GetSectionsRequestInterface): Observable<GetSectionsResponse> {
    const request = (r as GetSectionsRequest).toApiJson ? (r as GetSectionsRequest) : new GetSectionsRequest(r);
    const url = new URL(this.hostService.httpsHost() + '/nsInternalApi/v3/notificationSettings/getSections/');
    const apiJson = request.toApiJson();
    for (const key in apiJson) {
      if (key in apiJson) {
        url.searchParams.append(key, apiJson[key]);
      }
    }
    return this.http.get<GetSectionsResponseInterface>(url.href, this.apiOptions()).pipe(
      map((resp) => GetSectionsResponse.fromProto(resp)),
      share(),
    );
  }

  saveSections(r: SaveSectionsRequest | SaveSectionsRequestInterface): Observable<HttpResponse<null>> {
    const request = (r as SaveSectionsRequest).toApiJson ? (r as SaveSectionsRequest) : new SaveSectionsRequest(r);
    return this.http
      .post<null>(
        new URL(this.hostService.httpsHost() + '/nsInternalApi/v3/notificationSettings/sectionSetting/updateMulti/')
          .href,
        request.toApiJson(),
        this.apiOptions(),
      )
      .pipe(
        map(() => null),
        share(),
      );
  }

  getSettingsByAccountGroupId(
    r: GetByAccountGroupIdRequest | GetByAccountGroupIdRequestInterface,
  ): Observable<GetByAccountGroupIdResponse> {
    const request = (r as GetByAccountGroupIdRequest).toApiJson
      ? (r as GetByAccountGroupIdRequest)
      : new GetByAccountGroupIdRequest(r);
    const url = new URL(this.hostService.httpsHost() + '/nsInternalApi/v3/notificationSettings/getByAccountGroupId/');
    const apiJson = request.toApiJson();
    for (const key in apiJson) {
      if (key in apiJson) {
        url.searchParams.append(key, apiJson[key]);
      }
    }
    return this.http.get<GetByAccountGroupIdResponseInterface>(url.href, this.apiOptions()).pipe(
      map((resp) => GetByAccountGroupIdResponse.fromProto(resp)),
      share(),
    );
  }

  private apiOptions(): { withCredentials: boolean } {
    return {
      withCredentials: true,
    };
  }
}
