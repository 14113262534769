import { NgModule } from '@angular/core';
import { VaGoalTableComponent } from './goal-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { VaCardsModule } from '../va-cards/va-cards.module';
import { CommonModule } from '@angular/common';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  imports: [
    MatTableModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    VaCardsModule,
    CommonModule,
    MatCardModule,
    MatIconModule,
  ],
  exports: [VaGoalTableComponent],
  declarations: [VaGoalTableComponent],
  providers: [],
})
export class VaGoalTableModule {}
