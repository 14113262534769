import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BrandingV2ApiService,
  BrandingV2Interface,
  GetBrandingV2Request,
  GetBrandingV2RequestInterface,
  GetBrandingV2Response,
  GetMultiBrandingV2Request,
  GetMultiBrandingV2Response,
  ListBrandingV2Request,
  ListBrandingV2Response,
  UpsertBrandingV2Request,
  UpsertMutationInterface,
} from '@vendasta/partner';

@Injectable({ providedIn: 'root' })
export class BrandingV2Service {
  constructor(private api: BrandingV2ApiService) {}

  getBranding(partnerId: string, marketId?: string): Observable<BrandingV2Interface> {
    const req = new GetBrandingV2Request({ partnerId: partnerId, marketId: marketId });
    return this.api.getBranding(req).pipe(map((resp: GetBrandingV2Response) => resp.branding));
  }

  getMultiBranding(requests: GetBrandingV2RequestInterface[]): Observable<BrandingV2Interface[]> {
    const req = new GetMultiBrandingV2Request({ requests: requests });
    return this.api.getMultiBranding(req).pipe(map((resp: GetMultiBrandingV2Response) => resp.branding));
  }

  listBranding(partnerId: string, pageSize: number, cursor: string): Observable<BrandingV2Interface[]> {
    const req = new ListBrandingV2Request({
      partnerId: partnerId,
      pagingOptions: { pageSize: pageSize, cursor: cursor },
    });
    return this.api.listBranding(req).pipe(map((resp: ListBrandingV2Response) => resp?.brandings));
  }

  upsertBranding(partnerId: string, marketId?: string, mutations?: UpsertMutationInterface[]): Observable<boolean> {
    const req = new UpsertBrandingV2Request({ partnerId: partnerId, marketId: marketId, mutations: mutations });
    return this.api.upsertBranding(req).pipe(map(() => true));
  }
}
