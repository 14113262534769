import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BadgeComponent } from './badge.component';
export { BadgeComponent };

export const MODULE_IMPORTS = [CommonModule];

export const MODULE_DECLARATIONS = [BadgeComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BadgeComponent],
})
export class GalaxyBadgeModule {}
