import { Injectable } from '@angular/core';
import {
  NotificationContextInterface,
  NotificationMedium,
  NotificationSetting,
  NotificationStatus,
  NotificationType,
  NotificationsService as NotificationsClientService,
  UpdateNotificationSettingsRequestUpdateNotificationSettingsOperationInterface,
} from '@vendasta/notifications-sdk';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Notification } from './notification';
import { stripHtmlEscapeCharactersFromURL } from './strip-html';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private notificationsService: NotificationsClientService) {}

  loadNotifications$(
    partnerId: string,
    pageSize?: number,
    cursor?: string,
    flatten?: boolean,
    medium?: NotificationMedium,
  ): Observable<ListNotificationsResponse> {
    return this.notificationsService.list$(partnerId, pageSize, cursor, medium, flatten).pipe(
      map((resp) => {
        if (!!resp && !!resp.notifications) {
          return {
            notifications: resp.notifications.map((n) => ({
              id: n.notificationId,
              notificationTypeId: n.notificationTypeId,
              body: n.web.body,
              eventTime: n.created,
              link: stripHtmlEscapeCharactersFromURL(n.link),
              viewed: n.viewed,
              followed: n.followed,
              partnerId: n.partnerId,
              marketId: n.marketId,
              accountGroupId: n.accountGroupId,
            })),
            hasMore: resp.hasMore,
            nextCursor: resp.nextCursor,
          };
        } else {
          return {
            notifications: [],
            hasMore: false,
            nextCursor: '',
          };
        }
      }),
    );
  }

  listNotificationTypes$(partnerId: string): Observable<NotificationType[]> {
    return this.notificationsService.listTypes$(partnerId).pipe(
      map((resp) => (!!resp && !!resp.types ? resp.types : [])),
      map((resp) =>
        resp.map((nt) => {
          nt.configurations = nt.configurations.map((conf) => {
            if (!conf.medium) {
              conf.medium = NotificationMedium.NOTIFICATION_MEDIUM_WEB;
            }
            return conf;
          });
          return nt;
        }),
      ),
    );
  }

  /**
   * Mark the notifications as viewed
   */
  public viewed$(partnerId: string, notificationIds: Array<string>): Observable<Record<string, any>> {
    const ops = notificationIds.map((id) => ({
      notificationId: id,
      viewed: true,
    }));
    return this.notificationsService.updateNotifications$(partnerId, ops);
  }

  /**
   * Mark notifications as followed
   */
  public followed$(
    partnerId: string,
    notificationIds: Array<string>,
    followed: boolean,
  ): Observable<Record<string, any>> {
    const ops = notificationIds.map((id) => ({
      notificationId: id,
      followed: followed,
    }));
    return this.notificationsService.updateNotifications$(partnerId, ops);
  }

  /**
   * subscribe to a notification
   */
  public subscribe$(partnerId: string, setting: NotificationSetting): Observable<Record<string, any>> {
    const updated = Object.assign({}, setting);
    updated.status = NotificationStatus.NOTIFICATION_STATUS_ENABLED;
    return this.saveSettingMulti$(partnerId, [updated]);
  }

  /**
   * unsubscribe from a notification
   */
  public unsubscribe$(partnerId: string, setting: NotificationSetting): Observable<Record<string, any>> {
    const updated = Object.assign({}, setting);
    updated.status = NotificationStatus.NOTIFICATION_STATUS_DISABLED;
    return this.saveSettingMulti$(partnerId, [updated]);
  }

  /**
   * save multiple settings at once
   */
  public saveSettingMulti$(partnerId: string, settings: NotificationSetting[]): Observable<Record<string, any>> {
    const ops: UpdateNotificationSettingsRequestUpdateNotificationSettingsOperationInterface[] = [];
    settings.forEach((o) => {
      ops.push({
        notificationTypeId: o.notificationTypeId,
        notificationMedium: o.notificationMedium,
        context: o.context,
        status: o.status,
      });
    });
    return this.notificationsService.updateNotificationSetting$(partnerId, ops).pipe(map(() => ({})));
  }

  public settings$(partnerId: string, context?: NotificationContextInterface): Observable<Array<NotificationSetting>> {
    return this.notificationsService.getNotificationSetting$(partnerId, context);
  }

  // Get the status of a notification for specific notification type(For both web and email)
  public getNotificationSettingStatus$(
    partnerId: string,
    context: NotificationContextInterface,
    notificationTypeId: string,
  ): Observable<boolean> {
    return this.notificationsService.getNotificationSetting$(partnerId, context).pipe(
      map((settings) => {
        const selectedSettings = settings.filter((s) => s.notificationTypeId === notificationTypeId);
        if (selectedSettings && selectedSettings.length > 0) {
          return !!selectedSettings.find(
            (selectedSetting) => selectedSetting.status === NotificationStatus.NOTIFICATION_STATUS_ENABLED,
          );
        }
      }),
    );
  }
}

export interface ListNotificationsResponse {
  notifications: Notification[];
  nextCursor: string;
  hasMore: boolean;
}
