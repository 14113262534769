import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  OnInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';

import { DEFAULT_POSITIONS } from './popover-positions';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

export type PaddingSize = 'large' | 'small' | 'none';

@Component({
  selector: 'glxy-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent implements OnInit, AfterContentInit, OnChanges {
  _positions?: ConnectedPosition[];

  /** Origin that references the component the popover should be created relative to. Comes from CdkOverlayOrigin directive */
  @Input() origin!: CdkOverlayOrigin;

  /** Whether the popover is open or closed */
  @Input({ transform: booleanAttribute }) isOpen = false;

  /** Width and Height of the popover box */
  @Input() maxWidth: number | string = '320px';
  @Input() maxHeight: number | string = 'auto';

  /** Option to place a backdrop element behind the popover to allow for click interaction/blocking */
  @Input({ transform: booleanAttribute }) hasBackdrop = false;

  /** Option to color the backdrop, or leave it transparent, IF hasBackdrop is true */
  @Input({ transform: booleanAttribute }) showBackdrop = false;

  /** Option to close the popover when the backdrop is clicked */
  /** Will automatically enable the background if enabled */
  @Input({ transform: booleanAttribute }) closeOnBackdropClick = false;

  /** Option to close the popover when the escape key is pressed */
  @Input({ transform: booleanAttribute }) closeOnEscapeKey = false;

  /** Whether to show the arrow for the popover */
  @Input({ transform: booleanAttribute }) hasArrow = true;

  /** Whether to move the popover to keep it on screen **/
  @Input({ transform: booleanAttribute }) keepOnScreen = false;

  /** Control the amount of padding on the popover */
  @Input() padding: PaddingSize = 'large';

  /** Change the background color and text for high contrast with white backgrounds */
  @Input({ transform: booleanAttribute }) highContrast = false;

  /** If the popover should change to fullscreen on smaller viewports */
  @Input({ transform: booleanAttribute }) mobileFullScreen = false;

  /** Breakpoint below which the popover becomes fullscreen, if using mobileFullScreen **/
  @Input() breakpoint = '425px';

  /** Whether to show a close button on mobileFullScreen popovers */
  @Input({ transform: booleanAttribute }) showMobileClose = true;

  /**
   * What positioning strategy to use for the popover. By default, the strategy allows for the popover
   * to move to above, below, left, and right of the origin depending on screen size
   */
  @Input() positions: ConnectedPosition[] = DEFAULT_POSITIONS;

  @Output() readonly isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>(/* isAsync */ true);

  /** Emits when the backdrop has been clicked */
  @Output() backdropClick: EventEmitter<any> = new EventEmitter();

  /** Listen to escape key and close popover when closeOnEscapeKey is true  */
  @HostListener('document:keydown.escape')
  handleEscapeKey() {
    if (this.closeOnEscapeKey && this.isOpen) this.close();
  }

  get _cooercedMaxWidth(): string {
    return coerceCssPixelValue(this.maxWidth);
  }
  get _cooercedMaxHeight(): string {
    return coerceCssPixelValue(this.maxHeight);
  }
  get _popoverClasses(): string[] {
    return ['glxy-popover', this.hasArrow ? 'has-arrow' : ''];
  }
  get _backdropClass(): string {
    return this.showBackdrop ? '' : 'hide-backdrop';
  }

  isFullscreen$?: Observable<boolean>;
  mobileBreakpoint = '';

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.mobileBreakpoint = `(max-width: ${this.breakpoint})`;
    this.isFullscreen$ = this.breakpointObserver.observe([this.mobileBreakpoint]).pipe(
      map((resp) => {
        return resp.breakpoints[this.mobileBreakpoint];
      }),
    );
  }

  ngAfterContentInit(): void {
    this.setupPositions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { positions } = changes;
    if (positions && positions.previousValue !== this.positions) {
      this.setupPositions();
    }
  }

  setupPositions(): void {
    this._positions = this.positions.map((pos: ConnectedPosition) => {
      const newPos = { ...pos };
      return newPos;
    });
  }

  /** Used by the popover directive to attach itself to the popover component */
  setOrigin(origin: CdkOverlayOrigin): void {
    this.origin = origin;
  }

  onBackdropClick(): void {
    this.backdropClick.emit();
    if (this.closeOnBackdropClick) this.close();
  }

  open(): void {
    this.isOpen = true;
    this.isOpenChange.emit(this.isOpen);
  }

  close(): void {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}
