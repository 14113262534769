<atlas-modal>
  <atlas-modal-header>{{ 'DROPDOWN_MENU.SWITCH_USER' | translate }}</atlas-modal-header>
  <atlas-modal-content>
    <ng-container *ngFor="let user of userData$ | async">
      <a [href]="user.entryUrl">
        <div class="partner-container">
          <div class="partner-name">
            {{ user.partnerName }}
          </div>
          <div class="partner-id">
            {{ user.partnerId }}
          </div>
        </div>
      </a>
    </ng-container>
  </atlas-modal-content>
  <atlas-modal-footer>
    <button mat-stroked-button (click)="closeDialog()">{{ 'USER_SWITCHER_MODAL.CANCEL' | translate }}</button>
  </atlas-modal-footer>
</atlas-modal>
