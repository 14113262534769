import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  inject,
} from '@angular/core';

export const defaultBackgroundColor = '#BDBDBD';

@Component({
  selector: 'glxy-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit, OnChanges {
  @HostBinding('class') class = 'glxy-avatar';

  @Input() src?: string;
  @Input() svgIcon?: string;
  @Input() name?: string;
  @Input() width = 40;
  @Input() shape?: 'circle' | 'square' = 'circle';
  @Input() icon?: string;
  @Input() abbreviation?: string;
  @Input() backgroundColor?: string;
  @Input() gravatarEmailMD5?: string;

  @HostBinding('style.color')
  @Input()
  textColor?: string;

  @HostBinding('style.width')
  @HostBinding('style.height')
  get getWidth(): string {
    return this.width + `px`;
  }

  @HostBinding('class.shape-circle')
  get isShapeCircle(): boolean {
    return this.shape === 'circle';
  }

  @HostBinding('class.shape-square')
  get isShapesquare(): boolean {
    return this.shape === 'square';
  }

  changeDetector = inject(ChangeDetectorRef);

  imageExists = true;
  computedBackgroundColor?: string;
  computerAbbreviation?: string;

  ngOnInit(): void {
    this.getAbbreviationForName();
    this.generateBackgroundColorFromName();
    this.checkIfImageUrlExists();
  }

  ngOnChanges(): void {
    // rereun init scripts if inputs changes
    this.ngOnInit();
  }

  getGravatarUrl(): void {
    if (this.gravatarEmailMD5) {
      this.src = `https://www.gravatar.com/avatar/${this.gravatarEmailMD5}?d=404`;
    }
  }

  getAbbreviationForName(): void {
    if (this.abbreviation) {
      this.computerAbbreviation = this.abbreviation;
    } else if (this.name) {
      const names = this.name.trim().split(/\s+/);
      this.computerAbbreviation = names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
    } else {
      this.computerAbbreviation = 'UNKNOWN'; // for unknown use mat-icon
    }
  }

  checkIfImageUrlExists(): void {
    if (!this.src) {
      this.imageExists = false;
    } else {
      const image = new Image();
      image.src = this.src;
      image.onload = () => {
        this.imageExists = true;
        this.changeDetector.markForCheck();
      };
      image.onerror = () => {
        this.imageExists = false;
        this.changeDetector.markForCheck();
      };
    }
  }

  generateBackgroundColorFromName(): void {
    if (this.backgroundColor) {
      this.computedBackgroundColor = this.backgroundColor;
    } else if (!this.name) {
      this.computedBackgroundColor = defaultBackgroundColor;
    } else {
      let nameSum = 0;
      const COLOR_CODES = [
        '#EF5350',
        '#42A5F5',
        '#66BB6A',
        '#FFA726',
        '#AB47BC',
        '#FFCA28',
        '#EC407A',
        '#26C6DA',
        '#FF7B57',
      ];
      for (let i = 0; i < this.name.length; i++) {
        nameSum += this.name[i].charCodeAt(0);
      }
      const index = nameSum % COLOR_CODES.length;
      this.computedBackgroundColor = COLOR_CODES[index];
    }
  }
}
