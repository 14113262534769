import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({ selector: '[atlasIconButton]' })
export class IconButtonDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.setStyle(el.nativeElement, 'padding', '0');
    renderer.setStyle(el.nativeElement, 'min-width', '0');
    renderer.setStyle(el.nativeElement, 'width', '40px');
    renderer.setStyle(el.nativeElement, 'height', '40px');
    renderer.setStyle(el.nativeElement, 'flex-shrink', '0');
    renderer.setStyle(el.nativeElement, 'line-height', '40px');
    renderer.setStyle(el.nativeElement, 'border-radius', '50%');
    renderer.setStyle(el.nativeElement, 'box-sizing', 'border-box');
    renderer.setStyle(el.nativeElement, 'position', 'relative');
    renderer.setStyle(el.nativeElement, 'user-select', 'none');
    renderer.setStyle(el.nativeElement, 'cursor', 'pointer');
    renderer.setStyle(el.nativeElement, 'outline', '0');
    renderer.setStyle(el.nativeElement, 'border', 'none');
    renderer.setStyle(el.nativeElement, 'display', 'flex');
    renderer.setStyle(el.nativeElement, 'justify-content', 'center');
    renderer.setStyle(el.nativeElement, 'align-items', 'center');
    renderer.setStyle(el.nativeElement, 'white-space', 'nowrap');
    renderer.setStyle(el.nativeElement, 'text-decoration', 'none');
    renderer.setStyle(el.nativeElement, 'vertical-align', 'baseline');
    renderer.setStyle(el.nativeElement, 'text-align', 'center');
    renderer.setStyle(el.nativeElement, 'margin', '0');
    renderer.setStyle(el.nativeElement, 'overflow', 'visible');
    renderer.setStyle(el.nativeElement, 'color', 'inherit');
    renderer.setStyle(el.nativeElement, 'background', 'transparent');
    renderer.setStyle(
      el.nativeElement,
      'transition',
      'background-color 0.2s cubic-bezier(0.4, 0, 1, 1), color 0.15s ease-in-out',
    );
  }

  @HostListener('mouseover')
  @HostListener('mouseup')
  onMouseOver(): void {
    this.renderer.setStyle(this.el.nativeElement, 'background', 'var(--themingPrimaryHoverColor)');
  }

  @HostListener('mouseout')
  onMouseOut(): void {
    this.renderer.setStyle(this.el.nativeElement, 'background', 'transparent');
  }

  @HostListener('mousedown')
  onClick(): void {
    this.renderer.setStyle(this.el.nativeElement, 'background', 'var(--themingPrimaryActiveColor)');
  }
}
