import { NotificationMedium, NotificationSetting } from '@vendasta/notifications-sdk';

export interface Notification {
  partnerId: string;
  marketId?: string;
  accountGroupId?: string;
  id: string;
  notificationTypeId: string;
  body: string;
  eventTime: Date;
  link: string; // TODO: Handle single page links
  viewed: boolean;
  followed: boolean;
}

export function getSettingFromNotification(
  notification: Notification,
  settings: Array<NotificationSetting>,
  medium?: NotificationMedium,
): NotificationSetting {
  if (!notification) {
    return null;
  }
  if (!medium) {
    medium = NotificationMedium.NOTIFICATION_MEDIUM_WEB;
  }
  const settingsOfType = settings.filter((setting) => setting.notificationTypeId === notification.notificationTypeId);

  let accountGroupSetting: NotificationSetting;
  let marketSetting: NotificationSetting;
  let partnerSetting: NotificationSetting;
  settingsOfType.forEach((setting) => {
    if (setting.notificationMedium !== medium) {
      return;
    }
    // Check if this is an account group level setting
    if (
      notification.accountGroupId &&
      setting.context &&
      setting.context.accountGroup &&
      setting.context.accountGroup.accountGroupId === notification.accountGroupId
    ) {
      accountGroupSetting = setting;
      return;
    }
    if (notification.partnerId) {
      // Check if this is a market level setting
      if (
        notification.marketId &&
        setting.context &&
        setting.context.partner &&
        setting.context.partner.partnerId === notification.partnerId
      ) {
        marketSetting = setting;
        return;
      }
      // Check if this is a partner level setting
      if (setting.context && setting.context.partner && setting.context.partner.partnerId === notification.partnerId) {
        partnerSetting = setting;
        return;
      }
    }
  });
  return accountGroupSetting || marketSetting || partnerSetting || null;
}
