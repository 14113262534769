import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SentenceCasePipe } from './sentence-case/sentence-case.pipe';
export { SentenceCasePipe };

import { GalaxyAddressPipe } from './address/address.pipe';
export { GalaxyAddressPipe };

import { GlxyAsyncStatus, GalaxyAsyncStatusPipe } from './async-status/async-status.pipe';
export { GalaxyAsyncStatusPipe, GlxyAsyncStatus };

import { GalaxyCurrencyPipe } from './currency/currency.pipe';
export { GalaxyCurrencyPipe };

import { GalaxyDatePipe } from './date/date.pipe';
export { GalaxyDatePipe };

import { GalaxyOrdinalPipe } from './ordinal/ordinal.pipe';
export { GalaxyOrdinalPipe };

import { GalaxyITrustThisHtmlPipe } from './i-trust-this-html/i-trust-this-html.pipe';
export { GalaxyITrustThisHtmlPipe };

import { LinkyPipe } from './linky/linky.pipe';
export { LinkyPipe };

import { FormatStatPipe } from './format-stat/format-stat.pipe';
export { FormatStatPipe };

import { TruncateUrlPipe } from './truncate-url/truncate-url.pipe';
export { TruncateUrlPipe };

import { GlxyMarkdownPipe } from './markdown/markdown.pipe';
export { GlxyMarkdownPipe };

import { EscapeHtmlPipe } from './escape-html/escape-html.pipe';
export { EscapeHtmlPipe };

@NgModule({
  providers: [TitleCasePipe],
  imports: [CommonModule, LinkyPipe, TruncateUrlPipe, GlxyMarkdownPipe, EscapeHtmlPipe],
  declarations: [
    GalaxyDatePipe,
    GalaxyOrdinalPipe,
    GalaxyCurrencyPipe,
    GalaxyAddressPipe,
    GalaxyITrustThisHtmlPipe,
    GalaxyAsyncStatusPipe,
    SentenceCasePipe,
  ],
  exports: [
    GalaxyDatePipe,
    GalaxyOrdinalPipe,
    GalaxyCurrencyPipe,
    GalaxyAddressPipe,
    GalaxyITrustThisHtmlPipe,
    GalaxyAsyncStatusPipe,
    SentenceCasePipe,
    LinkyPipe,
    TruncateUrlPipe,
    GlxyMarkdownPipe,
    EscapeHtmlPipe,
  ],
})
export class GalaxyPipesModule {}
