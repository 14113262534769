import { translations } from './constants';

const isObject = (item: unknown): boolean => {
  return item === Object(item) && !Array.isArray(item);
};

export const deepMerge = (target: translations, sources: translations | translations[]): translations => {
  if (!Array.isArray(sources)) {
    sources = [sources];
  }
  sources
    .filter((source) => isObject(source))
    .forEach((source) => {
      Object.entries(source).forEach(([key, value]) => {
        if (typeof value === 'string') {
          target[key] = value;
        } else {
          if (typeof value === 'object') {
            if (!target[key] || !(typeof target[key] === 'object')) {
              target[key] = {};
            }
            deepMerge(target[key] as translations, value);
          }
        }
      });
    });
  return target;
};
