import { Injectable, WritableSignal, effect, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { isLocalStorageAvailable } from './util';

declare const iamSession: string;
const LOCAL_SESSION_KEY = 'local_session';
const USE_LOCAL_STORAGE = isLocalStorageAvailable();

interface SessionServiceInterface {
  getSessionId(): Observable<string | null>;
  setSessionId(sessionId: string): void;
  clearSessionId(): void;
}

@Injectable({ providedIn: 'root' })
export class SessionService implements SessionServiceInterface {
  private readonly sessionIdSubject: WritableSignal<string | null>;
  private readonly sessionId$: Observable<string | null>;

  constructor() {
    if (typeof iamSession !== 'undefined' && !!iamSession) {
      this.sessionIdSubject = signal(iamSession);
    } else if (USE_LOCAL_STORAGE) {
      const localSession = this.getLocalStoredSession();
      this.sessionIdSubject = signal(localSession);
    } else {
      this.sessionIdSubject = signal(null);
    }

    effect(() => {
      const sessionId = this.sessionIdSubject();
      if (USE_LOCAL_STORAGE) {
        this.setLocalStoredSession(sessionId);
      }
    });

    this.sessionId$ = toObservable(this.sessionIdSubject);
  }

  getSessionId(): Observable<string | null> {
    return this.sessionId$;
  }

  setSessionId(sessionId: string): void {
    this.sessionIdSubject.set(sessionId);
  }

  clearSessionId(): void {
    this.sessionIdSubject.set('');
  }

  private getLocalStoredSession(): string | null {
    return localStorage.getItem(LOCAL_SESSION_KEY);
  }

  private setLocalStoredSession(session: string | null): void {
    localStorage.setItem(LOCAL_SESSION_KEY, session ?? '');
  }
}
