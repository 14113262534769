<button *ngIf="(isMobile$ | async) === false" color="primary" mat-stroked-button [matMenuTriggerFor]="columnMenu">
  <div class="centered-label">
    <mat-icon>{{ iconName }}</mat-icon>
    {{ buttonLabel }}
  </div>
</button>
<button *ngIf="(isMobile$ | async) === true" color="primary" mat-icon-button [matMenuTriggerFor]="columnMenu">
  <mat-icon>{{ iconName }}</mat-icon>
</button>
<mat-menu #columnMenu="matMenu">
  <div class="va-mat-table-dragable-container" cdkDropList dkDropListLockAxis="y" cdkDropListOrientation="vertical">
    <div
      class="dragable-row"
      *ngFor="let column of columnInfo; let i = index"
      cdkDrag
      [cdkDragData]="{ columnIndex: i, columnTitle: column.id }"
      (cdkDragDropped)="columnMenuDropped($event)"
    >
      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
      <mat-checkbox
        [checked]="!column.hidden"
        (click)="$event.stopPropagation()"
        (change)="toggleSelectedColumn(column.id)"
      >
        <ng-container *ngTemplateOutlet="nameOutput; context: { name: column.name }" />
      </mat-checkbox>

      <mat-card appearance="outlined" class="va-mat-table-drag-preview" *cdkDragPreview>
        <mat-icon>drag_indicator</mat-icon>
        <mat-checkbox [checked]="!column.hidden">
          <ng-container *ngTemplateOutlet="nameOutput; context: { name: column.name }" />
        </mat-checkbox>
      </mat-card>
    </div>
  </div>
</mat-menu>
<ng-template #nameOutput let-name="name">
  <ng-container *ngIf="useI18NTranslations">
    {{ name | translate }}
  </ng-container>
  <ng-container *ngIf="!useI18NTranslations">
    {{ name }}
  </ng-container>
</ng-template>
