export const Language = {
  AFRIKAANS: 'af',
  CZECH: 'cs',
  DUTCH: 'nl',
  ENGLISH: 'en',
  FRENCH: 'fr',
  FRENCH_CANADA: 'fr-ca',
  FRENCH_FRANCE: 'fr-fr',
  GERMAN: 'de',
  HEBREW: 'he',
  ITALIAN: 'it',
  JAPANESE: 'ja',
  PORTUGUESE: 'pt',
  RUSSIAN: 'ru',
  SPANISH_LATIN_AMERICA: 'es-419',
} as const;

export type Language = (typeof Language)[keyof typeof Language];

export interface LanguageMapping {
  code: string;
  label: string;
  flag?: string;
  totalStrings?: number;
  translatedStrings?: number;
}

export const LanguageMap: LanguageMapping[] = [
  { code: Language.ENGLISH, label: 'English', flag: '🇺🇸' },
  { code: Language.SPANISH_LATIN_AMERICA, label: 'English', flag: '🇪🇸' },
  { code: Language.FRENCH_FRANCE, label: 'Français', flag: '🇫🇷' },
  { code: Language.FRENCH_CANADA, label: 'Français', flag: '🇨🇦' },
  { code: Language.CZECH, label: 'Čeština', flag: '🇨🇿' },
  { code: Language.DUTCH, label: 'Nederlands', flag: '🇳🇱' },
  { code: Language.GERMAN, label: 'Deutsch', flag: '🇧🇪' },
  { code: Language.ITALIAN, label: 'Italiano', flag: '🇮🇹' },
  { code: Language.HEBREW, label: 'עברית', flag: '🇮🇱' },
];
