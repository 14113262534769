import { Component, HostBinding, Input } from '@angular/core';

export type BadgeColor =
  | 'light-grey'
  | 'grey'
  | 'grey-solid'
  | 'dark-grey-solid'
  | 'black-solid'
  | 'blue'
  | 'blue-solid'
  | 'green'
  | 'green-solid'
  | 'red'
  | 'red-solid'
  | 'yellow'
  | 'yellow-solid'
  | 'purple';

export type ComponentSize = 'normal' | 'small';

@Component({
  selector: 'glxy-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  // Predefined named color for badge: grey, green, red, blue, black-solid, etc
  @Input() color: BadgeColor = 'grey';

  // Predefined size: normal (default) | small
  @Input() size: ComponentSize = 'normal';

  @HostBinding('class') get badgeOptions(): string {
    let classes = 'glxy-badge';
    classes += ' glxy-badge--' + this.color;
    if (this.size === 'small') {
      classes += ' glxy-badge--small';
    }
    return classes;
  }
}
