import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PopoverComponent } from '../popover.component';

@Directive({
  selector: '[glxyPopover]',
  exportAs: 'glxyPopover',
})
export class GalaxyPopoverDirective implements OnInit {
  /** Passed in reference of the popover */
  @Input('glxyPopover') popover?: PopoverComponent;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.initPopover();
  }

  initPopover(): void {
    const origin = new CdkOverlayOrigin(this.elementRef);
    this.popover?.setOrigin(origin);
  }
}
