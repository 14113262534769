import {
  addDays as add,
  addMinutes,
  isBefore as before,
  differenceInMonths as diffInMonths,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  format,
  formatDistance,
  parseISO,
  isSameDay as sameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { getLocale } from '../../locale';
import { DateDuration, DateFormat, DateRange } from './interface';

const MS_PER_MINUTE = 60 * 1000;

/**
 * @param {Date} value What you wish to normalize
 * @param {(value: Date | number) => Date} dateFn A date function that takes in a date and applies some logic and returns a date
 * @returns {Date} normalized Date
 */
export function normalizeDateFunc(
  value: Date,
  dateFn: (value: Date | number, ...args: any[]) => Date,
  ...args: any[]
): Date {
  const date = new Date(value);
  return addMinutes(dateFn(getUTCTime(date), ...args), -date.getTimezoneOffset());
}

/**
 * @param {Date} date - a given date
 * @param {number} amount - number of days to add to the date
 * @returns {Date} Returns a date that is "amount" of days in the future from the provided date.
 */
export function addDays(date: Date, amount: number): Date {
  return add(date, amount);
}

/**
 * @param {Date} date - a given date
 * @param {number} amount - number of days to subtract from the date
 * @returns {Date} Returns a date that is "amount" of days in the past from the provided date.
 */
export function subtractDays(date: Date, amount: number): Date {
  return subDays(date, amount);
}

/**
 * @param {Date} date - a given date
 * @param {number} amount - number of months to subtract from the date
 * @returns {Date} Returns a date that is "amount" of months in the past from the provided date.
 */
export function subtractMonths(date: Date, amount: number): Date {
  return subMonths(date, amount);
}

/**
 * @param {Date} date - a given date
 * @param {number} amount - number of years to subtract from the date
 * @returns {Date} Returns a date that is "amount" of years in the past from the provided date.
 */
export function subtractYears(date: Date, amount: number): Date {
  return subYears(date, amount);
}

/**
 * Given a date, a number, and either days or months we return a date that is that amount of days in the past to find the start date.
 * @example
 * // returns Tue Nov 14 2020
 * calculateStartDate(Tue Nov 17 2020, 3, 'days')
 * @example
 * // returns Thu Sept 17 2020
 * calculateStartDate(Thu Sept 17 2020, 2, 'months')
 * @param {Date} endDate - the end date of date range
 * @param {number} dur - the number of date units the date range is
 * @parm {DateDuration} unit - specifies whether we to "dur" days or "dur" months into the past for the start date
 * @returns {Date} Returns the date that is either "dur" days or "dur" months in the past in relation to the provided end date
 */
export function calculateStartDate(endDate: Date, dur: number, unit: DateDuration): Date {
  return unit === DateDuration.days ? subtractDays(endDate, dur) : subtractMonths(endDate, dur);
}

/**
 * @param {Date} value - a given date
 * @returns {string} Returns a localized string representation of the given date
 */
export function formatDate(
  value: Date,
  dateFormat: DateFormat | string = DateFormat.mediumLocalDate,
  locale?: Locale,
): string {
  if (!locale) {
    locale = getLocale();
  }
  const date = new Date(value);
  return format(date, dateFormat, {
    locale,
  });
}

/**
 * @param {Date} date - a given date
 * @returns {string} Returns a string representation of the given date in UTC
 */
export function formatUTCDate(
  date: Date,
  dateFormat: DateFormat | string = DateFormat.mediumLocalDate,
  locale?: Locale,
): string {
  return formatDate(getUTCTime(date), dateFormat, locale);
}

/**
 * @param {DateRange} dateRange - An object with start and end properties, both properties are of type Date
 * @returns {string} Returns a localized string representation of the start and end date, separated by a "-"
 */
export function formatDateRange(dateRange: DateRange): string {
  if (!dateRange || !dateRange.start || !dateRange.end) return '';
  return `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`;
}

export function formatUTCDateRange(dateRange: DateRange): string {
  if (!dateRange || !dateRange.start || !dateRange.end) return '';
  return `${formatUTCDate(dateRange.start)} - ${formatUTCDate(dateRange.end)}`;
}

/**
 * @returns {number} Returns the number of milliseconds that represent the timezone offset
 */
export function getTimezoneOffsetInMilliseconds(): number {
  return new Date().getTimezoneOffset() * MS_PER_MINUTE;
}

/**
 * @param {Date} date A date object
 * @returns {Date} returns the UTC time based off of the timezone of the client
 */
export function getUTCTime(date: Date): Date {
  return parseISO(date.toISOString().slice(0, -1));
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the start of the day (midnight)
 */
export function getStartOfDay(date: Date): Date {
  return startOfDay(date);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the start of the day (midnight) in UTC
 *  The start date of the UTC date from the given date
 *  e.g. date: Wed Aug 31 2022 23:59:59 GMT-0600 (Central Standard Time) => Sept 1st in UTC
 *       return: Thur Sept 01 2022 00:00:00 UTC
 */
export function getUTCStartOfDay(date: Date): Date {
  return normalizeDateFunc(date, startOfDay);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the end of the day (minute before midnight)
 */
export function getEndOfDay(date: Date): Date {
  return endOfDay(date);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the end of the day (minute before midnight) in UTC
 */
export function getUTCEndOfDay(date: Date): Date {
  return normalizeDateFunc(date, endOfDay);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the start of the week (midnight)
 */
export function getStartOfWeek(date: Date): Date {
  return startOfWeek(date);
}

/**
 * @param {Date} date - a given date
 * @param {string} unit - unit of time
 * @returns {Date} Returns the start of a unit for the given date.
 */
export function getStartOf(
  date: Date,
  unit: 'month' | 'week' | 'day',
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  },
): Date {
  switch (unit) {
    case 'month':
      return startOfMonth(date);
    case 'week':
      return startOfWeek(date, options);
    case 'day':
      return startOfDay(date);
  }
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the start of the month (midnight)
 */
export function getStartOfMonth(date: Date): Date {
  return startOfMonth(date);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the start of the month (midnight) in UTC
 */
export function getUTCStartOfMonth(date: Date): Date {
  return normalizeDateFunc(date, startOfMonth);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the end of the month (minute before midnight)
 */
export function getEndOfMonth(date: Date): Date {
  return endOfMonth(date);
}

/**
 * @param {Date} date - a given date
 * @returns {Date} Returns the given date at the end of the month (minute before midnight) in UTC
 *  The end of date of the UTC date from the given date
 *  e.g. date: Thur Sept 01 2022 00:00:00 GMT-0600 (Central Standard Time) => Aug 31st in UTC
 *       return: Wed Aug 31 2022 23:59:59 UTC
 */
export function getUTCEndOfMonth(date: Date): Date {
  return normalizeDateFunc(date, endOfMonth);
}

/**
 * @param {Date} endDate - a given date
 * @param {Date} startDate - a givend date, either on the same day as endDate, or in the past.
 * @returns {number} Returns the number of days in between the startDate and endDate.
 */
export function differenceInDays(endDate: Date, startDate: Date): number {
  return differenceInCalendarDays(endDate, startDate);
}

/**
 * @param {Date} endDate - a given date
 * @param {Date} startDate - a givend date, either on the same day as endDate, or in the past.
 * @returns {number} Returns the number of months in between the startDate and endDate.
 */
export function differenceInMonths(endDate: Date, startDate: Date): number {
  return diffInMonths(endDate, startDate);
}

/**
 * @param {Date} dateOne - a date object you want to compare from
 * @param {Date} dateTwo - a date object you want to compare against
 * @returns {boolean} Returns true if dateOne and dateTwo are dates with the same day
 */
export function isSameDay(dateOne: Date, dateTwo: Date): boolean {
  return sameDay(dateOne, dateTwo);
}

/**
 * @param {Date} dateOne - a date object you want to compare with
 * @param {Date} dateTwo - a date object you want to compare against
 * @returns {boolean} Returns true if dateOne is before dateTwo
 */
export function isBefore(dateOne: Date, dateTwo: Date): boolean {
  return before(dateOne, dateTwo);
}

/**
 * @param {Date} date - a given date
 * @param {Date} comparisonDate - a given date as the base for the relative comparison
 * @returns {string} Returns a localized string representation of the given date relative to the given base date
 */
export function formatTimeBetween(
  date: Date,
  comparisonDate: Date,
  includeSeconds = false,
  addSuffix = false,
  locale?: Locale,
): string {
  if (!locale) {
    locale = getLocale();
  }
  return formatDistance(date, comparisonDate, { includeSeconds, addSuffix, locale });
}

/**
 * @param {Date} date - a given date
 * @returns {string} Returns a localized string representation of the given date relative to now (ex: "3 days ago")
 */
export function fromNow(date: Date, locale?: Locale): string {
  return formatTimeBetween(date, new Date(), false, true, locale);
}

/**
 * In North America, weeks start on Sundays, but other locales start the week on Monday or Saturday.
 * This function returns the days of the week in order for a locale.
 * @returns {string[]} week in localized order (indexed to north american week order)
 */
export function localizedWeekOrder(locale?: Locale): number[] {
  if (!locale) {
    locale = getLocale();
  }
  const weekStart = localizedWeekStart(locale);
  return [0, 1, 2, 3, 4, 5, 6].map((index) => {
    switch (weekStart) {
      case 'sat':
        return (index + 6) % 7;
      case 'sun':
        return index;
      case 'mon':
        return (index + 1) % 7;
      default:
        return index;
    }
  });
}

/**
 * In North America, weeks start on Sundays, but other locales start the week on Monday or Saturday.
 * This function maps a given index to the day of the week at the current locale.
 * Ex. The 0th weekday in Canada is Sunday (0 -> 0), the 0th weekday in Germany is Monday (0 -> 1)
 * @param {number} index - the requested index
 * @returns {number} day of the week for a weekday (indexed to north american week order)
 */
export function localizedWeekday(index: number, locale?: Locale): number {
  if (!locale) {
    locale = getLocale();
  }
  const weekStart = localizedWeekStart(locale);
  switch (weekStart) {
    case 'sat':
      return (index + 6) % 7;
    case 'sun':
    default:
      return index;
    case 'mon':
      return (index + 1) % 7;
  }
}

/**
 * In North America, weeks start on Sundays, but other locales start the week on Monday or Saturday.
 * This function compares the locale against known values for the first day of the week.
 * @returns {string} first three letters of a day of the week
 */
export function localizedWeekStart(locale?: Locale): string {
  if (!locale) {
    locale = getLocale();
  }
  const parts = locale.code?.match(
    /^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i,
  );
  if (!parts) return 'sun';
  const [region, language] = [parts[4], parts[1]];

  const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g);
  const regionSun =
    'AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW'.match(
      /../g,
    );
  const languageSat = ['ar', 'arq', 'arz', 'fa'];
  const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'.match(/../g);

  return region
    ? regionSun?.includes(region)
      ? 'sun'
      : regionSat?.includes(region)
        ? 'sat'
        : 'mon'
    : languageSun?.includes(language)
      ? 'sun'
      : languageSat.includes(language)
        ? 'sat'
        : 'mon';
}
