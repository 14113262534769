import { NgModule } from '@angular/core';
import { VaIconModule } from '../va-icon/icon.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

import { VaActionMenuModule } from '../va-action-menu/va-action-menu.module';
import { VaListComponent } from './va-list.component';
import { VaListItemComponent } from './list-item/va-list-item.component';
import { ExpandedViewDirective } from './list-item/expanded-view.directive';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    VaIconModule,
    VaActionMenuModule,
  ],
  declarations: [ExpandedViewDirective, VaListComponent, VaListItemComponent],
  exports: [VaListComponent, ExpandedViewDirective],
})
export class VaListModule {}
