var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};
var webchannel_blob_es2018 = {};

/** @license
Copyright The Closure Library Authors.
SPDX-License-Identifier: Apache-2.0
*/

var XhrIo;
var FetchXmlHttpFactory;
var WebChannel;
var EventType;
var ErrorCode;
var Stat;
var Event;
var getStatEventTarget;
var createWebChannelTransport;
(function () {
  var h,
    aa = "function" == typeof Object.defineProperties ? Object.defineProperty : function (a, b, c) {
      if (a == Array.prototype || a == Object.prototype) return a;
      a[b] = c.value;
      return a;
    };
  function ba(a) {
    a = ["object" == typeof globalThis && globalThis, a, "object" == typeof window && window, "object" == typeof self && self, "object" == typeof commonjsGlobal && commonjsGlobal];
    for (var b = 0; b < a.length; ++b) {
      var c = a[b];
      if (c && c.Math == Math) return c;
    }
    throw Error("Cannot find global object");
  }
  var ca = ba(this);
  function da(a, b) {
    if (b) a: {
      var c = ca;
      a = a.split(".");
      for (var d = 0; d < a.length - 1; d++) {
        var e = a[d];
        if (!(e in c)) break a;
        c = c[e];
      }
      a = a[a.length - 1];
      d = c[a];
      b = b(d);
      b != d && null != b && aa(c, a, {
        configurable: !0,
        writable: !0,
        value: b
      });
    }
  }
  function ea(a, b) {
    a instanceof String && (a += "");
    var c = 0,
      d = !1,
      e = {
        next: function () {
          if (!d && c < a.length) {
            var f = c++;
            return {
              value: b(f, a[f]),
              done: !1
            };
          }
          d = !0;
          return {
            done: !0,
            value: void 0
          };
        }
      };
    e[Symbol.iterator] = function () {
      return e;
    };
    return e;
  }
  da("Array.prototype.values", function (a) {
    return a ? a : function () {
      return ea(this, function (b, c) {
        return c;
      });
    };
  }); /** @license
      Copyright The Closure Library Authors.
      SPDX-License-Identifier: Apache-2.0
      */
  var fa = fa || {},
    k = this || self;
  function ha(a) {
    var b = typeof a;
    b = "object" != b ? b : a ? Array.isArray(a) ? "array" : b : "null";
    return "array" == b || "object" == b && "number" == typeof a.length;
  }
  function n(a) {
    var b = typeof a;
    return "object" == b && null != a || "function" == b;
  }
  function ia(a, b, c) {
    return a.call.apply(a.bind, arguments);
  }
  function ja(a, b, c) {
    if (!a) throw Error();
    if (2 < arguments.length) {
      var d = Array.prototype.slice.call(arguments, 2);
      return function () {
        var e = Array.prototype.slice.call(arguments);
        Array.prototype.unshift.apply(e, d);
        return a.apply(b, e);
      };
    }
    return function () {
      return a.apply(b, arguments);
    };
  }
  function p(a, b, c) {
    p = Function.prototype.bind && -1 != Function.prototype.bind.toString().indexOf("native code") ? ia : ja;
    return p.apply(null, arguments);
  }
  function ka(a, b) {
    var c = Array.prototype.slice.call(arguments, 1);
    return function () {
      var d = c.slice();
      d.push.apply(d, arguments);
      return a.apply(this, d);
    };
  }
  function r(a, b) {
    function c() {}
    c.prototype = b.prototype;
    a.aa = b.prototype;
    a.prototype = new c();
    a.prototype.constructor = a;
    a.Qb = function (d, e, f) {
      for (var g = Array(arguments.length - 2), m = 2; m < arguments.length; m++) g[m - 2] = arguments[m];
      return b.prototype[e].apply(d, g);
    };
  }
  function la(a) {
    const b = a.length;
    if (0 < b) {
      const c = Array(b);
      for (let d = 0; d < b; d++) c[d] = a[d];
      return c;
    }
    return [];
  }
  function ma(a, b) {
    for (let c = 1; c < arguments.length; c++) {
      const d = arguments[c];
      if (ha(d)) {
        const e = a.length || 0,
          f = d.length || 0;
        a.length = e + f;
        for (let g = 0; g < f; g++) a[e + g] = d[g];
      } else a.push(d);
    }
  }
  class na {
    constructor(a, b) {
      this.i = a;
      this.j = b;
      this.h = 0;
      this.g = null;
    }
    get() {
      let a;
      0 < this.h ? (this.h--, a = this.g, this.g = a.next, a.next = null) : a = this.i();
      return a;
    }
  }
  function t(a) {
    return /^[\s\xa0]*$/.test(a);
  }
  function u() {
    var a = k.navigator;
    return a && (a = a.userAgent) ? a : "";
  }
  function oa(a) {
    oa[" "](a);
    return a;
  }
  oa[" "] = function () {};
  var pa = -1 != u().indexOf("Gecko") && !(-1 != u().toLowerCase().indexOf("webkit") && -1 == u().indexOf("Edge")) && !(-1 != u().indexOf("Trident") || -1 != u().indexOf("MSIE")) && -1 == u().indexOf("Edge");
  function qa(a, b, c) {
    for (const d in a) b.call(c, a[d], d, a);
  }
  function ra(a, b) {
    for (const c in a) b.call(void 0, a[c], c, a);
  }
  function sa(a) {
    const b = {};
    for (const c in a) b[c] = a[c];
    return b;
  }
  const ta = "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
  function ua(a, b) {
    let c, d;
    for (let e = 1; e < arguments.length; e++) {
      d = arguments[e];
      for (c in d) a[c] = d[c];
      for (let f = 0; f < ta.length; f++) c = ta[f], Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c]);
    }
  }
  function va(a) {
    var b = 1;
    a = a.split(":");
    const c = [];
    for (; 0 < b && a.length;) c.push(a.shift()), b--;
    a.length && c.push(a.join(":"));
    return c;
  }
  function wa(a) {
    k.setTimeout(() => {
      throw a;
    }, 0);
  }
  function xa() {
    var a = za;
    let b = null;
    a.g && (b = a.g, a.g = a.g.next, a.g || (a.h = null), b.next = null);
    return b;
  }
  class Aa {
    constructor() {
      this.h = this.g = null;
    }
    add(a, b) {
      const c = Ba.get();
      c.set(a, b);
      this.h ? this.h.next = c : this.g = c;
      this.h = c;
    }
  }
  var Ba = new na(() => new Ca(), a => a.reset());
  class Ca {
    constructor() {
      this.next = this.g = this.h = null;
    }
    set(a, b) {
      this.h = a;
      this.g = b;
      this.next = null;
    }
    reset() {
      this.next = this.g = this.h = null;
    }
  }
  let x,
    y = !1,
    za = new Aa(),
    Ea = () => {
      const a = k.Promise.resolve(void 0);
      x = () => {
        a.then(Da);
      };
    };
  var Da = () => {
    for (var a; a = xa();) {
      try {
        a.h.call(a.g);
      } catch (c) {
        wa(c);
      }
      var b = Ba;
      b.j(a);
      100 > b.h && (b.h++, a.next = b.g, b.g = a);
    }
    y = !1;
  };
  function z() {
    this.s = this.s;
    this.C = this.C;
  }
  z.prototype.s = !1;
  z.prototype.ma = function () {
    this.s || (this.s = !0, this.N());
  };
  z.prototype.N = function () {
    if (this.C) for (; this.C.length;) this.C.shift()();
  };
  function A(a, b) {
    this.type = a;
    this.g = this.target = b;
    this.defaultPrevented = !1;
  }
  A.prototype.h = function () {
    this.defaultPrevented = !0;
  };
  var Fa = function () {
    if (!k.addEventListener || !Object.defineProperty) return !1;
    var a = !1,
      b = Object.defineProperty({}, "passive", {
        get: function () {
          a = !0;
        }
      });
    try {
      const c = () => {};
      k.addEventListener("test", c, b);
      k.removeEventListener("test", c, b);
    } catch (c) {}
    return a;
  }();
  function C(a, b) {
    A.call(this, a ? a.type : "");
    this.relatedTarget = this.g = this.target = null;
    this.button = this.screenY = this.screenX = this.clientY = this.clientX = 0;
    this.key = "";
    this.metaKey = this.shiftKey = this.altKey = this.ctrlKey = !1;
    this.state = null;
    this.pointerId = 0;
    this.pointerType = "";
    this.i = null;
    if (a) {
      var c = this.type = a.type,
        d = a.changedTouches && a.changedTouches.length ? a.changedTouches[0] : null;
      this.target = a.target || a.srcElement;
      this.g = b;
      if (b = a.relatedTarget) {
        if (pa) {
          a: {
            try {
              oa(b.nodeName);
              var e = !0;
              break a;
            } catch (f) {}
            e = !1;
          }
          e || (b = null);
        }
      } else "mouseover" == c ? b = a.fromElement : "mouseout" == c && (b = a.toElement);
      this.relatedTarget = b;
      d ? (this.clientX = void 0 !== d.clientX ? d.clientX : d.pageX, this.clientY = void 0 !== d.clientY ? d.clientY : d.pageY, this.screenX = d.screenX || 0, this.screenY = d.screenY || 0) : (this.clientX = void 0 !== a.clientX ? a.clientX : a.pageX, this.clientY = void 0 !== a.clientY ? a.clientY : a.pageY, this.screenX = a.screenX || 0, this.screenY = a.screenY || 0);
      this.button = a.button;
      this.key = a.key || "";
      this.ctrlKey = a.ctrlKey;
      this.altKey = a.altKey;
      this.shiftKey = a.shiftKey;
      this.metaKey = a.metaKey;
      this.pointerId = a.pointerId || 0;
      this.pointerType = "string" === typeof a.pointerType ? a.pointerType : Ga[a.pointerType] || "";
      this.state = a.state;
      this.i = a;
      a.defaultPrevented && C.aa.h.call(this);
    }
  }
  r(C, A);
  var Ga = {
    2: "touch",
    3: "pen",
    4: "mouse"
  };
  C.prototype.h = function () {
    C.aa.h.call(this);
    var a = this.i;
    a.preventDefault ? a.preventDefault() : a.returnValue = !1;
  };
  var D = "closure_listenable_" + (1E6 * Math.random() | 0);
  var Ha = 0;
  function Ia(a, b, c, d, e) {
    this.listener = a;
    this.proxy = null;
    this.src = b;
    this.type = c;
    this.capture = !!d;
    this.ha = e;
    this.key = ++Ha;
    this.da = this.fa = !1;
  }
  function Ja(a) {
    a.da = !0;
    a.listener = null;
    a.proxy = null;
    a.src = null;
    a.ha = null;
  }
  function Ka(a) {
    this.src = a;
    this.g = {};
    this.h = 0;
  }
  Ka.prototype.add = function (a, b, c, d, e) {
    var f = a.toString();
    a = this.g[f];
    a || (a = this.g[f] = [], this.h++);
    var g = La(a, b, d, e);
    -1 < g ? (b = a[g], c || (b.fa = !1)) : (b = new Ia(b, this.src, f, !!d, e), b.fa = c, a.push(b));
    return b;
  };
  function Ma(a, b) {
    var c = b.type;
    if (c in a.g) {
      var d = a.g[c],
        e = Array.prototype.indexOf.call(d, b, void 0),
        f;
      (f = 0 <= e) && Array.prototype.splice.call(d, e, 1);
      f && (Ja(b), 0 == a.g[c].length && (delete a.g[c], a.h--));
    }
  }
  function La(a, b, c, d) {
    for (var e = 0; e < a.length; ++e) {
      var f = a[e];
      if (!f.da && f.listener == b && f.capture == !!c && f.ha == d) return e;
    }
    return -1;
  }
  var Na = "closure_lm_" + (1E6 * Math.random() | 0),
    Oa = {};
  function Qa(a, b, c, d, e) {
    if (d && d.once) return Ra(a, b, c, d, e);
    if (Array.isArray(b)) {
      for (var f = 0; f < b.length; f++) Qa(a, b[f], c, d, e);
      return null;
    }
    c = Sa(c);
    return a && a[D] ? a.K(b, c, n(d) ? !!d.capture : !!d, e) : Ta(a, b, c, !1, d, e);
  }
  function Ta(a, b, c, d, e, f) {
    if (!b) throw Error("Invalid event type");
    var g = n(e) ? !!e.capture : !!e,
      m = Ua(a);
    m || (a[Na] = m = new Ka(a));
    c = m.add(b, c, d, g, f);
    if (c.proxy) return c;
    d = Va();
    c.proxy = d;
    d.src = a;
    d.listener = c;
    if (a.addEventListener) Fa || (e = g), void 0 === e && (e = !1), a.addEventListener(b.toString(), d, e);else if (a.attachEvent) a.attachEvent(Wa(b.toString()), d);else if (a.addListener && a.removeListener) a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");
    return c;
  }
  function Va() {
    function a(c) {
      return b.call(a.src, a.listener, c);
    }
    const b = Xa;
    return a;
  }
  function Ra(a, b, c, d, e) {
    if (Array.isArray(b)) {
      for (var f = 0; f < b.length; f++) Ra(a, b[f], c, d, e);
      return null;
    }
    c = Sa(c);
    return a && a[D] ? a.L(b, c, n(d) ? !!d.capture : !!d, e) : Ta(a, b, c, !0, d, e);
  }
  function Ya(a, b, c, d, e) {
    if (Array.isArray(b)) for (var f = 0; f < b.length; f++) Ya(a, b[f], c, d, e);else (d = n(d) ? !!d.capture : !!d, c = Sa(c), a && a[D]) ? (a = a.i, b = String(b).toString(), b in a.g && (f = a.g[b], c = La(f, c, d, e), -1 < c && (Ja(f[c]), Array.prototype.splice.call(f, c, 1), 0 == f.length && (delete a.g[b], a.h--)))) : a && (a = Ua(a)) && (b = a.g[b.toString()], a = -1, b && (a = La(b, c, d, e)), (c = -1 < a ? b[a] : null) && Za(c));
  }
  function Za(a) {
    if ("number" !== typeof a && a && !a.da) {
      var b = a.src;
      if (b && b[D]) Ma(b.i, a);else {
        var c = a.type,
          d = a.proxy;
        b.removeEventListener ? b.removeEventListener(c, d, a.capture) : b.detachEvent ? b.detachEvent(Wa(c), d) : b.addListener && b.removeListener && b.removeListener(d);
        (c = Ua(b)) ? (Ma(c, a), 0 == c.h && (c.src = null, b[Na] = null)) : Ja(a);
      }
    }
  }
  function Wa(a) {
    return a in Oa ? Oa[a] : Oa[a] = "on" + a;
  }
  function Xa(a, b) {
    if (a.da) a = !0;else {
      b = new C(b, this);
      var c = a.listener,
        d = a.ha || a.src;
      a.fa && Za(a);
      a = c.call(d, b);
    }
    return a;
  }
  function Ua(a) {
    a = a[Na];
    return a instanceof Ka ? a : null;
  }
  var $a = "__closure_events_fn_" + (1E9 * Math.random() >>> 0);
  function Sa(a) {
    if ("function" === typeof a) return a;
    a[$a] || (a[$a] = function (b) {
      return a.handleEvent(b);
    });
    return a[$a];
  }
  function E() {
    z.call(this);
    this.i = new Ka(this);
    this.M = this;
    this.F = null;
  }
  r(E, z);
  E.prototype[D] = !0;
  E.prototype.removeEventListener = function (a, b, c, d) {
    Ya(this, a, b, c, d);
  };
  function F(a, b) {
    var c,
      d = a.F;
    if (d) for (c = []; d; d = d.F) c.push(d);
    a = a.M;
    d = b.type || b;
    if ("string" === typeof b) b = new A(b, a);else if (b instanceof A) b.target = b.target || a;else {
      var e = b;
      b = new A(d, a);
      ua(b, e);
    }
    e = !0;
    if (c) for (var f = c.length - 1; 0 <= f; f--) {
      var g = b.g = c[f];
      e = ab(g, d, !0, b) && e;
    }
    g = b.g = a;
    e = ab(g, d, !0, b) && e;
    e = ab(g, d, !1, b) && e;
    if (c) for (f = 0; f < c.length; f++) g = b.g = c[f], e = ab(g, d, !1, b) && e;
  }
  E.prototype.N = function () {
    E.aa.N.call(this);
    if (this.i) {
      var a = this.i,
        c;
      for (c in a.g) {
        for (var d = a.g[c], e = 0; e < d.length; e++) Ja(d[e]);
        delete a.g[c];
        a.h--;
      }
    }
    this.F = null;
  };
  E.prototype.K = function (a, b, c, d) {
    return this.i.add(String(a), b, !1, c, d);
  };
  E.prototype.L = function (a, b, c, d) {
    return this.i.add(String(a), b, !0, c, d);
  };
  function ab(a, b, c, d) {
    b = a.i.g[String(b)];
    if (!b) return !0;
    b = b.concat();
    for (var e = !0, f = 0; f < b.length; ++f) {
      var g = b[f];
      if (g && !g.da && g.capture == c) {
        var m = g.listener,
          q = g.ha || g.src;
        g.fa && Ma(a.i, g);
        e = !1 !== m.call(q, d) && e;
      }
    }
    return e && !d.defaultPrevented;
  }
  function bb(a, b, c) {
    if ("function" === typeof a) c && (a = p(a, c));else if (a && "function" == typeof a.handleEvent) a = p(a.handleEvent, a);else throw Error("Invalid listener argument");
    return 2147483647 < Number(b) ? -1 : k.setTimeout(a, b || 0);
  }
  function cb(a) {
    a.g = bb(() => {
      a.g = null;
      a.i && (a.i = !1, cb(a));
    }, a.l);
    const b = a.h;
    a.h = null;
    a.m.apply(null, b);
  }
  class eb extends z {
    constructor(a, b) {
      super();
      this.m = a;
      this.l = b;
      this.h = null;
      this.i = !1;
      this.g = null;
    }
    j(a) {
      this.h = arguments;
      this.g ? this.i = !0 : cb(this);
    }
    N() {
      super.N();
      this.g && (k.clearTimeout(this.g), this.g = null, this.i = !1, this.h = null);
    }
  }
  function G(a) {
    z.call(this);
    this.h = a;
    this.g = {};
  }
  r(G, z);
  var fb = [];
  function gb(a) {
    qa(a.g, function (b, c) {
      this.g.hasOwnProperty(c) && Za(b);
    }, a);
    a.g = {};
  }
  G.prototype.N = function () {
    G.aa.N.call(this);
    gb(this);
  };
  G.prototype.handleEvent = function () {
    throw Error("EventHandler.handleEvent not implemented");
  };
  var hb = k.JSON.stringify;
  var ib = k.JSON.parse;
  var jb = class {
    stringify(a) {
      return k.JSON.stringify(a, void 0);
    }
    parse(a) {
      return k.JSON.parse(a, void 0);
    }
  };
  function kb() {}
  kb.prototype.h = null;
  function lb(a) {
    return a.h || (a.h = a.i());
  }
  function mb() {}
  var H = {
    OPEN: "a",
    kb: "b",
    Ja: "c",
    wb: "d"
  };
  function nb() {
    A.call(this, "d");
  }
  r(nb, A);
  function ob() {
    A.call(this, "c");
  }
  r(ob, A);
  var I = {},
    pb = null;
  function qb() {
    return pb = pb || new E();
  }
  I.La = "serverreachability";
  function rb(a) {
    A.call(this, I.La, a);
  }
  r(rb, A);
  function J(a) {
    const b = qb();
    F(b, new rb(b));
  }
  I.STAT_EVENT = "statevent";
  function sb(a, b) {
    A.call(this, I.STAT_EVENT, a);
    this.stat = b;
  }
  r(sb, A);
  function K(a) {
    const b = qb();
    F(b, new sb(b, a));
  }
  I.Ma = "timingevent";
  function tb(a, b) {
    A.call(this, I.Ma, a);
    this.size = b;
  }
  r(tb, A);
  function ub(a, b) {
    if ("function" !== typeof a) throw Error("Fn must not be null and must be a function");
    return k.setTimeout(function () {
      a();
    }, b);
  }
  function vb() {
    this.g = !0;
  }
  vb.prototype.xa = function () {
    this.g = !1;
  };
  function wb(a, b, c, d, e, f) {
    a.info(function () {
      if (a.g) {
        if (f) {
          var g = "";
          for (var m = f.split("&"), q = 0; q < m.length; q++) {
            var l = m[q].split("=");
            if (1 < l.length) {
              var v = l[0];
              l = l[1];
              var w = v.split("_");
              g = 2 <= w.length && "type" == w[1] ? g + (v + "=" + l + "&") : g + (v + "=redacted&");
            }
          }
        } else g = null;
      } else g = f;
      return "XMLHTTP REQ (" + d + ") [attempt " + e + "]: " + b + "\n" + c + "\n" + g;
    });
  }
  function xb(a, b, c, d, e, f, g) {
    a.info(function () {
      return "XMLHTTP RESP (" + d + ") [ attempt " + e + "]: " + b + "\n" + c + "\n" + f + " " + g;
    });
  }
  function L(a, b, c, d) {
    a.info(function () {
      return "XMLHTTP TEXT (" + b + "): " + yb(a, c) + (d ? " " + d : "");
    });
  }
  function zb(a, b) {
    a.info(function () {
      return "TIMEOUT: " + b;
    });
  }
  vb.prototype.info = function () {};
  function yb(a, b) {
    if (!a.g) return b;
    if (!b) return null;
    try {
      var c = JSON.parse(b);
      if (c) for (a = 0; a < c.length; a++) if (Array.isArray(c[a])) {
        var d = c[a];
        if (!(2 > d.length)) {
          var e = d[1];
          if (Array.isArray(e) && !(1 > e.length)) {
            var f = e[0];
            if ("noop" != f && "stop" != f && "close" != f) for (var g = 1; g < e.length; g++) e[g] = "";
          }
        }
      }
      return hb(c);
    } catch (m) {
      return b;
    }
  }
  var Ab = {
    NO_ERROR: 0,
    gb: 1,
    tb: 2,
    sb: 3,
    nb: 4,
    rb: 5,
    ub: 6,
    Ia: 7,
    TIMEOUT: 8,
    xb: 9
  };
  var Bb = {
    lb: "complete",
    Hb: "success",
    Ja: "error",
    Ia: "abort",
    zb: "ready",
    Ab: "readystatechange",
    TIMEOUT: "timeout",
    vb: "incrementaldata",
    yb: "progress",
    ob: "downloadprogress",
    Pb: "uploadprogress"
  };
  var Cb;
  function Db() {}
  r(Db, kb);
  Db.prototype.g = function () {
    return new XMLHttpRequest();
  };
  Db.prototype.i = function () {
    return {};
  };
  Cb = new Db();
  function M(a, b, c, d) {
    this.j = a;
    this.i = b;
    this.l = c;
    this.R = d || 1;
    this.U = new G(this);
    this.I = 45E3;
    this.H = null;
    this.o = !1;
    this.m = this.A = this.v = this.L = this.F = this.S = this.B = null;
    this.D = [];
    this.g = null;
    this.C = 0;
    this.s = this.u = null;
    this.X = -1;
    this.J = !1;
    this.O = 0;
    this.M = null;
    this.W = this.K = this.T = this.P = !1;
    this.h = new Eb();
  }
  function Eb() {
    this.i = null;
    this.g = "";
    this.h = !1;
  }
  var Fb = {},
    Gb = {};
  function Hb(a, b, c) {
    a.L = 1;
    a.v = Ib(N(b));
    a.m = c;
    a.P = !0;
    Jb(a, null);
  }
  function Jb(a, b) {
    a.F = Date.now();
    Kb(a);
    a.A = N(a.v);
    var c = a.A,
      d = a.R;
    Array.isArray(d) || (d = [String(d)]);
    Lb(c.i, "t", d);
    a.C = 0;
    c = a.j.J;
    a.h = new Eb();
    a.g = Mb(a.j, c ? b : null, !a.m);
    0 < a.O && (a.M = new eb(p(a.Y, a, a.g), a.O));
    b = a.U;
    c = a.g;
    d = a.ca;
    var e = "readystatechange";
    Array.isArray(e) || (e && (fb[0] = e.toString()), e = fb);
    for (var f = 0; f < e.length; f++) {
      var g = Qa(c, e[f], d || b.handleEvent, !1, b.h || b);
      if (!g) break;
      b.g[g.key] = g;
    }
    b = a.H ? sa(a.H) : {};
    a.m ? (a.u || (a.u = "POST"), b["Content-Type"] = "application/x-www-form-urlencoded", a.g.ea(a.A, a.u, a.m, b)) : (a.u = "GET", a.g.ea(a.A, a.u, null, b));
    J();
    wb(a.i, a.u, a.A, a.l, a.R, a.m);
  }
  M.prototype.ca = function (a) {
    a = a.target;
    const b = this.M;
    b && 3 == P(a) ? b.j() : this.Y(a);
  };
  M.prototype.Y = function (a) {
    try {
      if (a == this.g) a: {
        const w = P(this.g);
        var b = this.g.Ba();
        const O = this.g.Z();
        if (!(3 > w) && (3 != w || this.g && (this.h.h || this.g.oa() || Nb(this.g)))) {
          this.J || 4 != w || 7 == b || (8 == b || 0 >= O ? J(3) : J(2));
          Ob(this);
          var c = this.g.Z();
          this.X = c;
          b: if (Pb(this)) {
            var d = Nb(this.g);
            a = "";
            var e = d.length,
              f = 4 == P(this.g);
            if (!this.h.i) {
              if ("undefined" === typeof TextDecoder) {
                Q(this);
                Qb(this);
                var g = "";
                break b;
              }
              this.h.i = new k.TextDecoder();
            }
            for (b = 0; b < e; b++) this.h.h = !0, a += this.h.i.decode(d[b], {
              stream: !(f && b == e - 1)
            });
            d.length = 0;
            this.h.g += a;
            this.C = 0;
            g = this.h.g;
          } else g = this.g.oa();
          this.o = 200 == c;
          xb(this.i, this.u, this.A, this.l, this.R, w, c);
          if (this.o) {
            if (this.T && !this.K) {
              b: {
                if (this.g) {
                  var m,
                    q = this.g;
                  if ((m = q.g ? q.g.getResponseHeader("X-HTTP-Initial-Response") : null) && !t(m)) {
                    var l = m;
                    break b;
                  }
                }
                l = null;
              }
              if (c = l) L(this.i, this.l, c, "Initial handshake response via X-HTTP-Initial-Response"), this.K = !0, Rb(this, c);else {
                this.o = !1;
                this.s = 3;
                K(12);
                Q(this);
                Qb(this);
                break a;
              }
            }
            if (this.P) {
              c = !0;
              let B;
              for (; !this.J && this.C < g.length;) if (B = Sb(this, g), B == Gb) {
                4 == w && (this.s = 4, K(14), c = !1);
                L(this.i, this.l, null, "[Incomplete Response]");
                break;
              } else if (B == Fb) {
                this.s = 4;
                K(15);
                L(this.i, this.l, g, "[Invalid Chunk]");
                c = !1;
                break;
              } else L(this.i, this.l, B, null), Rb(this, B);
              Pb(this) && 0 != this.C && (this.h.g = this.h.g.slice(this.C), this.C = 0);
              4 != w || 0 != g.length || this.h.h || (this.s = 1, K(16), c = !1);
              this.o = this.o && c;
              if (!c) L(this.i, this.l, g, "[Invalid Chunked Response]"), Q(this), Qb(this);else if (0 < g.length && !this.W) {
                this.W = !0;
                var v = this.j;
                v.g == this && v.ba && !v.M && (v.j.info("Great, no buffering proxy detected. Bytes received: " + g.length), Tb(v), v.M = !0, K(11));
              }
            } else L(this.i, this.l, g, null), Rb(this, g);
            4 == w && Q(this);
            this.o && !this.J && (4 == w ? Ub(this.j, this) : (this.o = !1, Kb(this)));
          } else Vb(this.g), 400 == c && 0 < g.indexOf("Unknown SID") ? (this.s = 3, K(12)) : (this.s = 0, K(13)), Q(this), Qb(this);
        }
      }
    } catch (w) {} finally {}
  };
  function Pb(a) {
    return a.g ? "GET" == a.u && 2 != a.L && a.j.Ca : !1;
  }
  function Sb(a, b) {
    var c = a.C,
      d = b.indexOf("\n", c);
    if (-1 == d) return Gb;
    c = Number(b.substring(c, d));
    if (isNaN(c)) return Fb;
    d += 1;
    if (d + c > b.length) return Gb;
    b = b.slice(d, d + c);
    a.C = d + c;
    return b;
  }
  M.prototype.cancel = function () {
    this.J = !0;
    Q(this);
  };
  function Kb(a) {
    a.S = Date.now() + a.I;
    Wb(a, a.I);
  }
  function Wb(a, b) {
    if (null != a.B) throw Error("WatchDog timer not null");
    a.B = ub(p(a.ba, a), b);
  }
  function Ob(a) {
    a.B && (k.clearTimeout(a.B), a.B = null);
  }
  M.prototype.ba = function () {
    this.B = null;
    const a = Date.now();
    0 <= a - this.S ? (zb(this.i, this.A), 2 != this.L && (J(), K(17)), Q(this), this.s = 2, Qb(this)) : Wb(this, this.S - a);
  };
  function Qb(a) {
    0 == a.j.G || a.J || Ub(a.j, a);
  }
  function Q(a) {
    Ob(a);
    var b = a.M;
    b && "function" == typeof b.ma && b.ma();
    a.M = null;
    gb(a.U);
    a.g && (b = a.g, a.g = null, b.abort(), b.ma());
  }
  function Rb(a, b) {
    try {
      var c = a.j;
      if (0 != c.G && (c.g == a || Xb(c.h, a))) if (!a.K && Xb(c.h, a) && 3 == c.G) {
        try {
          var d = c.Da.g.parse(b);
        } catch (l) {
          d = null;
        }
        if (Array.isArray(d) && 3 == d.length) {
          var e = d;
          if (0 == e[0]) a: {
            if (!c.u) {
              if (c.g) if (c.g.F + 3E3 < a.F) Yb(c), Zb(c);else break a;
              $b(c);
              K(18);
            }
          } else c.za = e[1], 0 < c.za - c.T && 37500 > e[2] && c.F && 0 == c.v && !c.C && (c.C = ub(p(c.Za, c), 6E3));
          if (1 >= ac(c.h) && c.ca) {
            try {
              c.ca();
            } catch (l) {}
            c.ca = void 0;
          }
        } else R(c, 11);
      } else if ((a.K || c.g == a) && Yb(c), !t(b)) for (e = c.Da.g.parse(b), b = 0; b < e.length; b++) {
        let l = e[b];
        c.T = l[0];
        l = l[1];
        if (2 == c.G) {
          if ("c" == l[0]) {
            c.K = l[1];
            c.ia = l[2];
            const v = l[3];
            null != v && (c.la = v, c.j.info("VER=" + c.la));
            const w = l[4];
            null != w && (c.Aa = w, c.j.info("SVER=" + c.Aa));
            const O = l[5];
            null != O && "number" === typeof O && 0 < O && (d = 1.5 * O, c.L = d, c.j.info("backChannelRequestTimeoutMs_=" + d));
            d = c;
            const B = a.g;
            if (B) {
              const ya = B.g ? B.g.getResponseHeader("X-Client-Wire-Protocol") : null;
              if (ya) {
                var f = d.h;
                f.g || -1 == ya.indexOf("spdy") && -1 == ya.indexOf("quic") && -1 == ya.indexOf("h2") || (f.j = f.l, f.g = new Set(), f.h && (bc(f, f.h), f.h = null));
              }
              if (d.D) {
                const db = B.g ? B.g.getResponseHeader("X-HTTP-Session-Id") : null;
                db && (d.ya = db, S(d.I, d.D, db));
              }
            }
            c.G = 3;
            c.l && c.l.ua();
            c.ba && (c.R = Date.now() - a.F, c.j.info("Handshake RTT: " + c.R + "ms"));
            d = c;
            var g = a;
            d.qa = cc(d, d.J ? d.ia : null, d.W);
            if (g.K) {
              dc(d.h, g);
              var m = g,
                q = d.L;
              q && (m.I = q);
              m.B && (Ob(m), Kb(m));
              d.g = g;
            } else ec(d);
            0 < c.i.length && fc(c);
          } else "stop" != l[0] && "close" != l[0] || R(c, 7);
        } else 3 == c.G && ("stop" == l[0] || "close" == l[0] ? "stop" == l[0] ? R(c, 7) : gc(c) : "noop" != l[0] && c.l && c.l.ta(l), c.v = 0);
      }
      J(4);
    } catch (l) {}
  }
  var hc = class {
    constructor(a, b) {
      this.g = a;
      this.map = b;
    }
  };
  function ic(a) {
    this.l = a || 10;
    k.PerformanceNavigationTiming ? (a = k.performance.getEntriesByType("navigation"), a = 0 < a.length && ("hq" == a[0].nextHopProtocol || "h2" == a[0].nextHopProtocol)) : a = !!(k.chrome && k.chrome.loadTimes && k.chrome.loadTimes() && k.chrome.loadTimes().wasFetchedViaSpdy);
    this.j = a ? this.l : 1;
    this.g = null;
    1 < this.j && (this.g = new Set());
    this.h = null;
    this.i = [];
  }
  function jc(a) {
    return a.h ? !0 : a.g ? a.g.size >= a.j : !1;
  }
  function ac(a) {
    return a.h ? 1 : a.g ? a.g.size : 0;
  }
  function Xb(a, b) {
    return a.h ? a.h == b : a.g ? a.g.has(b) : !1;
  }
  function bc(a, b) {
    a.g ? a.g.add(b) : a.h = b;
  }
  function dc(a, b) {
    a.h && a.h == b ? a.h = null : a.g && a.g.has(b) && a.g.delete(b);
  }
  ic.prototype.cancel = function () {
    this.i = kc(this);
    if (this.h) this.h.cancel(), this.h = null;else if (this.g && 0 !== this.g.size) {
      for (const a of this.g.values()) a.cancel();
      this.g.clear();
    }
  };
  function kc(a) {
    if (null != a.h) return a.i.concat(a.h.D);
    if (null != a.g && 0 !== a.g.size) {
      let b = a.i;
      for (const c of a.g.values()) b = b.concat(c.D);
      return b;
    }
    return la(a.i);
  }
  function lc(a) {
    if (a.V && "function" == typeof a.V) return a.V();
    if ("undefined" !== typeof Map && a instanceof Map || "undefined" !== typeof Set && a instanceof Set) return Array.from(a.values());
    if ("string" === typeof a) return a.split("");
    if (ha(a)) {
      for (var b = [], c = a.length, d = 0; d < c; d++) b.push(a[d]);
      return b;
    }
    b = [];
    c = 0;
    for (d in a) b[c++] = a[d];
    return b;
  }
  function mc(a) {
    if (a.na && "function" == typeof a.na) return a.na();
    if (!a.V || "function" != typeof a.V) {
      if ("undefined" !== typeof Map && a instanceof Map) return Array.from(a.keys());
      if (!("undefined" !== typeof Set && a instanceof Set)) {
        if (ha(a) || "string" === typeof a) {
          var b = [];
          a = a.length;
          for (var c = 0; c < a; c++) b.push(c);
          return b;
        }
        b = [];
        c = 0;
        for (const d in a) b[c++] = d;
        return b;
      }
    }
  }
  function nc(a, b) {
    if (a.forEach && "function" == typeof a.forEach) a.forEach(b, void 0);else if (ha(a) || "string" === typeof a) Array.prototype.forEach.call(a, b, void 0);else for (var c = mc(a), d = lc(a), e = d.length, f = 0; f < e; f++) b.call(void 0, d[f], c && c[f], a);
  }
  var oc = RegExp("^(?:([^:/?#.]+):)?(?://(?:([^\\\\/?#]*)@)?([^\\\\/?#]*?)(?::([0-9]+))?(?=[\\\\/?#]|$))?([^?#]+)?(?:\\?([^#]*))?(?:#([\\s\\S]*))?$");
  function pc(a, b) {
    if (a) {
      a = a.split("&");
      for (var c = 0; c < a.length; c++) {
        var d = a[c].indexOf("="),
          e = null;
        if (0 <= d) {
          var f = a[c].substring(0, d);
          e = a[c].substring(d + 1);
        } else f = a[c];
        b(f, e ? decodeURIComponent(e.replace(/\+/g, " ")) : "");
      }
    }
  }
  function T(a) {
    this.g = this.o = this.j = "";
    this.s = null;
    this.m = this.l = "";
    this.h = !1;
    if (a instanceof T) {
      this.h = a.h;
      qc(this, a.j);
      this.o = a.o;
      this.g = a.g;
      rc(this, a.s);
      this.l = a.l;
      var b = a.i;
      var c = new sc();
      c.i = b.i;
      b.g && (c.g = new Map(b.g), c.h = b.h);
      tc(this, c);
      this.m = a.m;
    } else a && (b = String(a).match(oc)) ? (this.h = !1, qc(this, b[1] || "", !0), this.o = uc(b[2] || ""), this.g = uc(b[3] || "", !0), rc(this, b[4]), this.l = uc(b[5] || "", !0), tc(this, b[6] || "", !0), this.m = uc(b[7] || "")) : (this.h = !1, this.i = new sc(null, this.h));
  }
  T.prototype.toString = function () {
    var a = [],
      b = this.j;
    b && a.push(vc(b, wc, !0), ":");
    var c = this.g;
    if (c || "file" == b) a.push("//"), (b = this.o) && a.push(vc(b, wc, !0), "@"), a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g, "%$1")), c = this.s, null != c && a.push(":", String(c));
    if (c = this.l) this.g && "/" != c.charAt(0) && a.push("/"), a.push(vc(c, "/" == c.charAt(0) ? xc : yc, !0));
    (c = this.i.toString()) && a.push("?", c);
    (c = this.m) && a.push("#", vc(c, zc));
    return a.join("");
  };
  function N(a) {
    return new T(a);
  }
  function qc(a, b, c) {
    a.j = c ? uc(b, !0) : b;
    a.j && (a.j = a.j.replace(/:$/, ""));
  }
  function rc(a, b) {
    if (b) {
      b = Number(b);
      if (isNaN(b) || 0 > b) throw Error("Bad port number " + b);
      a.s = b;
    } else a.s = null;
  }
  function tc(a, b, c) {
    b instanceof sc ? (a.i = b, Ac(a.i, a.h)) : (c || (b = vc(b, Bc)), a.i = new sc(b, a.h));
  }
  function S(a, b, c) {
    a.i.set(b, c);
  }
  function Ib(a) {
    S(a, "zx", Math.floor(2147483648 * Math.random()).toString(36) + Math.abs(Math.floor(2147483648 * Math.random()) ^ Date.now()).toString(36));
    return a;
  }
  function uc(a, b) {
    return a ? b ? decodeURI(a.replace(/%25/g, "%2525")) : decodeURIComponent(a) : "";
  }
  function vc(a, b, c) {
    return "string" === typeof a ? (a = encodeURI(a).replace(b, Cc), c && (a = a.replace(/%25([0-9a-fA-F]{2})/g, "%$1")), a) : null;
  }
  function Cc(a) {
    a = a.charCodeAt(0);
    return "%" + (a >> 4 & 15).toString(16) + (a & 15).toString(16);
  }
  var wc = /[#\/\?@]/g,
    yc = /[#\?:]/g,
    xc = /[#\?]/g,
    Bc = /[#\?@]/g,
    zc = /#/g;
  function sc(a, b) {
    this.h = this.g = null;
    this.i = a || null;
    this.j = !!b;
  }
  function U(a) {
    a.g || (a.g = new Map(), a.h = 0, a.i && pc(a.i, function (b, c) {
      a.add(decodeURIComponent(b.replace(/\+/g, " ")), c);
    }));
  }
  h = sc.prototype;
  h.add = function (a, b) {
    U(this);
    this.i = null;
    a = V(this, a);
    var c = this.g.get(a);
    c || this.g.set(a, c = []);
    c.push(b);
    this.h += 1;
    return this;
  };
  function Dc(a, b) {
    U(a);
    b = V(a, b);
    a.g.has(b) && (a.i = null, a.h -= a.g.get(b).length, a.g.delete(b));
  }
  function Ec(a, b) {
    U(a);
    b = V(a, b);
    return a.g.has(b);
  }
  h.forEach = function (a, b) {
    U(this);
    this.g.forEach(function (c, d) {
      c.forEach(function (e) {
        a.call(b, e, d, this);
      }, this);
    }, this);
  };
  h.na = function () {
    U(this);
    const a = Array.from(this.g.values()),
      b = Array.from(this.g.keys()),
      c = [];
    for (let d = 0; d < b.length; d++) {
      const e = a[d];
      for (let f = 0; f < e.length; f++) c.push(b[d]);
    }
    return c;
  };
  h.V = function (a) {
    U(this);
    let b = [];
    if ("string" === typeof a) Ec(this, a) && (b = b.concat(this.g.get(V(this, a))));else {
      a = Array.from(this.g.values());
      for (let c = 0; c < a.length; c++) b = b.concat(a[c]);
    }
    return b;
  };
  h.set = function (a, b) {
    U(this);
    this.i = null;
    a = V(this, a);
    Ec(this, a) && (this.h -= this.g.get(a).length);
    this.g.set(a, [b]);
    this.h += 1;
    return this;
  };
  h.get = function (a, b) {
    if (!a) return b;
    a = this.V(a);
    return 0 < a.length ? String(a[0]) : b;
  };
  function Lb(a, b, c) {
    Dc(a, b);
    0 < c.length && (a.i = null, a.g.set(V(a, b), la(c)), a.h += c.length);
  }
  h.toString = function () {
    if (this.i) return this.i;
    if (!this.g) return "";
    const a = [],
      b = Array.from(this.g.keys());
    for (var c = 0; c < b.length; c++) {
      var d = b[c];
      const f = encodeURIComponent(String(d)),
        g = this.V(d);
      for (d = 0; d < g.length; d++) {
        var e = f;
        "" !== g[d] && (e += "=" + encodeURIComponent(String(g[d])));
        a.push(e);
      }
    }
    return this.i = a.join("&");
  };
  function V(a, b) {
    b = String(b);
    a.j && (b = b.toLowerCase());
    return b;
  }
  function Ac(a, b) {
    b && !a.j && (U(a), a.i = null, a.g.forEach(function (c, d) {
      var e = d.toLowerCase();
      d != e && (Dc(this, d), Lb(this, e, c));
    }, a));
    a.j = b;
  }
  function Fc(a, b) {
    const c = new vb();
    if (k.Image) {
      const d = new Image();
      d.onload = ka(W, c, "TestLoadImage: loaded", !0, b, d);
      d.onerror = ka(W, c, "TestLoadImage: error", !1, b, d);
      d.onabort = ka(W, c, "TestLoadImage: abort", !1, b, d);
      d.ontimeout = ka(W, c, "TestLoadImage: timeout", !1, b, d);
      k.setTimeout(function () {
        if (d.ontimeout) d.ontimeout();
      }, 1E4);
      d.src = a;
    } else b(!1);
  }
  function Gc(a, b) {
    const c = new vb(),
      d = new AbortController(),
      e = setTimeout(() => {
        d.abort();
        W(c, "TestPingServer: timeout", !1, b);
      }, 1E4);
    fetch(a, {
      signal: d.signal
    }).then(f => {
      clearTimeout(e);
      f.ok ? W(c, "TestPingServer: ok", !0, b) : W(c, "TestPingServer: server error", !1, b);
    }).catch(() => {
      clearTimeout(e);
      W(c, "TestPingServer: error", !1, b);
    });
  }
  function W(a, b, c, d, e) {
    try {
      e && (e.onload = null, e.onerror = null, e.onabort = null, e.ontimeout = null), d(c);
    } catch (f) {}
  }
  function Hc() {
    this.g = new jb();
  }
  function Ic(a, b, c) {
    const d = c || "";
    try {
      nc(a, function (e, f) {
        let g = e;
        n(e) && (g = hb(e));
        b.push(d + f + "=" + encodeURIComponent(g));
      });
    } catch (e) {
      throw b.push(d + "type=" + encodeURIComponent("_badmap")), e;
    }
  }
  function Jc(a) {
    this.l = a.Ub || null;
    this.j = a.eb || !1;
  }
  r(Jc, kb);
  Jc.prototype.g = function () {
    return new Kc(this.l, this.j);
  };
  Jc.prototype.i = function (a) {
    return function () {
      return a;
    };
  }({});
  function Kc(a, b) {
    E.call(this);
    this.D = a;
    this.o = b;
    this.m = void 0;
    this.status = this.readyState = 0;
    this.responseType = this.responseText = this.response = this.statusText = "";
    this.onreadystatechange = null;
    this.u = new Headers();
    this.h = null;
    this.B = "GET";
    this.A = "";
    this.g = !1;
    this.v = this.j = this.l = null;
  }
  r(Kc, E);
  h = Kc.prototype;
  h.open = function (a, b) {
    if (0 != this.readyState) throw this.abort(), Error("Error reopening a connection");
    this.B = a;
    this.A = b;
    this.readyState = 1;
    Lc(this);
  };
  h.send = function (a) {
    if (1 != this.readyState) throw this.abort(), Error("need to call open() first. ");
    this.g = !0;
    const b = {
      headers: this.u,
      method: this.B,
      credentials: this.m,
      cache: void 0
    };
    a && (b.body = a);
    (this.D || k).fetch(new Request(this.A, b)).then(this.Sa.bind(this), this.ga.bind(this));
  };
  h.abort = function () {
    this.response = this.responseText = "";
    this.u = new Headers();
    this.status = 0;
    this.j && this.j.cancel("Request was aborted.").catch(() => {});
    1 <= this.readyState && this.g && 4 != this.readyState && (this.g = !1, Mc(this));
    this.readyState = 0;
  };
  h.Sa = function (a) {
    if (this.g && (this.l = a, this.h || (this.status = this.l.status, this.statusText = this.l.statusText, this.h = a.headers, this.readyState = 2, Lc(this)), this.g && (this.readyState = 3, Lc(this), this.g))) if ("arraybuffer" === this.responseType) a.arrayBuffer().then(this.Qa.bind(this), this.ga.bind(this));else if ("undefined" !== typeof k.ReadableStream && "body" in a) {
      this.j = a.body.getReader();
      if (this.o) {
        if (this.responseType) throw Error('responseType must be empty for "streamBinaryChunks" mode responses.');
        this.response = [];
      } else this.response = this.responseText = "", this.v = new TextDecoder();
      Nc(this);
    } else a.text().then(this.Ra.bind(this), this.ga.bind(this));
  };
  function Nc(a) {
    a.j.read().then(a.Pa.bind(a)).catch(a.ga.bind(a));
  }
  h.Pa = function (a) {
    if (this.g) {
      if (this.o && a.value) this.response.push(a.value);else if (!this.o) {
        var b = a.value ? a.value : new Uint8Array(0);
        if (b = this.v.decode(b, {
          stream: !a.done
        })) this.response = this.responseText += b;
      }
      a.done ? Mc(this) : Lc(this);
      3 == this.readyState && Nc(this);
    }
  };
  h.Ra = function (a) {
    this.g && (this.response = this.responseText = a, Mc(this));
  };
  h.Qa = function (a) {
    this.g && (this.response = a, Mc(this));
  };
  h.ga = function () {
    this.g && Mc(this);
  };
  function Mc(a) {
    a.readyState = 4;
    a.l = null;
    a.j = null;
    a.v = null;
    Lc(a);
  }
  h.setRequestHeader = function (a, b) {
    this.u.append(a, b);
  };
  h.getResponseHeader = function (a) {
    return this.h ? this.h.get(a.toLowerCase()) || "" : "";
  };
  h.getAllResponseHeaders = function () {
    if (!this.h) return "";
    const a = [],
      b = this.h.entries();
    for (var c = b.next(); !c.done;) c = c.value, a.push(c[0] + ": " + c[1]), c = b.next();
    return a.join("\r\n");
  };
  function Lc(a) {
    a.onreadystatechange && a.onreadystatechange.call(a);
  }
  Object.defineProperty(Kc.prototype, "withCredentials", {
    get: function () {
      return "include" === this.m;
    },
    set: function (a) {
      this.m = a ? "include" : "same-origin";
    }
  });
  function Oc(a) {
    let b = "";
    qa(a, function (c, d) {
      b += d;
      b += ":";
      b += c;
      b += "\r\n";
    });
    return b;
  }
  function Pc(a, b, c) {
    a: {
      for (d in c) {
        var d = !1;
        break a;
      }
      d = !0;
    }
    d || (c = Oc(c), "string" === typeof a ? null != c && encodeURIComponent(String(c)) : S(a, b, c));
  }
  function X(a) {
    E.call(this);
    this.headers = new Map();
    this.o = a || null;
    this.h = !1;
    this.v = this.g = null;
    this.D = "";
    this.m = 0;
    this.l = "";
    this.j = this.B = this.u = this.A = !1;
    this.I = null;
    this.H = "";
    this.J = !1;
  }
  r(X, E);
  var Qc = /^https?$/i,
    Rc = ["POST", "PUT"];
  h = X.prototype;
  h.Ha = function (a) {
    this.J = a;
  };
  h.ea = function (a, b, c, d) {
    if (this.g) throw Error("[goog.net.XhrIo] Object is active with another request=" + this.D + "; newUri=" + a);
    b = b ? b.toUpperCase() : "GET";
    this.D = a;
    this.l = "";
    this.m = 0;
    this.A = !1;
    this.h = !0;
    this.g = this.o ? this.o.g() : Cb.g();
    this.v = this.o ? lb(this.o) : lb(Cb);
    this.g.onreadystatechange = p(this.Ea, this);
    try {
      this.B = !0, this.g.open(b, String(a), !0), this.B = !1;
    } catch (f) {
      Sc(this, f);
      return;
    }
    a = c || "";
    c = new Map(this.headers);
    if (d) if (Object.getPrototypeOf(d) === Object.prototype) for (var e in d) c.set(e, d[e]);else if ("function" === typeof d.keys && "function" === typeof d.get) for (const f of d.keys()) c.set(f, d.get(f));else throw Error("Unknown input type for opt_headers: " + String(d));
    d = Array.from(c.keys()).find(f => "content-type" == f.toLowerCase());
    e = k.FormData && a instanceof k.FormData;
    !(0 <= Array.prototype.indexOf.call(Rc, b, void 0)) || d || e || c.set("Content-Type", "application/x-www-form-urlencoded;charset=utf-8");
    for (const [f, g] of c) this.g.setRequestHeader(f, g);
    this.H && (this.g.responseType = this.H);
    "withCredentials" in this.g && this.g.withCredentials !== this.J && (this.g.withCredentials = this.J);
    try {
      Tc(this), this.u = !0, this.g.send(a), this.u = !1;
    } catch (f) {
      Sc(this, f);
    }
  };
  function Sc(a, b) {
    a.h = !1;
    a.g && (a.j = !0, a.g.abort(), a.j = !1);
    a.l = b;
    a.m = 5;
    Uc(a);
    Vc(a);
  }
  function Uc(a) {
    a.A || (a.A = !0, F(a, "complete"), F(a, "error"));
  }
  h.abort = function (a) {
    this.g && this.h && (this.h = !1, this.j = !0, this.g.abort(), this.j = !1, this.m = a || 7, F(this, "complete"), F(this, "abort"), Vc(this));
  };
  h.N = function () {
    this.g && (this.h && (this.h = !1, this.j = !0, this.g.abort(), this.j = !1), Vc(this, !0));
    X.aa.N.call(this);
  };
  h.Ea = function () {
    this.s || (this.B || this.u || this.j ? Wc(this) : this.bb());
  };
  h.bb = function () {
    Wc(this);
  };
  function Wc(a) {
    if (a.h && "undefined" != typeof fa && (!a.v[1] || 4 != P(a) || 2 != a.Z())) if (a.u && 4 == P(a)) bb(a.Ea, 0, a);else if (F(a, "readystatechange"), 4 == P(a)) {
      a.h = !1;
      try {
        const g = a.Z();
        a: switch (g) {
          case 200:
          case 201:
          case 202:
          case 204:
          case 206:
          case 304:
          case 1223:
            var b = !0;
            break a;
          default:
            b = !1;
        }
        var c;
        if (!(c = b)) {
          var d;
          if (d = 0 === g) {
            var e = String(a.D).match(oc)[1] || null;
            !e && k.self && k.self.location && (e = k.self.location.protocol.slice(0, -1));
            d = !Qc.test(e ? e.toLowerCase() : "");
          }
          c = d;
        }
        if (c) F(a, "complete"), F(a, "success");else {
          a.m = 6;
          try {
            var f = 2 < P(a) ? a.g.statusText : "";
          } catch (m) {
            f = "";
          }
          a.l = f + " [" + a.Z() + "]";
          Uc(a);
        }
      } finally {
        Vc(a);
      }
    }
  }
  function Vc(a, b) {
    if (a.g) {
      Tc(a);
      const c = a.g,
        d = a.v[0] ? () => {} : null;
      a.g = null;
      a.v = null;
      b || F(a, "ready");
      try {
        c.onreadystatechange = d;
      } catch (e) {}
    }
  }
  function Tc(a) {
    a.I && (k.clearTimeout(a.I), a.I = null);
  }
  h.isActive = function () {
    return !!this.g;
  };
  function P(a) {
    return a.g ? a.g.readyState : 0;
  }
  h.Z = function () {
    try {
      return 2 < P(this) ? this.g.status : -1;
    } catch (a) {
      return -1;
    }
  };
  h.oa = function () {
    try {
      return this.g ? this.g.responseText : "";
    } catch (a) {
      return "";
    }
  };
  h.Oa = function (a) {
    if (this.g) {
      var b = this.g.responseText;
      a && 0 == b.indexOf(a) && (b = b.substring(a.length));
      return ib(b);
    }
  };
  function Nb(a) {
    try {
      if (!a.g) return null;
      if ("response" in a.g) return a.g.response;
      switch (a.H) {
        case "":
        case "text":
          return a.g.responseText;
        case "arraybuffer":
          if ("mozResponseArrayBuffer" in a.g) return a.g.mozResponseArrayBuffer;
      }
      return null;
    } catch (b) {
      return null;
    }
  }
  function Vb(a) {
    const b = {};
    a = (a.g && 2 <= P(a) ? a.g.getAllResponseHeaders() || "" : "").split("\r\n");
    for (let d = 0; d < a.length; d++) {
      if (t(a[d])) continue;
      var c = va(a[d]);
      const e = c[0];
      c = c[1];
      if ("string" !== typeof c) continue;
      c = c.trim();
      const f = b[e] || [];
      b[e] = f;
      f.push(c);
    }
    ra(b, function (d) {
      return d.join(", ");
    });
  }
  h.Ba = function () {
    return this.m;
  };
  h.Ka = function () {
    return "string" === typeof this.l ? this.l : String(this.l);
  };
  function Xc(a, b, c) {
    return c && c.internalChannelParams ? c.internalChannelParams[a] || b : b;
  }
  function Yc(a) {
    this.Aa = 0;
    this.i = [];
    this.j = new vb();
    this.ia = this.qa = this.I = this.W = this.g = this.ya = this.D = this.H = this.m = this.S = this.o = null;
    this.Ya = this.U = 0;
    this.Va = Xc("failFast", !1, a);
    this.F = this.C = this.u = this.s = this.l = null;
    this.X = !0;
    this.za = this.T = -1;
    this.Y = this.v = this.B = 0;
    this.Ta = Xc("baseRetryDelayMs", 5E3, a);
    this.cb = Xc("retryDelaySeedMs", 1E4, a);
    this.Wa = Xc("forwardChannelMaxRetries", 2, a);
    this.wa = Xc("forwardChannelRequestTimeoutMs", 2E4, a);
    this.pa = a && a.xmlHttpFactory || void 0;
    this.Xa = a && a.Tb || void 0;
    this.Ca = a && a.useFetchStreams || !1;
    this.L = void 0;
    this.J = a && a.supportsCrossDomainXhr || !1;
    this.K = "";
    this.h = new ic(a && a.concurrentRequestLimit);
    this.Da = new Hc();
    this.P = a && a.fastHandshake || !1;
    this.O = a && a.encodeInitMessageHeaders || !1;
    this.P && this.O && (this.O = !1);
    this.Ua = a && a.Rb || !1;
    a && a.xa && this.j.xa();
    a && a.forceLongPolling && (this.X = !1);
    this.ba = !this.P && this.X && a && a.detectBufferingProxy || !1;
    this.ja = void 0;
    a && a.longPollingTimeout && 0 < a.longPollingTimeout && (this.ja = a.longPollingTimeout);
    this.ca = void 0;
    this.R = 0;
    this.M = !1;
    this.ka = this.A = null;
  }
  h = Yc.prototype;
  h.la = 8;
  h.G = 1;
  h.connect = function (a, b, c, d) {
    K(0);
    this.W = a;
    this.H = b || {};
    c && void 0 !== d && (this.H.OSID = c, this.H.OAID = d);
    this.F = this.X;
    this.I = cc(this, null, this.W);
    fc(this);
  };
  function gc(a) {
    Zc(a);
    if (3 == a.G) {
      var b = a.U++,
        c = N(a.I);
      S(c, "SID", a.K);
      S(c, "RID", b);
      S(c, "TYPE", "terminate");
      $c(a, c);
      b = new M(a, a.j, b);
      b.L = 2;
      b.v = Ib(N(c));
      c = !1;
      if (k.navigator && k.navigator.sendBeacon) try {
        c = k.navigator.sendBeacon(b.v.toString(), "");
      } catch (d) {}
      !c && k.Image && (new Image().src = b.v, c = !0);
      c || (b.g = Mb(b.j, null), b.g.ea(b.v));
      b.F = Date.now();
      Kb(b);
    }
    ad(a);
  }
  function Zb(a) {
    a.g && (Tb(a), a.g.cancel(), a.g = null);
  }
  function Zc(a) {
    Zb(a);
    a.u && (k.clearTimeout(a.u), a.u = null);
    Yb(a);
    a.h.cancel();
    a.s && ("number" === typeof a.s && k.clearTimeout(a.s), a.s = null);
  }
  function fc(a) {
    if (!jc(a.h) && !a.s) {
      a.s = !0;
      var b = a.Ga;
      x || Ea();
      y || (x(), y = !0);
      za.add(b, a);
      a.B = 0;
    }
  }
  function bd(a, b) {
    if (ac(a.h) >= a.h.j - (a.s ? 1 : 0)) return !1;
    if (a.s) return a.i = b.D.concat(a.i), !0;
    if (1 == a.G || 2 == a.G || a.B >= (a.Va ? 0 : a.Wa)) return !1;
    a.s = ub(p(a.Ga, a, b), cd(a, a.B));
    a.B++;
    return !0;
  }
  h.Ga = function (a) {
    if (this.s) if (this.s = null, 1 == this.G) {
      if (!a) {
        this.U = Math.floor(1E5 * Math.random());
        a = this.U++;
        const e = new M(this, this.j, a);
        let f = this.o;
        this.S && (f ? (f = sa(f), ua(f, this.S)) : f = this.S);
        null !== this.m || this.O || (e.H = f, f = null);
        if (this.P) a: {
          var b = 0;
          for (var c = 0; c < this.i.length; c++) {
            b: {
              var d = this.i[c];
              if ("__data__" in d.map && (d = d.map.__data__, "string" === typeof d)) {
                d = d.length;
                break b;
              }
              d = void 0;
            }
            if (void 0 === d) break;
            b += d;
            if (4096 < b) {
              b = c;
              break a;
            }
            if (4096 === b || c === this.i.length - 1) {
              b = c + 1;
              break a;
            }
          }
          b = 1E3;
        } else b = 1E3;
        b = dd(this, e, b);
        c = N(this.I);
        S(c, "RID", a);
        S(c, "CVER", 22);
        this.D && S(c, "X-HTTP-Session-Id", this.D);
        $c(this, c);
        f && (this.O ? b = "headers=" + encodeURIComponent(String(Oc(f))) + "&" + b : this.m && Pc(c, this.m, f));
        bc(this.h, e);
        this.Ua && S(c, "TYPE", "init");
        this.P ? (S(c, "$req", b), S(c, "SID", "null"), e.T = !0, Hb(e, c, null)) : Hb(e, c, b);
        this.G = 2;
      }
    } else 3 == this.G && (a ? ed(this, a) : 0 == this.i.length || jc(this.h) || ed(this));
  };
  function ed(a, b) {
    var c;
    b ? c = b.l : c = a.U++;
    const d = N(a.I);
    S(d, "SID", a.K);
    S(d, "RID", c);
    S(d, "AID", a.T);
    $c(a, d);
    a.m && a.o && Pc(d, a.m, a.o);
    c = new M(a, a.j, c, a.B + 1);
    null === a.m && (c.H = a.o);
    b && (a.i = b.D.concat(a.i));
    b = dd(a, c, 1E3);
    c.I = Math.round(.5 * a.wa) + Math.round(.5 * a.wa * Math.random());
    bc(a.h, c);
    Hb(c, d, b);
  }
  function $c(a, b) {
    a.H && qa(a.H, function (c, d) {
      S(b, d, c);
    });
    a.l && nc({}, function (c, d) {
      S(b, d, c);
    });
  }
  function dd(a, b, c) {
    c = Math.min(a.i.length, c);
    var d = a.l ? p(a.l.Na, a.l, a) : null;
    a: {
      var e = a.i;
      let f = -1;
      for (;;) {
        const g = ["count=" + c];
        -1 == f ? 0 < c ? (f = e[0].g, g.push("ofs=" + f)) : f = 0 : g.push("ofs=" + f);
        let m = !0;
        for (let q = 0; q < c; q++) {
          let l = e[q].g;
          const v = e[q].map;
          l -= f;
          if (0 > l) f = Math.max(0, e[q].g - 100), m = !1;else try {
            Ic(v, g, "req" + l + "_");
          } catch (w) {
            d && d(v);
          }
        }
        if (m) {
          d = g.join("&");
          break a;
        }
      }
    }
    a = a.i.splice(0, c);
    b.D = a;
    return d;
  }
  function ec(a) {
    if (!a.g && !a.u) {
      a.Y = 1;
      var b = a.Fa;
      x || Ea();
      y || (x(), y = !0);
      za.add(b, a);
      a.v = 0;
    }
  }
  function $b(a) {
    if (a.g || a.u || 3 <= a.v) return !1;
    a.Y++;
    a.u = ub(p(a.Fa, a), cd(a, a.v));
    a.v++;
    return !0;
  }
  h.Fa = function () {
    this.u = null;
    fd(this);
    if (this.ba && !(this.M || null == this.g || 0 >= this.R)) {
      var a = 2 * this.R;
      this.j.info("BP detection timer enabled: " + a);
      this.A = ub(p(this.ab, this), a);
    }
  };
  h.ab = function () {
    this.A && (this.A = null, this.j.info("BP detection timeout reached."), this.j.info("Buffering proxy detected and switch to long-polling!"), this.F = !1, this.M = !0, K(10), Zb(this), fd(this));
  };
  function Tb(a) {
    null != a.A && (k.clearTimeout(a.A), a.A = null);
  }
  function fd(a) {
    a.g = new M(a, a.j, "rpc", a.Y);
    null === a.m && (a.g.H = a.o);
    a.g.O = 0;
    var b = N(a.qa);
    S(b, "RID", "rpc");
    S(b, "SID", a.K);
    S(b, "AID", a.T);
    S(b, "CI", a.F ? "0" : "1");
    !a.F && a.ja && S(b, "TO", a.ja);
    S(b, "TYPE", "xmlhttp");
    $c(a, b);
    a.m && a.o && Pc(b, a.m, a.o);
    a.L && (a.g.I = a.L);
    var c = a.g;
    a = a.ia;
    c.L = 1;
    c.v = Ib(N(b));
    c.m = null;
    c.P = !0;
    Jb(c, a);
  }
  h.Za = function () {
    null != this.C && (this.C = null, Zb(this), $b(this), K(19));
  };
  function Yb(a) {
    null != a.C && (k.clearTimeout(a.C), a.C = null);
  }
  function Ub(a, b) {
    var c = null;
    if (a.g == b) {
      Yb(a);
      Tb(a);
      a.g = null;
      var d = 2;
    } else if (Xb(a.h, b)) c = b.D, dc(a.h, b), d = 1;else return;
    if (0 != a.G) if (b.o) {
      if (1 == d) {
        c = b.m ? b.m.length : 0;
        b = Date.now() - b.F;
        var e = a.B;
        d = qb();
        F(d, new tb(d, c));
        fc(a);
      } else ec(a);
    } else if (e = b.s, 3 == e || 0 == e && 0 < b.X || !(1 == d && bd(a, b) || 2 == d && $b(a))) switch (c && 0 < c.length && (b = a.h, b.i = b.i.concat(c)), e) {
      case 1:
        R(a, 5);
        break;
      case 4:
        R(a, 10);
        break;
      case 3:
        R(a, 6);
        break;
      default:
        R(a, 2);
    }
  }
  function cd(a, b) {
    let c = a.Ta + Math.floor(Math.random() * a.cb);
    a.isActive() || (c *= 2);
    return c * b;
  }
  function R(a, b) {
    a.j.info("Error code " + b);
    if (2 == b) {
      var c = p(a.fb, a),
        d = a.Xa;
      const e = !d;
      d = new T(d || "//www.google.com/images/cleardot.gif");
      k.location && "http" == k.location.protocol || qc(d, "https");
      Ib(d);
      e ? Fc(d.toString(), c) : Gc(d.toString(), c);
    } else K(2);
    a.G = 0;
    a.l && a.l.sa(b);
    ad(a);
    Zc(a);
  }
  h.fb = function (a) {
    a ? (this.j.info("Successfully pinged google.com"), K(2)) : (this.j.info("Failed to ping google.com"), K(1));
  };
  function ad(a) {
    a.G = 0;
    a.ka = [];
    if (a.l) {
      const b = kc(a.h);
      if (0 != b.length || 0 != a.i.length) ma(a.ka, b), ma(a.ka, a.i), a.h.i.length = 0, la(a.i), a.i.length = 0;
      a.l.ra();
    }
  }
  function cc(a, b, c) {
    var d = c instanceof T ? N(c) : new T(c);
    if ("" != d.g) b && (d.g = b + "." + d.g), rc(d, d.s);else {
      var e = k.location;
      d = e.protocol;
      b = b ? b + "." + e.hostname : e.hostname;
      e = +e.port;
      var f = new T(null);
      d && qc(f, d);
      b && (f.g = b);
      e && rc(f, e);
      c && (f.l = c);
      d = f;
    }
    c = a.D;
    b = a.ya;
    c && b && S(d, c, b);
    S(d, "VER", a.la);
    $c(a, d);
    return d;
  }
  function Mb(a, b, c) {
    if (b && !a.J) throw Error("Can't create secondary domain capable XhrIo object.");
    b = a.Ca && !a.pa ? new X(new Jc({
      eb: c
    })) : new X(a.pa);
    b.Ha(a.J);
    return b;
  }
  h.isActive = function () {
    return !!this.l && this.l.isActive(this);
  };
  function gd() {}
  h = gd.prototype;
  h.ua = function () {};
  h.ta = function () {};
  h.sa = function () {};
  h.ra = function () {};
  h.isActive = function () {
    return !0;
  };
  h.Na = function () {};
  function hd() {}
  hd.prototype.g = function (a, b) {
    return new Y(a, b);
  };
  function Y(a, b) {
    E.call(this);
    this.g = new Yc(b);
    this.l = a;
    this.h = b && b.messageUrlParams || null;
    a = b && b.messageHeaders || null;
    b && b.clientProtocolHeaderRequired && (a ? a["X-Client-Protocol"] = "webchannel" : a = {
      "X-Client-Protocol": "webchannel"
    });
    this.g.o = a;
    a = b && b.initMessageHeaders || null;
    b && b.messageContentType && (a ? a["X-WebChannel-Content-Type"] = b.messageContentType : a = {
      "X-WebChannel-Content-Type": b.messageContentType
    });
    b && b.va && (a ? a["X-WebChannel-Client-Profile"] = b.va : a = {
      "X-WebChannel-Client-Profile": b.va
    });
    this.g.S = a;
    (a = b && b.Sb) && !t(a) && (this.g.m = a);
    this.v = b && b.supportsCrossDomainXhr || !1;
    this.u = b && b.sendRawJson || !1;
    (b = b && b.httpSessionIdParam) && !t(b) && (this.g.D = b, a = this.h, null !== a && b in a && (a = this.h, b in a && delete a[b]));
    this.j = new Z(this);
  }
  r(Y, E);
  Y.prototype.m = function () {
    this.g.l = this.j;
    this.v && (this.g.J = !0);
    this.g.connect(this.l, this.h || void 0);
  };
  Y.prototype.close = function () {
    gc(this.g);
  };
  Y.prototype.o = function (a) {
    var b = this.g;
    if ("string" === typeof a) {
      var c = {};
      c.__data__ = a;
      a = c;
    } else this.u && (c = {}, c.__data__ = hb(a), a = c);
    b.i.push(new hc(b.Ya++, a));
    3 == b.G && fc(b);
  };
  Y.prototype.N = function () {
    this.g.l = null;
    delete this.j;
    gc(this.g);
    delete this.g;
    Y.aa.N.call(this);
  };
  function id(a) {
    nb.call(this);
    a.__headers__ && (this.headers = a.__headers__, this.statusCode = a.__status__, delete a.__headers__, delete a.__status__);
    var b = a.__sm__;
    if (b) {
      a: {
        for (const c in b) {
          a = c;
          break a;
        }
        a = void 0;
      }
      if (this.i = a) a = this.i, b = null !== b && a in b ? b[a] : void 0;
      this.data = b;
    } else this.data = a;
  }
  r(id, nb);
  function jd() {
    ob.call(this);
    this.status = 1;
  }
  r(jd, ob);
  function Z(a) {
    this.g = a;
  }
  r(Z, gd);
  Z.prototype.ua = function () {
    F(this.g, "a");
  };
  Z.prototype.ta = function (a) {
    F(this.g, new id(a));
  };
  Z.prototype.sa = function (a) {
    F(this.g, new jd());
  };
  Z.prototype.ra = function () {
    F(this.g, "b");
  };
  hd.prototype.createWebChannel = hd.prototype.g;
  Y.prototype.send = Y.prototype.o;
  Y.prototype.open = Y.prototype.m;
  Y.prototype.close = Y.prototype.close;
  createWebChannelTransport = webchannel_blob_es2018.createWebChannelTransport = function () {
    return new hd();
  };
  getStatEventTarget = webchannel_blob_es2018.getStatEventTarget = function () {
    return qb();
  };
  Event = webchannel_blob_es2018.Event = I;
  Stat = webchannel_blob_es2018.Stat = {
    mb: 0,
    pb: 1,
    qb: 2,
    Jb: 3,
    Ob: 4,
    Lb: 5,
    Mb: 6,
    Kb: 7,
    Ib: 8,
    Nb: 9,
    PROXY: 10,
    NOPROXY: 11,
    Gb: 12,
    Cb: 13,
    Db: 14,
    Bb: 15,
    Eb: 16,
    Fb: 17,
    ib: 18,
    hb: 19,
    jb: 20
  };
  Ab.NO_ERROR = 0;
  Ab.TIMEOUT = 8;
  Ab.HTTP_ERROR = 6;
  ErrorCode = webchannel_blob_es2018.ErrorCode = Ab;
  Bb.COMPLETE = "complete";
  EventType = webchannel_blob_es2018.EventType = Bb;
  mb.EventType = H;
  H.OPEN = "a";
  H.CLOSE = "b";
  H.ERROR = "c";
  H.MESSAGE = "d";
  E.prototype.listen = E.prototype.K;
  WebChannel = webchannel_blob_es2018.WebChannel = mb;
  FetchXmlHttpFactory = webchannel_blob_es2018.FetchXmlHttpFactory = Jc;
  X.prototype.listenOnce = X.prototype.L;
  X.prototype.getLastError = X.prototype.Ka;
  X.prototype.getLastErrorCode = X.prototype.Ba;
  X.prototype.getStatus = X.prototype.Z;
  X.prototype.getResponseJson = X.prototype.Oa;
  X.prototype.getResponseText = X.prototype.oa;
  X.prototype.send = X.prototype.ea;
  X.prototype.setWithCredentials = X.prototype.Ha;
  XhrIo = webchannel_blob_es2018.XhrIo = X;
}).apply(typeof commonjsGlobal !== 'undefined' ? commonjsGlobal : typeof self !== 'undefined' ? self : typeof window !== 'undefined' ? window : {});
export { ErrorCode, Event, EventType, FetchXmlHttpFactory, Stat, WebChannel, XhrIo, createWebChannelTransport, webchannel_blob_es2018 as default, getStatEventTarget };
