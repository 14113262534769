import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlagsApiService } from '@vendasta/partner';
import {
  BatchGetFeatureFlagRequest,
  BatchGetFeatureFlagResponse,
  BatchGetStatusFeatureFlagRequest,
  BatchGetStatusFeatureFlagResponse,
  ListFeatureFlagRequest,
  UpdateFeatureFlagRequest,
  CreateFeatureFlagRequest,
  CreateAnalyticsDashboardRequest,
} from '@vendasta/partner';
import { FeatureFlag } from '@vendasta/partner';
import {
  ListFeatureFlagFiltersInterface,
  ListFeatureFlagResponseInterface,
  UpdateFeatureFlagRequestMutationInterface,
  CreateFeatureFlagRequestInterface,
} from '@vendasta/partner';

export interface FeatureFlagStatusInterface {
  [status: string]: boolean;
}

export interface ListFeatureFlagFilters extends ListFeatureFlagFiltersInterface {
  archivedOnly?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(private api: FeatureFlagsApiService) {}

  batchGetStatus(partnerId: string, marketId: string, featureIds: string[]): Observable<FeatureFlagStatusInterface> {
    const req = new BatchGetStatusFeatureFlagRequest({
      featureIds: featureIds,
      partnerId: partnerId,
      marketId: marketId,
    });
    return this.api.batchGetStatus(req).pipe(
      map((resp: BatchGetStatusFeatureFlagResponse): FeatureFlagStatusInterface => {
        const statuses: FeatureFlagStatusInterface = {};
        resp.hasAccess.forEach((status, i) => {
          statuses[featureIds[i]] = status;
        });
        return statuses;
      }),
    );
  }

  batchGet(featureFlagIds: string[]): Observable<FeatureFlag[]> {
    return this.api
      .batchGet(new BatchGetFeatureFlagRequest({ ids: featureFlagIds }))
      .pipe(map((resp: BatchGetFeatureFlagResponse) => resp.flags));
  }

  update(id: string, mutations: UpdateFeatureFlagRequestMutationInterface[]): Observable<boolean> {
    return this.api.update(new UpdateFeatureFlagRequest({ id: id, mutations: mutations })).pipe(map(() => true));
  }

  create(featureFlag: CreateFeatureFlagRequestInterface): Observable<boolean> {
    return this.api.create(new CreateFeatureFlagRequest(featureFlag)).pipe(map(() => true));
  }

  list(
    cursor: string,
    pageSize: number,
    filters: ListFeatureFlagFilters,
  ): Observable<ListFeatureFlagResponseInterface> {
    return this.api.list(
      new ListFeatureFlagRequest({
        archivedOnly: filters.archivedOnly,
        cursor: cursor,
        pageSize: pageSize,
        filters: { searchTerm: filters.searchTerm, accessLevel: filters.accessLevel },
      }),
    );
  }

  createAnalyticsDashboard(featureFlagID: string, userID: string): Observable<boolean> {
    const request = { featureFlagId: featureFlagID, userId: userID };
    return this.api.createAnalyticsDashboard(new CreateAnalyticsDashboardRequest(request)).pipe(map(() => true));
  }
}
