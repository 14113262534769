import { Inject, Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { LEXICON_BASE_TRANSLATION } from './constants';

interface InterpolateParams {
  [key: string]: string;
}

@Injectable()
export class LexiconMissingTranslationHandler implements MissingTranslationHandler {
  constructor(@Inject(LEXICON_BASE_TRANSLATION) private baseTranslations: any[]) {}

  handle(params: MissingTranslationHandlerParams): string {
    for (let baseTranslation of this.baseTranslations) {
      if (Object.prototype.hasOwnProperty.call(baseTranslation, params.key)) {
        baseTranslation = baseTranslation[params.key];
      }
      const parts = params.key.split('.', -1);
      for (const part of parts) {
        if (Object.prototype.hasOwnProperty.call(baseTranslation, part)) {
          baseTranslation = baseTranslation[part];
        }
      }
      if (typeof baseTranslation === 'string') {
        if (typeof params.interpolateParams === 'object') {
          if (this.isInterpolateParams(params.interpolateParams)) {
            for (const [param, value] of Object.entries<string>(params.interpolateParams)) {
              baseTranslation = baseTranslation.replace(new RegExp(`{{ ?${param} ?}}`, 'g'), value);
            }
          }
        }
        return baseTranslation;
      }
    }
    return params.key;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  isInterpolateParams(obj: Object): obj is InterpolateParams {
    return Object.values(obj).every((value) => typeof value === 'string' || typeof value === 'number');
  }
}
