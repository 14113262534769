import { Injectable } from '@angular/core';

export interface ColumnInfo {
  id: string;
  name?: string;
  hidden?: boolean;
}

@Injectable()
export class ColumnSorterService {
  loadSavedColumnInfo(columnInfo: ColumnInfo[], saveName?: string): ColumnInfo[] | undefined {
    // Only load if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return;
      }

      const loadedInfoJSON = localStorage.getItem(`${saveName}-columns`);

      // Found save data, validate the data
      if (loadedInfoJSON) {
        return this.validateSavedData(columnInfo, JSON.parse(loadedInfoJSON), saveName);
      }

      this.saveColumnInfo(columnInfo, saveName);
    }
    return columnInfo;
  }

  private validateSavedData(
    passedInColumns: ColumnInfo[],
    loadedColumns: ColumnInfo[],
    saveName?: string,
  ): ColumnInfo[] {
    // Check to make sure all columns are accounted for
    //  This will add any new columns to the end of the list
    passedInColumns.forEach((col) => {
      const colFound = loadedColumns.find((searchCol) => searchCol.id === col.id);
      if (!colFound) {
        loadedColumns.push(col);
      } else {
        // Update the saved data
        colFound.name = col.name;
      }
    });

    // Remove any columns that are no longer present
    loadedColumns = loadedColumns.filter((col) => !!passedInColumns.find((searchCol) => searchCol.id === col.id));

    // Save the new set of columns
    this.saveColumnInfo(loadedColumns, saveName);

    return loadedColumns;
  }

  saveColumnInfo(columnInfo: ColumnInfo[], saveName?: string): void {
    // Only save if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return;
      }

      localStorage.setItem(`${saveName}-columns`, JSON.stringify(columnInfo));
    }
  }
}
