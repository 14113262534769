import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, GalaxyI18NModule],
  providers: [SnackbarService],
})
export class GalaxySnackbarModule {}
