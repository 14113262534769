import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'glxy-hint, [glxyHint]',
})
export class HintDirective {
  @HostBinding('class') class = 'glxy-hint';

  @Input() align: 'left' | 'right' = 'left';

  @HostBinding('style.text-align')
  get textAlign(): string {
    return this.align;
  }
}
