<div class="alert">
  <div class="alert-icon" *ngIf="showIcon">
    <mat-icon [svgIcon]="this.svgIcon ?? ''">
      {{ this.currentIcon }}
    </mat-icon>
  </div>

  <div class="text">
    <ng-content />
  </div>

  <ng-container *ngIf="showAction">
    <div [ngClass]="stackedView ? 'hide-object' : 'action'" [class.beside-close-button]="showClose">
      <ng-container *ngIf="actionExternalLink; else buttonNotLink">
        <a
          [href]="actionExternalLink"
          mat-stroked-button
          (click)="this.onActionClicked()"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ this.actionTitle }}
        </a>
      </ng-container>

      <ng-template #buttonNotLink>
        <button [routerLink]="actionRouterLink" mat-stroked-button type="button" (click)="this.onActionClicked()">
          {{ this.actionTitle }}
        </button>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="showClose">
    <div [ngClass]="stackedView ? 'hide-object' : 'close-button'" [class.beside-action-button]="showAction">
      <button mat-icon-button (click)="this.onCloseAlert()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

<div class="alert-extended-wrapper">
  <ng-content select="glxy-alert-extended" />
</div>

<ng-container *ngIf="showAction">
  <div [ngClass]="stackedView ? 'show-object' : 'mobile-action'" class="mobile-button-wrapper">
    <ng-container *ngIf="actionExternalLink; else buttonNotLink">
      <a
        [href]="actionExternalLink"
        mat-stroked-button
        (click)="this.onActionClicked()"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ this.actionTitle }}
      </a>
    </ng-container>

    <ng-template #buttonNotLink>
      <button [routerLink]="actionRouterLink" mat-stroked-button type="button" (click)="this.onActionClicked()">
        {{ this.actionTitle }}
      </button>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="showClose">
  <div [ngClass]="stackedView ? 'show-object' : 'mobile-close-button'" class="mobile-button-wrapper">
    <button mat-stroked-button (click)="this.onCloseAlert()">
      {{ this.closeTitle }}
    </button>
  </div>
</ng-container>
