import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import '@angular/localize/init';
import { TranslateLoader } from '@ngx-translate/core';
import { GetTranslationsResponse, LexiconApiService } from '@vendasta/lexicon';
import { PartnerServiceInterface, PartnerServiceInterfaceToken } from '@galaxy/partner';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import {
  LEXICON_BASE_TRANSLATION,
  LEXICON_COMPONENT_NAME,
  LEXICON_DISABLE_OTW,
  LEXICON_FILE_FORMAT,
  LEXICON_FILE_FORMAT_OPTION,
  translations,
} from './constants';
import { convertToFlatJson, convertToNestedJson } from './converters';
import { deepMerge } from './merge';

declare const $localize: { TRANSLATIONS: { [key: string]: { text: string } } };
declare global {
  interface Window {
    partnerId: string;
  }
}

@Injectable()
export class LexiconLoader implements TranslateLoader {
  private _translations: { [lang: string]: Observable<translations> } = {};
  private partnerId$: Observable<string>;

  constructor(
    @Inject(LEXICON_COMPONENT_NAME) private componentNames: string[],
    @Inject(LEXICON_BASE_TRANSLATION) private baseTranslations: translations[],
    @Inject(LEXICON_DISABLE_OTW) private disableOtw: boolean,
    @Inject(LEXICON_FILE_FORMAT) private targetFileFormat: LEXICON_FILE_FORMAT_OPTION,
    @Optional() @Inject(PartnerServiceInterfaceToken) private partnerService: PartnerServiceInterface,
    private lexiconApiService: LexiconApiService,
    @Optional() @SkipSelf() private parent?: LexiconLoader,
  ) {
    this.partnerId$ = !this.partnerService
      ? of('')
      : this.partnerService
          .getPartnerId()
          .pipe(
            debounceTime(1000),
            startWith(typeof window !== 'undefined' && window.partnerId ? window.partnerId : ''),
          );
  }

  getTranslation(lang: string): Observable<translations> {
    if (this._translations[lang]) {
      return this._translations[lang];
    }

    let translations$: Observable<translations>;
    if (this.disableOtw) {
      translations$ = of(deepMerge({}, this.baseTranslations));
    } else {
      translations$ = this.partnerId$.pipe(
        switchMap((partnerId: string) => {
          const componentNames = this.removeDuplicateComponentNames();
          if (componentNames.length === 0) {
            return of(new GetTranslationsResponse({ translations: {} }));
          }
          return this.lexiconApiService.getTranslations({
            componentNames: componentNames,
            languageCode: lang,
            partnerId: partnerId,
          });
        }),
        map((response: GetTranslationsResponse) => response.translations || {}),
      );
    }

    if (this.parent) {
      translations$ = combineLatest([this.parent.getTranslation(lang), translations$]).pipe(
        map(([parent, child]) => deepMerge(parent, [child])),
      );
    }

    // Merge translations from $angular/localize store into ngx-translate store
    translations$ = translations$.pipe(
      map((translations) => this.augmentWithAngularLocalizeTranslations(translations)),
      shareReplay(1),
    );

    this._translations[lang] = translations$;
    return translations$;
  }

  private removeDuplicateComponentNames() {
    let components = Array.from(new Set(this.componentNames || []));
    if (this.parent?.componentNames) {
      const p = this.parent?.componentNames;
      components = components.filter((c) => p.indexOf(c) < 0);
    }
    return components.filter((c) => !!c);
  }

  augmentWithAngularLocalizeTranslations(translations: translations): translations {
    if (!$localize || !$localize.TRANSLATIONS) {
      return translations;
    }
    switch (this.targetFileFormat) {
      case LEXICON_FILE_FORMAT_OPTION.XLIFF1:
        // TODO: Support XLIFF1 merging
        return translations;
      case LEXICON_FILE_FORMAT_OPTION.FLAT_JSON: {
        const flatJson = convertToFlatJson($localize.TRANSLATIONS);
        return deepMerge(translations, [flatJson]);
      }
      case LEXICON_FILE_FORMAT_OPTION.NESTED_JSON: {
        const nestedJson = convertToNestedJson($localize.TRANSLATIONS);
        return deepMerge(translations, [nestedJson]);
      }
      default:
        return translations;
    }
  }
}
