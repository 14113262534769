import { enUS, enCA, cs, frCA, nl, fr, de, es } from 'date-fns/locale';
import {
  getBrowserCultureLang,
  getLanguageFromLocalStorage,
} from '@vendasta/galaxy/i18n/src/browser-locale/browser-locale';

export const supportedLocales = [enUS, enCA, cs, frCA, nl, fr, de, es];
/**
 * Based the user's preferred language from the navigator and finds the best locale match.
 * @returns {Locale} Returns a Locale that is the best match, enUs as the fallback.
 */
export function getLocale(): Locale {
  const languageFromLocalStorage = getLanguageFromLocalStorage();
  const locale = languageFromLocalStorage ? languageFromLocalStorage : getBrowserCultureLang();
  if (locale === 'fr-FR' || locale === 'fr') {
    return fr;
  } else if (locale === 'fr-CA') {
    return frCA;
  } else if (locale === 'en-CA') {
    return enCA;
  } else if (locale === 'cs') {
    return cs;
  } else if (locale === 'nl') {
    return nl;
  } else if (locale === 'de') {
    return de;
  } else if (locale === 'es-419') {
    return es;
  }
  return enUS;
}

/**
 * @param locale {string} A locale code for the locale format, typically from navigator.language (BCP-47)
 * @return { string } A locale code that is ISO 639-1 to deal missing matching locale codes between BCP-47 and ISO 639-1
 * (ISO 639-1 w/ optional two character region subtag) ex) en, en-US, en-CA, fr-CA, fr
 */
export const getLocaleCode = (locale?: string): string | undefined => {
  if (!locale) {
    return getLocale().code;
  }
  return locale;
};
