import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAMService, UserIdentifier } from '@vendasta/iamv2';

@Injectable({ providedIn: 'root' })
export class AtlasEmailVerificationService {
  constructor(private readonly iamService: IAMService) {}

  sendEmailVerificationEmail(
    email: string,
    partnerID: string,
    nextURL: string,
    nextURLText: string,
  ): Observable<boolean> {
    return this.iamService
      .sendEmailVerification(
        new UserIdentifier({
          namespacedEmail: {
            email: email,
            namespace: partnerID,
          },
        }),
        '',
        nextURL,
        nextURLText,
      )
      .pipe(map(() => true));
  }
}
