import { translations } from './constants';

export const convertToFlatJson = (localizeTranslations: { [key: string]: { text: string } }): translations => {
  const result: { [key: string]: string } = {};
  Object.entries(localizeTranslations).forEach(([key, value]) => {
    result[key] = value.text;
  });
  return result;
};

export const convertToNestedJson = (localizeTranslations: { [key: string]: { text: string } }): translations => {
  const result: translations = {};

  for (const objectPath in localizeTranslations) {
    const parts = objectPath.split('.');

    let target = result;
    while (parts.length > 1) {
      const part = parts.shift() as string;
      target = target[part] = (target[part] as translations) || {};
    }

    target[parts[0]] = localizeTranslations[objectPath].text;
  }

  return result;
};
