import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export enum LEXICON_FILE_FORMAT_OPTION {
  NESTED_JSON = 'nested_json',
  FLAT_JSON = 'flat_json',
  XLIFF1 = 'xliff1',
}

export const LEXICON_TRANSLATE_PORTAL_URL = new InjectionToken<string>('https://weblate.apigateway.co');
export const LEXICON_COMPONENT_NAME = new InjectionToken<string>('lexiconComponentName');
export const LEXICON_PARTNER_ID = new InjectionToken<Observable<string>>('lexiconPartnerId');
export const LEXICON_DISABLE_OTW = new InjectionToken<boolean>('lexiconDisableOtw');
export const LEXICON_BASE_TRANSLATION = new InjectionToken('lexiconBaseTranslation');
export const LEXICON_ROOT_COMPONENT = new InjectionToken<string>('lexiconRootComponent');
export const LEXICON_FILE_FORMAT = new InjectionToken<LEXICON_FILE_FORMAT_OPTION>('lexiconFileFormat');

export type translations = { [key: string]: string | translations };
