import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaSanitizePipe } from './safe-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [VaSanitizePipe],
  exports: [VaSanitizePipe],
})
export class VaSafeHtmlModule {}
