<!-- Begin Modal Content -->
<h2 mat-dialog-title>
  Manage Columns
  <button mat-icon-button mat-dialog-close class="close-modal" (click)="onClose()" tabindex="-1">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="advanced-column-organizer-content" autoFocus="false">
  <!-- Desktop View -->
  <ng-container *ngIf="!isMobileScreen()">
    <div class="desktop-wrapper">
      <div class="desktop-left-pane">
        <ng-container *ngTemplateOutlet="filtered_checkablelist" />
      </div>

      <div class="desktop-right-pane">
        <ng-container *ngTemplateOutlet="filtered_dragable_list" />
      </div>
    </div>
  </ng-container>

  <!-- Mobile View -->
  <ng-container *ngIf="isMobileScreen()">
    <div class="mobile-wrapper">
      <mat-tab-group
        animationDuration="0ms"
        class="mobile-tabs"
        mat-align-tabs="center"
        [selectedIndex]="activeTab"
        (selectedTabChange)="setActiveTab($event.index.toString())"
      >
        <mat-tab label="Choose Columns">
          <div class="mobile-max-width">
            <ng-container *ngTemplateOutlet="filtered_checkablelist" />
          </div>
        </mat-tab>

        <mat-tab label="Arrange Columns">
          <div class="mobile-max-width">
            <ng-container *ngTemplateOutlet="filtered_dragable_list" />
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close (click)="onClose()">Cancel</button>
  <button mat-flat-button color="primary" mat-dialog-close (click)="save()">Save</button>
</mat-dialog-actions>
<!-- End Modal Content -->

<!-- Available Column List -->
<ng-template #filtered_checkablelist>
  <div class="section-container">
    <div class="section-content">
      <va-search-box class="va-search-box" (update)="updateSearchTerm($event)" />

      <!-- No Results -->
      <ng-container *ngIf="searchedGroups$ | async as searchedGroups">
        <div class="no-results" *ngIf="searchedGroups.length === 0">
          No columns matched '{{ searchTerm$ | async }}'. Please try another term.
        </div>
      </ng-container>

      <!-- List of checkboxes -->
      <ng-container *ngFor="let columnGroup of searchedGroups$ | async">
        <ng-container *ngIf="columnGroup.columns.length !== 0">
          <div class="column-group">
            <h4 class="column-group-name">
              {{ columnGroup.name }}
              <div class="select-all-controls">
                <a (click)="selectAll(columnGroup)">Select All</a>
                |
                <a (click)="deselectAll(columnGroup)">Deselect All</a>
              </div>
            </h4>
            <ul class="column-group-list">
              <ng-container *ngFor="let column of columnGroup.columns">
                <li class="column-group-item">
                  <mat-checkbox
                    color="primary"
                    [checked]="column.isVisible"
                    [disabled]="(column.pinned || 0) > 0"
                    (change)="setColumnVisibility(column.id, $event.checked)"
                  >
                    {{ column.name }}
                  </mat-checkbox>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Draggable List -->
<ng-template #filtered_dragable_list>
  <div class="section-container">
    <div class="section-header">Arrange Columns</div>

    <div class="section-content">
      <!-- Pinned to top -->
      <div *ngFor="let column of pinnedLeftColumns$ | async" class="draggable-row draggable-row--pinned">
        <div class="drag-icon">
          <mat-icon>lock</mat-icon>
        </div>
        <div class="name">{{ column.name }}</div>
      </div>

      <!-- Draggable Items -->
      <div cdkDropList>
        <div
          *ngFor="let columnInfo of selectedColumns$ | async; let i = index"
          cdkDrag
          [cdkDragData]="{ columnIndex: i, columnId: columnInfo.id }"
          (cdkDragDropped)="columnDroppedInList($event)"
          class="draggable-row"
        >
          <div class="drag-icon draggable" cdkDragHandle>
            <mat-icon>drag_indicator</mat-icon>
          </div>

          <div class="name draggable" cdkDragHandle *ngIf="!isMobileScreen()">
            {{ columnInfo.name }}
          </div>
          <div class="name" *ngIf="isMobileScreen()">
            {{ columnInfo.name }}
          </div>
          <div class="remove-icon">
            <button mat-icon-button (click)="setColumnVisibility(columnInfo.id, false)">
              <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
            </button>
          </div>

          <div class="drag-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>

      <!-- Pinned to Bottom -->
      <div *ngFor="let column of pinnedRightColumns$ | async" class="draggable-row draggable-row--pinned">
        <div class="drag-icon">
          <mat-icon>lock</mat-icon>
        </div>
        <div class="name">{{ column.name }}</div>
      </div>
    </div>
  </div>
</ng-template>
