import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AtlasLanguageService, LanguageMapping } from '../../../../../../core/src';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'atlas-language-list-item',
  templateUrl: './language-list-item.component.html',
  styleUrls: ['./language-list-item.component.scss'],
})
export class LanguageListItemComponent implements OnChanges {
  @Input() language: LanguageMapping;
  @Output() languageSelected: EventEmitter<string> = new EventEmitter<string>();

  isCurrentLanguage = this.languageService.language$.pipe(map((language) => language === this.language.code));
  public languageLabels$: Observable<{ current: string; target: string }>;

  constructor(
    private languageService: AtlasLanguageService,
    private translateService: TranslateService,
    private translateLoader: TranslateLoader,
  ) {}

  ngOnChanges(): void {
    this.languageLabels$ = combineLatest([
      this.translateService.stream(`LANGUAGE.${this.language.code.toLowerCase()}` || this.language.label),
      this.translateLoader.getTranslation(this.language.code),
    ]).pipe(
      map(([currentTranslation, targetTranslation]) => {
        const languageLabels = { current: currentTranslation, target: '' };
        ['LANGUAGE', this.language.code.toLowerCase()].forEach((key) => {
          if (key in targetTranslation) {
            targetTranslation = targetTranslation[key];
          }
        });
        if (typeof targetTranslation === 'string') {
          languageLabels.target = targetTranslation;
        }
        return languageLabels;
      }),
    );
  }

  selectLanguage(): void {
    this.languageSelected.emit(this.language.code);
  }
}
