import { InjectionToken, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ItemComponent } from './item/item.component';
import { AtlasLanguageSelectorComponent } from './language-selector/language-selector.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { NavComponent } from './nav/nav.component';
import { TitleComponent } from './title/title.component';
import { Observable } from 'rxjs';

export interface AtlasConfig {
  partnerId: string;
  accountId?: string;
  marketId?: string;
  groupPath?: string;
  serviceProviderId: string;
}

export const ATLAS_CONFIG_TOKEN = new InjectionToken<Observable<AtlasConfig>>('ATLAS_CONFIG');

// ************ Elements-specific ************ //

export function DEFINE_CUSTOM_ELEMENTS(injector: Injector): void {
  try {
    const navbar = createCustomElement(NavComponent, { injector: injector });
    customElements.define('atlas-navbar-element', navbar);

    const item = createCustomElement(ItemComponent, { injector: injector });
    customElements.define('atlas-item-element', item);

    const menu = createCustomElement(MenuComponent, { injector: injector });
    customElements.define('atlas-menu-element', menu);

    const menuItem = createCustomElement(MenuItemComponent, {
      injector: injector,
    });
    customElements.define('atlas-menu-item-element', menuItem);

    const title = createCustomElement(TitleComponent, {
      injector: injector,
    });
    customElements.define('atlas-title-element', title);

    const languageSelector = createCustomElement(AtlasLanguageSelectorComponent, {
      injector: injector,
    });
    customElements.define('atlas-language-selector-element', languageSelector);
  } catch (e) {
    console.log(
      `failed to register web elements, you may need the @webcomponents/custom-elements polyfill: ${e.message}`,
    );
  }
}
