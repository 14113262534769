import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { ColumnInfo, ColumnSorterService } from './column-sorter.service';

export interface ColumnSorterColumn {
  columnId: string;
  columnName: string;
  hidden?: boolean;
}

@Component({
  selector: 'va-mat-table-column-sorter, button[va-mat-table-column-sorter]',
  templateUrl: './column-sorter.component.html',
  styleUrls: ['./column-sorter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSorterService],
})
export class ColumnSorterComponent implements OnInit, OnChanges, AfterViewInit {
  @Output()
  columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() iconName = 'view_column';
  @Input() buttonLabel: string;
  @Input()
  columns: string[];
  @Input()
  columnNames: string[];
  @Input()
  columnConfigs: ColumnSorterColumn[];
  @Input()
  saveName?: string;
  @Input()
  useI18NTranslations?: boolean;

  columnInfo: ColumnInfo[] | undefined;

  private columnsUpdated = false;
  private columnNamesUpdated = false;

  readonly isMobile$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  constructor(
    private elementRef: ElementRef,
    private columnSorterService: ColumnSorterService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.calculateColumnInformation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.columnsUpdated = true;
    }
    if (changes.columnNames) {
      this.columnNamesUpdated = true;
    }
    if ((this.columnsUpdated && this.columnNamesUpdated) || !!changes.columnConfigs) {
      this.calculateColumnInformation();
      this.columnsUpdated = false;
      this.columnNamesUpdated = false;
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'va-mat-button-no-input';
  }

  private calculateColumnInformation(): void {
    // If the column config is passed in, use that else use the passed in columns and names
    if (this.columnConfigs) {
      this.columnInfo = this.columnConfigs.map((colConfig) => {
        return {
          id: colConfig.columnId,
          name: colConfig.columnName,
          hidden: !!colConfig.hidden,
        };
      });
    } else {
      this.columnInfo = this.columns.map((currElement, index) => {
        return {
          id: currElement,
          name: this.columnNames[index],
          hidden: false,
        };
      });
    }
    this.columnInfo = this.columnSorterService.loadSavedColumnInfo(this.columnInfo, this.saveName);
    this.emitColumns(false);
  }

  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.columnInfo!, event.item.data.columnIndex, event.currentIndex);
    this.emitColumns(true);
  }

  toggleSelectedColumn(columnId: string): void {
    const colFound = this.columnInfo!.find((col) => col.id === columnId)!;
    colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean): void {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(this.columnInfo!.filter((colInfo) => !colInfo.hidden).map((colInfo) => colInfo.id));
      if (saveColumns) {
        this.columnSorterService.saveColumnInfo(this.columnInfo!, this.saveName);
      }
    });
  }
}
