import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverComponent } from './popover.component';
export { PopoverComponent } from './popover.component';

import { PopoverActionsDirective } from './directives/popover-actions.directive';
export { PopoverActionsDirective } from './directives/popover-actions.directive';

import { PopoverTitleDirective } from './directives/popover-title.directive';
export { PopoverTitleDirective } from './directives/popover-title.directive';

import { GalaxyPopoverDirective } from './directives/popover.directive';
export { GalaxyPopoverDirective } from './directives/popover.directive';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export const MODULE_IMPORTS = [CommonModule, OverlayModule, MatIconModule, MatButtonModule];
export const MODULE_DECLARATIONS = [
  PopoverComponent,
  PopoverTitleDirective,
  PopoverActionsDirective,
  GalaxyPopoverDirective,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  exports: MODULE_DECLARATIONS,
  imports: [MODULE_IMPORTS],
})
export class GalaxyPopoverModule {}
