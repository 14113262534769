import { ElementRef, Directive, Inject } from '@angular/core';

@Directive({ standalone: true, selector: '[vaSearchInput]' })
export class VaSearchInputDirective {
  constructor(@Inject(ElementRef) private elementRef: ElementRef) {}

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  selector(): void {
    this.elementRef.nativeElement.select();
  }
}
