<div
  class="atlas-navbar__notification atlas-navbar__transition"
  *ngIf="rollback === null"
  [ngClass]="{
    'atlas-navbar__notification--unfollowed': !notification.followed,
    'atlas-navbar__notification--link': !!notification.link
  }"
  (mouseenter)="onHover()"
  (mouseleave)="onLeave()"
>
  <a [href]="notification.link" [target]="target" (click)="clickNotification()">
    <div class="atlas-navbar__notification__notification-container">
      <div class="atlas-navbar__notification__new-indicator-container">
        <div class="atlas-navbar__notification__new-indicator" *ngIf="!notification.viewed"></div>
      </div>
      <div class="atlas-navbar__notification__body">
        <div>
          <span [innerHTML]="body"></span>
        </div>
        <div class="atlas-navbar__notification__footer">
          <div class="atlas-navbar__notification__event-time">{{ relativeTime }}</div>
          <atlas-chip
            *ngIf="getDisplayContext() && showContext"
            [style.background-color]="getColorForValue(getDisplayContext())"
          >
            {{ getDisplayContext() }}
          </atlas-chip>
        </div>
      </div>
    </div>
  </a>
  <div class="atlas-navbar__notification__actions">
    <div class="atlas-navbar__notification__actions-button">
      <button atlasIconButton *ngIf="isHovering || menuOpen">
        <i class="material-icons">more_vert</i>
      </button>
      <atlas-menu
        id="atlas-navbar__notifications__actions"
        [relative]="true"
        [anchorRight]="true"
        [anchorBottom]="last"
      >
        <atlas-menu-item
          *ngIf="notification.followed"
          [label]="'NOTIFICATIONS.ACTIONS.MARK_AS_UNREAD' | translate"
          (click)="markAsUnread()"
        ></atlas-menu-item>
        <atlas-menu-item
          *ngIf="!notification.followed"
          [label]="'NOTIFICATIONS.ACTIONS.MARK_AS_READ' | translate"
          (click)="markAsRead()"
        ></atlas-menu-item>
        <ng-container *ngIf="!!setting">
          <atlas-menu-item
            *ngIf="setting.status === SubscriptionStatus.NOTIFICATION_STATUS_ENABLED"
            [label]="'NOTIFICATIONS.ACTIONS.TURN_OFF_NOTIFICATION' | translate"
            (click)="unsubscribeFrom()"
          ></atlas-menu-item>
          <atlas-menu-item
            *ngIf="setting.status === SubscriptionStatus.NOTIFICATION_STATUS_DISABLED"
            [label]="'NOTIFICATIONS.ACTIONS.TURN_ON_NOTIFICATION' | translate"
            (click)="subscribeTo()"
          ></atlas-menu-item>
        </ng-container>
      </atlas-menu>
    </div>
  </div>
</div>
<div
  class="atlas-navbar__notification__unsubscribe atlas-navbar__notification__notification-container"
  *ngIf="rollback !== null"
>
  <div class="atlas-navbar__notification__undo-body" *ngIf="rollback === 'subscribe'">
    {{ 'NOTIFICATIONS.UNSUBSCRIBE_TEXT' | translate }}
  </div>
  <div class="atlas-navbar__notification__undo-body" *ngIf="rollback === 'unsubscribe'">
    {{ 'NOTIFICATIONS.SUBSCRIBE_TEXT' | translate }}
  </div>
  <div class="atlas-navbar__notification__undo">
    <atlas-button (onClick)="emitUndo()" class="atlas-navbar__notification__undo-button" [raised]="true">
      {{ 'NOTIFICATIONS.ACTIONS.UNDO' | translate }}
    </atlas-button>
  </div>
</div>
