<div
  class="atlas-navbar__transition atlas-navbar__item"
  [ngClass]="[customClass]"
  [class.atlas-navbar__item--interactable]="isInteractable"
>
  <div *ngIf="icon" class="atlas-navbar__item__icon__wrapper">
    <i class="material-icons">{{ icon }}</i>
    <div *ngIf="iconText" class="icon-text">{{ iconText }}</div>
    <atlas-chip *ngIf="badgeContent" class="atlas-navbar__item__badge">
      {{ badgeContent }}
    </atlas-chip>
  </div>
  <ng-content></ng-content>
</div>
