import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalaxyWrapComponent } from './galaxy-wrap.component';

@NgModule({
  declarations: [GalaxyWrapComponent],
  imports: [CommonModule],
  exports: [GalaxyWrapComponent],
})
export class GalaxyWrapModule {}
