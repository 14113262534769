import { Component, HostBinding } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'atlas-panel-content',
  templateUrl: './panel-content.component.html',
  styleUrls: ['./panel-content.component.scss'],
})
export class PanelContentComponent {
  set collapsed(collapsed: boolean) {
    this.collapsed$$.next(collapsed);
  }

  private collapsed$$ = new BehaviorSubject<boolean>(null);

  @HostBinding('class.atlas-panel-content__hidden')
  get isHidden(): boolean {
    return this.collapsed$$.getValue();
  }
}
