/**
 * Will convert number values to pixels, or pass through other css unit values
 */
export function coerceCssValue(value: string | number): string | null {
  if (value == null) {
    return null;
  }
  // parseFloat(value) handles most of the cases we're interested in
  // (it treats null, empty string, and other non-number values as NaN,
  // where Number just uses 0) but it considers the string '123hello'
  // to be a valid number. Therefore we also check if Number(value) is NaN.
  if (!isNaN(parseFloat(value as any)) && !isNaN(Number(value))) {
    return `${value}px`;
  } else {
    return `${value}`;
  }
}
