<div
  class="atlas_navbar--tooltip"
  [ngClass]="tooltipClass"
  [class.atlas_navbar--tooltip-handset]="(_isHandset | async)?.matches"
  [@state]="_visibility"
  (@state.start)="_animationStart()"
  (@state.done)="_animationDone($event)"
>
  {{message}}
</div>
