import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'glxy-button-loading-indicator',
  templateUrl: './button-loading-indicator.component.html',
  styleUrls: ['./button-loading-indicator.component.scss'],
})
export class ButtonLoadingIndicatorComponent {
  @HostBinding('class') class = 'glxy-button-loading-indicator';

  @HostBinding('class.is-loading')
  @Input({ transform: booleanAttribute })
  isLoading = false;
}
