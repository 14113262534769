import {
  ActivityInterface,
  GetSectionsRequestInterface,
  GetSectionsResponseInterface,
  ProductInterface,
  SaveSettingInterface,
  SaveSectionsRequestInterface,
  SectionContainerInterface,
  SectionInterface,
  GetByAccountGroupIdResponseInterface,
  GetByAccountGroupIdSettingInterface,
  GetByAccountGroupIdRequestInterface,
} from '../interfaces/notifications.interface';

export class Section implements SectionInterface {
  id: string;
  localized_title: string;
  title: string;
  value: string[] | boolean;

  constructor(kwargs?: SectionInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): Section {
    let m = new Section();
    m = Object.assign(m, proto);
    return m;
  }
}

export class Activity implements ActivityInterface {
  id: string;
  localized_title: string;
  tags: Section[];
  title: string;
  value: boolean;

  constructor(kwargs?: ActivityInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): Activity {
    let m = new Activity();
    m = Object.assign(m, proto);
    if (proto.tags) {
      m.tags = proto.tags.map(Section.fromProto);
    }
    return m;
  }
}

export class Product implements ProductInterface {
  activities: Activity[];
  productId: string;
  title: string;

  constructor(kwargs?: ProductInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): Product {
    let m = new Product();
    m = Object.assign(m, proto);
    if (proto.activities) {
      m.activities = proto.activities.map(Activity.fromProto);
    }
    return m;
  }
}

export class SectionContainer implements SectionContainerInterface {
  products: Product[];
  section: Section;

  constructor(kwargs?: SectionContainerInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): SectionContainer {
    let m = new SectionContainer();
    m = Object.assign(m, proto);
    if (proto.products) {
      m.products = proto.products.map(Product.fromProto);
    }
    if (proto.section) {
      m.section = Section.fromProto(proto.section);
    }
    return m;
  }
}

export class GetSectionsResponse implements GetSectionsResponseInterface {
  data: SectionContainer[];

  constructor(kwargs?: GetSectionsResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): GetSectionsResponse {
    let m = new GetSectionsResponse();
    m = Object.assign(m, proto);
    if (proto.data) {
      m.data = proto.data.map(SectionContainer.fromProto);
    }
    return m;
  }
}

export class GetSectionsRequest implements GetSectionsRequestInterface {
  accountGroupId: string;
  email: string;
  partnerId: string;
  marketId: string;
  locale: string;

  constructor(kwargs?: GetSectionsRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.accountGroupId === 'undefined' &&
      typeof this.email === 'undefined' &&
      typeof this.partnerId === 'undefined' &&
      typeof this.marketId === 'undefined' &&
      typeof this.locale === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.accountGroupId !== 'undefined') {
      toReturn.accountGroupId = this.accountGroupId;
    }
    if (typeof this.email !== 'undefined') {
      toReturn.email = this.email;
    }
    if (typeof this.partnerId !== 'undefined') {
      toReturn.partnerId = this.partnerId;
    }
    if (typeof this.marketId !== 'undefined') {
      toReturn.marketId = this.marketId;
    }
    if (typeof this.locale !== 'undefined') {
      toReturn.locale = this.locale;
    }
    return toReturn;
  }
}

export class SaveSetting implements SaveSettingInterface {
  section: string;
  setting: string;
  valueFlag: boolean;

  constructor(kwargs?: SaveSettingInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.section === 'undefined' &&
      typeof this.setting === 'undefined' &&
      typeof this.valueFlag === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.section !== 'undefined') {
      toReturn.section = this.section;
    }
    if (typeof this.setting !== 'undefined') {
      toReturn.setting = this.setting;
    }
    if (typeof this.valueFlag !== 'undefined') {
      toReturn.valueFlag = this.valueFlag;
    }
    return toReturn;
  }
}

export class SaveSectionsRequest implements SaveSectionsRequestInterface {
  accountGroupId: string;
  partnerId: string;
  section: string[];
  setting: string[];
  valueFlag: boolean[];

  constructor(kwargs?: SaveSectionsRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.accountGroupId === 'undefined' &&
      typeof this.partnerId === 'undefined' &&
      typeof this.section === 'undefined' &&
      typeof this.setting === 'undefined' &&
      typeof this.valueFlag === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.accountGroupId !== 'undefined') {
      toReturn.accountGroupId = this.accountGroupId;
    }
    if (typeof this.partnerId !== 'undefined') {
      toReturn.partnerId = this.partnerId;
    }
    if (typeof this.section !== 'undefined') {
      toReturn.section = this.section;
    }
    if (typeof this.setting !== 'undefined') {
      toReturn.setting = this.setting;
    }
    if (typeof this.valueFlag !== 'undefined') {
      toReturn.valueFlag = this.valueFlag;
    }
    return toReturn;
  }
}

export class GetByAccountGroupIdRequest implements GetByAccountGroupIdRequestInterface {
  accountGroupId: string;
  partnerId: string;

  constructor(kwargs?: GetByAccountGroupIdRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.accountGroupId === 'undefined' && typeof this.partnerId === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.accountGroupId !== 'undefined') {
      toReturn.accountGroupId = this.accountGroupId;
    }
    if (typeof this.partnerId !== 'undefined') {
      toReturn.partnerId = this.partnerId;
    }
    return toReturn;
  }
}

export class GetByAccountGroupIdSetting implements GetByAccountGroupIdSettingInterface {
  subjectId: string;
  unifiedUserId: string;
  notificationsEnabled: boolean;

  constructor(kwargs?: GetByAccountGroupIdSettingInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): GetByAccountGroupIdSetting {
    const m = new GetByAccountGroupIdSetting();
    if (proto.subject_id) {
      m.subjectId = proto.subject_id;
    }
    if (proto.unified_user_id) {
      m.unifiedUserId = proto.unified_user_id;
    }
    m.notificationsEnabled = proto.notifications_enabled || false;
    return m;
  }
}

export class GetByAccountGroupIdResponse implements GetByAccountGroupIdResponseInterface {
  data: GetByAccountGroupIdSetting[];

  constructor(kwargs?: GetByAccountGroupIdResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  static fromProto(proto: any): GetByAccountGroupIdResponse {
    let m = new GetByAccountGroupIdResponse();
    m = Object.assign(m, proto);
    if (proto.data) {
      m.data = proto.data.map(GetByAccountGroupIdSetting.fromProto);
    }
    return m;
  }
}
