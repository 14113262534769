<img class="logo" src="{{ logoURL$ | async }}" />

<div class="container">
  <div class="content-box">
    <div class="content">
      <div class="content-data">
        <div class="content-title">
          {{ 'EMAIL_VERIFICATION.TITLE' | translate }}
        </div>
        <div class="content-subtitle">
          <p
            [innerHTML]="
              'EMAIL_VERIFICATION.TO_IMPROVE_SECURITY_WITH_DEADLINE'
                | translate : { verificationDeadline: verificationDeadline | glxyDate : DateFormat.medium }
            "
          ></p>
          <p>
            {{ 'EMAIL_VERIFICATION.CLICK_BELOW' | translate }}:
            <b>{{ email$ | async }}</b>
          </p>
        </div>
        <div class="actions">
          <button
            mat-flat-button
            data-action="send-verification-email"
            class="cta"
            color="primary"
            type="button"
            (click)="sendVerificationEmail()"
            [disabled]="sendingVerificationEmail$ | async"
            data-testid="send-verification-email"
          >
            <glxy-button-loading-indicator [isLoading]="sendingVerificationEmail$ | async">
              {{ 'EMAIL_VERIFICATION.SEND_VERIFICATION_EMAIL' | translate }}
            </glxy-button-loading-indicator>
          </button>
          <ng-template #buttonWithSpinner>
            <button mat-flat-button color="primary" class="cta">
              <span>
                <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
              </span>
            </button>
          </ng-template>
          <button
            mat-stroked-button
            data-action="remind-me-later"
            class="cta"
            type="button"
            (click)="remindMeLater()"
            data-testid="remind-me-later"
          >
            {{ 'EMAIL_VERIFICATION.REMIND_ME_LATER' | translate }}
          </button>
          <ng-template #buttonWithSpinner>
            <button mat-flat-button color="primary" class="cta">
              <span>
                <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
              </span>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="support">
    {{ 'EMAIL_VERIFICATION.CONTACT_SUPPORT' | translate }}
  </div>
</div>
<div class="banner">
  <p class="quotation">
    {{ 'EMAIL_VERIFICATION.CHECK_YOUR_INBOX' | translate }}
  </p>
  <p class="image-container">
    <img
      class="image"
      src="https://storage.googleapis.com/galaxy-libs-public-images/example-verification-email.png"
      alt="Example verification image"
    />
  </p>
</div>
