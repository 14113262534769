import { Injectable } from '@angular/core';

export enum Environment {
  LOCAL = 0,
  TEST = 1,
  DEMO = 2,
  PROD = 3,
}

const environment: string = (globalThis as { environment?: string }).environment || 'prod';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private environment!: Environment;

  constructor() {
    this.setEnvironment(environment);
  }

  private setEnvironment(env: string): void {
    switch (env) {
      case 'local':
        this.environment = Environment.LOCAL;
        break;
      case 'test':
        this.environment = Environment.TEST;
        break;
      case 'demo':
        this.environment = Environment.DEMO;
        break;
      case 'prod':
        this.environment = Environment.PROD;
        break;
    }
  }

  getEnvironment(): Environment {
    return this.environment;
  }

  getEnvironmentString(): string {
    switch (this.environment) {
      case Environment.LOCAL:
        return 'local';
      case Environment.TEST:
        return 'test';
      case Environment.DEMO:
        return 'demo';
      case Environment.PROD:
      default:
        return 'prod';
    }
  }
}
