/**
 * If a component is being used in an iframe with a different domain
 * localstorage access will fail on Chrome
 * https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityer\
 * ror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-thi\
 * s-document
 *
 * this solution is cited here:
 * https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 */
export function isLocalStorageAvailable(): boolean {
  try {
    const key = '__some_random_key_just_to_test_localstorage__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}
