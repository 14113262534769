<atlas-modal>
  <atlas-modal-header>
    {{ 'SWITCH_LANGUAGE_MODAL.SWITCH_LANGUAGE' | translate }}
  </atlas-modal-header>
  <atlas-modal-content>
    <div class="atlas-navbar__language-selector-modal__impersonation-warning" *ngIf="impersonating$ | async">
      <glxy-alert [type]="'warning'" [size]="'small'">
        {{ 'SWITCH_LANGUAGE_MODAL.IMPERSONATION_WARNING' | translate }}
      </glxy-alert>
    </div>
    <div class="atlas-navbar__language-selector-modal__container">
      <div class="atlas-navbar__language-selector-modal__list">
        <atlas-language-list
          [languages]="languages$ | async"
          (languageSelected)="languageSelected($event)"
        ></atlas-language-list>
      </div>
    </div>
  </atlas-modal-content>
  <atlas-modal-footer
    class="atlas-navbar__language-selector-modal__translation-prompt"
    *ngIf="translatePortalUrl$ | async as translatePortalUrl"
  >
    <span class="atlas-navbar__language-selector-modal__translation-prompt__label">
      {{ 'SWITCH_LANGUAGE_MODAL.HELP_US_TRANSLATE' | translate }}
    </span>
    <a
      *ngIf="!urlIsExternal(translatePortalUrl)"
      class="atlas-navbar__language-selector-modal__translation-prompt__link"
      [routerLink]="translatePortalUrl"
    >
      {{ 'SWITCH_LANGUAGE_MODAL.VISIT_TRANSLATION_PORTAL' | translate }}
    </a>
    <a
      *ngIf="urlIsExternal(translatePortalUrl)"
      class="atlas-navbar__language-selector-modal__translation-prompt__link"
      target="_blank"
      [href]="translatePortalUrl"
    >
      {{ 'SWITCH_LANGUAGE_MODAL.VISIT_TRANSLATION_PORTAL' | translate }}
      <i class="atlas-navbar__language-selector-modal__translation-prompt__icon material-icons">open_in_new</i>
    </a>
  </atlas-modal-footer>
</atlas-modal>
