import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsApiService } from './_internal/notifications.api.service';
import { GetByAccountGroupIdResponse, GetSectionsResponse, SaveSetting } from './_internal/objects/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private apiService: NotificationsApiService) {}

  sections$(accountGroupId: string, email: string, partnerId: string, locale: string): Observable<GetSectionsResponse> {
    return this.apiService.getSections({
      accountGroupId: accountGroupId,
      email: email,
      partnerId: partnerId,
      locale: locale,
    });
  }

  saveSections$(accountGroupId: string, partnerId: string, s: SaveSetting[]): Observable<boolean> {
    const sections: string[] = new Array<string>(s.length);
    const settings: string[] = new Array<string>(s.length);
    const valueFlags: boolean[] = new Array<boolean>(s.length);

    for (let i = 0; i < s.length; i++) {
      const setting = s[i];
      sections[i] = setting.section;
      settings[i] = setting.setting;
      valueFlags[i] = setting.valueFlag;
    }

    return this.apiService
      .saveSections({
        accountGroupId: accountGroupId,
        partnerId: partnerId,
        section: sections,
        setting: settings,
        valueFlag: valueFlags,
      })
      .pipe(map(() => true));
  }

  getByAccountGroupId$(partnerId: string, accountGroupId: string): Observable<GetByAccountGroupIdResponse> {
    return this.apiService.getSettingsByAccountGroupId({
      partnerId,
      accountGroupId,
    });
  }
}
