import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss', '../common.scss'],
})
export class ItemComponent {
  isInteractable: boolean;
  @Input() icon: string;
  @Input() set interactable(val: string) {
    this.isInteractable = val !== 'false';
  }
  @Input() badgeContent: string | number | null;
  @Input() iconText: string;
  @Input() customClass = '';
}
