import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { VaNavComponent } from './nav.component';

@NgModule({
  imports: [CommonModule, MatSidenavModule, MatButtonModule, MatToolbarModule, MatIconModule],
  declarations: [VaNavComponent],
  exports: [VaNavComponent],
})
export class VaNavModule {}
