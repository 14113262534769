@if (showSelector) {
  @if (showNewMenu()) {
    <button mat-icon-button [matMenuTriggerFor]="appsMenu">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #appsMenu>
      @for (navItem of centerData; track $index) {
        @if (navItem.centerId !== serviceProviderId) {
          <a mat-menu-item [href]="navItem.entryUrl">
            {{ navItem.name }}
          </a>
        }
      }
    </mat-menu>
  } @else {
    <atlas-item icon="apps" interactable>
      <atlas-menu id="atlas-navbar__center-selector">
        <div>
          <ng-container *ngFor="let navItem of centerData">
            <atlas-menu-item
              *ngIf="navItem.centerId !== serviceProviderId"
              [label]="navItem.name"
              [url]="navItem.entryUrl"
            ></atlas-menu-item>
          </ng-container>
        </div>
      </atlas-menu>
    </atlas-item>
  }
}
