import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { VaCardComponent } from './va-card/va-card.component';
import { VaCardHeaderComponent } from './va-card-header/va-card-header.component';
import { VaCardLinkButtonComponent } from './va-card-link-button/va-card-link-button.component';
import { VaCardBreakLineComponent } from './va-card-break-line/va-card-break-line.component';
import { VaCardContentComponent } from './va-card-content/va-card-content.component';
import { VaCardBreakGroupComponent } from './va-card-break-group/va-card-break-group.component';
import { VerticalLayoutDirective, HorizontalLayoutDirective } from './va-layout.directive';
import { NumberWithChangeComponent } from './va-card-content/options/number-with-change/number-with-change.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  declarations: [
    VaCardComponent,
    VaCardHeaderComponent,
    VaCardLinkButtonComponent,
    VaCardBreakLineComponent,
    VaCardContentComponent,
    VaCardBreakGroupComponent,
    VerticalLayoutDirective,
    HorizontalLayoutDirective,
    NumberWithChangeComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    MatTooltipModule,
    CommonPipesModule,
  ],
  exports: [
    VaCardComponent,
    VaCardHeaderComponent,
    VaCardLinkButtonComponent,
    VaCardBreakLineComponent,
    VaCardContentComponent,
    VaCardBreakGroupComponent,
    VerticalLayoutDirective,
    HorizontalLayoutDirective,
    NumberWithChangeComponent,
  ],
})
export class VaCardsModule {}
