import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class URLParamService {
  appliedParams: Params = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.applyParams(this.appliedParams);
    });
  }

  applyParams(newParams: Params): void {
    const params = Object.assign({}, this.route.snapshot.queryParams, this.appliedParams, newParams);
    this.appliedParams = Object.assign(this.appliedParams, newParams);
    this.location.replaceState(window.location.pathname, this.serialize(params));
  }

  removeParams(oldParams: string[]): void {
    const currentParams = Object.assign({}, this.route.snapshot.queryParams, this.appliedParams);
    oldParams.forEach((param) => {
      if (this.appliedParams[param]) {
        delete this.appliedParams[param];
      }
      if (currentParams[param]) {
        delete currentParams[param];
      }
    });
    this.location.replaceState(window.location.pathname, this.serialize(currentParams));
  }

  serialize(obj: object) {
    const params = Object.keys(obj);
    if (params.length === 0) {
      return '';
    }
    return (
      '?' +
      params
        .reduce(function (a: string[], k: string) {
          a.push(k + '=' + encodeURIComponent(obj[k]));
          return a;
        }, [])
        .join('&')
    );
  }
  /* tslint:enable */
}
