import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export async function pushNotificationsEnabled(): Promise<boolean> {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }
  const result = await PushNotifications.checkPermissions();
  return result.receive === 'granted';
}

export async function registerForPushNotifications(): Promise<void> {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  const result = await PushNotifications.requestPermissions();
  if (result.receive === 'granted') {
    await PushNotifications.register();
  }
}

export async function openAppNotificationSettings(): Promise<void> {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  await NativeSettings.open({
    optionAndroid: AndroidSettings.AppNotification,
    optionIOS: IOSSettings.App,
  });
}
