import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';

import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from './assets/en_devel.json';

@NgModule({
  imports: [
    TranslateModule,
    LexiconModule.forChild({
      componentName: 'common/galaxy',
      baseTranslation: baseTranslation,
    }),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class GalaxyI18NModule {}
