import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VaSwitchComponent } from './va-switch.component';

@NgModule({
  imports: [CommonModule, MatSlideToggleModule],
  declarations: [VaSwitchComponent],
  exports: [VaSwitchComponent],
})
export class VaSwitchModule {}
