import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { VaCheckboxComponent } from './va-checkbox.component';

@NgModule({
  imports: [CommonModule, MatCheckboxModule],
  declarations: [VaCheckboxComponent],
  exports: [VaCheckboxComponent],
})
export class VaCheckboxModule {}
