import { DOCUMENT } from '@angular/common';
import { effect, Inject, Injectable, OnDestroy, Renderer2, RendererFactory2, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// the `deployment` and `environment` global vars is created on the app's index.html
declare const deployment: string;
declare const environment: string;

/** Set up theme stylesheets in project.json then use loadStyle to set the desired base theme. https://galaxy.vendasta.com/shared-styles/theming */

export enum GalaxyTheme {
  Light = 'glxy-light-theme',
  Dark = 'glxy-dark-theme',
}

@Injectable({ providedIn: 'root' })
export class StyleService implements OnDestroy {
  private renderer: Renderer2;
  private theme: GalaxyTheme = this.getTheme();
  private theme$$ = new BehaviorSubject<GalaxyTheme>(this.theme);
  activeTheme$ = this.theme$$.asObservable();

  private readonly useClientColorScheme = signal(false);
  private readonly client = signal('');
  private readonly colorSchemeListener = window.matchMedia('(prefers-color-scheme: dark)');
  private colorSchemeListenerAbort: AbortController | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    effect(() => {
      const useClientColorScheme = this.useClientColorScheme();
      const client = this.client();
      if (useClientColorScheme) {
        this.update(client, this.getTheme());
        this.colorSchemeListenerAbort = new AbortController();
        this.colorSchemeListener.addEventListener(
          'change',
          (event) => {
            this.update(client, event.matches ? GalaxyTheme.Dark : GalaxyTheme.Light);
          },
          { signal: this.colorSchemeListenerAbort.signal },
        );
      } else {
        this.removeEventListener();
      }
    });
  }

  removeEventListener() {
    if (this.colorSchemeListenerAbort) {
      this.colorSchemeListenerAbort.abort();
    }
  }

  ngOnDestroy(): void {
    this.removeEventListener();
  }

  private detectPrefersColorScheme(): GalaxyTheme {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise set to light.
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? GalaxyTheme.Dark : GalaxyTheme.Light;
    } else {
      // If the browser doesn't support prefers-color-scheme, set to light by default
      return GalaxyTheme.Light;
    }
  }

  private setTheme(theme: GalaxyTheme): void {
    this.theme = theme;
    this.theme$$.next(theme);
  }

  private getTheme(): GalaxyTheme {
    const savedTheme = localStorage.getItem('prefers-theme');
    // Check if a theme preference is stored in localStorage
    if (savedTheme) {
      // Use saved theme preference
      return savedTheme as GalaxyTheme;
    } else {
      // If no prefers-theme is stored in localStorage, try to detect OS default prefers-color-scheme
      return this.detectPrefersColorScheme();
    }
  }

  private getStyleUrl(client: string, theme: GalaxyTheme): string {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      // for local development work, we want to use the local theme file
      return `${theme}.css`;
    }
    // when deployed through vstore, the file urls are stored like so
    // https://cdn.apigateway.co/partner-center-client.9223372035177521852.prod/runtime.a1d3846e934497bb.js
    return `https://cdn.apigateway.co/${client}.${deployment}.${environment}/${theme}.css`;
  }

  private loadStyle(styleUrl: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const refNode = this.document.getElementsByTagName('link')[0];
    const themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleUrl;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = styleUrl;

      // theme styles must be before other stylesheets
      head.insertBefore(style, refNode);
    }
  }

  /**
   * Sets a theme class matching the theme stylesheet name on the body element.
   * Theme class should not be used as a style hook in production because it could break the systematic application of new themes. */
  private setStyleClass(): void {
    this.renderer.addClass(document.body, this.theme);
  }

  /** Returns the currently set theme */
  activeTheme(): GalaxyTheme {
    return this.theme;
  }

  /**
   * Detects the OS default prefers-color-scheme and sets the theme accordingly
   * @param client The vStatic appId
   * @param useClientColorScheme Whether to use the client's color scheme or the OS default
   */
  public useBrowserTheme(client: string, useClientColorScheme: boolean): void {
    this.setClient(client);
    this.useClientColorScheme.set(useClientColorScheme);
  }

  /**
   * Sets the theme in localstorage and switches out theme styles
   * @param client The vStatic appId
   * @param theme Theme name corresponding to the stylesheet
   */
  update(client: string, theme: GalaxyTheme): void {
    this.setClient(client);
    this.setTheme(theme);
    this.loadStyle(this.getStyleUrl(client, theme));
    this.setStyleClass();
  }

  private setClient(client: string): void {
    if (this.client() === '') {
      this.client.set(client);
    }
  }

  /**
   * Shortcut to switch between the Galaxy light and dark themes
   * @param client The vStatic appId
   */
  toggleLightDark(client: string): void {
    const toggleValue = this.theme === GalaxyTheme.Dark ? GalaxyTheme.Light : GalaxyTheme.Dark;
    this.renderer.removeClass(document.body, this.theme);
    // Save theme preference to localStorage
    localStorage.setItem('prefers-theme', toggleValue);
    this.update(client, toggleValue);
  }
}
