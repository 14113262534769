import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { VaChipComponent } from './va-chip.component';

@NgModule({
  imports: [CommonModule, MatChipsModule],
  declarations: [VaChipComponent],
  exports: [VaChipComponent],
})
export class VaChipModule {}
