export interface DateRange {
  start?: Date;
  end?: Date;
}

export enum DateDuration {
  days = 'days',
  months = 'months',
}

export enum DateFormat { // Wed Apr 01 2020 16:20:00 GMT-600
  mediumDay = 'LLL d', // MMM d (Apr 1)
  shortDate = 'L/d/yy', // M/d/yy (4/1/20)
  mediumDate = 'LLL d, y', // 'MMM d, y' (Apr 1, 2020)
  longDate = 'LLLL d, y', // 'MMMM d, y' (April 1, 2020)
  longMonth = 'LLLL y', // 'MMMM y' (April 2020)
  shortTime = 'h:mm a', // (4:20 PM)
  short = 'M/d/yy, h:mm a', // (4/1/20, 4:20 PM)
  medium = 'LLL d, y, h:mm a', // 'MMM d, y, h:mm:ss a' (removed the seconds) (Apr 1, 2020, 4:20 PM)
  long = 'LLLL d, y, h:mm a', // 'MMMM d, y, h:mm:ss a z' (removed the seconds and timezone) (April 1, 2020, 4:20 PM)
  mediumDateShortTime = 'LLL d, h:mm a', // 'MMM d, h:mm:ss a' (removed the seconds) (Apr 1, 4:20 PM)
  longLocalDate = 'PPP', // April 29, 1453 (changes order of day and month based on locale)
  mediumLocalDate = 'PP', // Apr 29, 1453 (changes order of day and month based on locale)
}
