import { SearchSelectComponent } from './search-select.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { VaSearchBoxModule } from '../search-box/va-search-box.module';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  imports: [
    CommonModule,
    VaSearchBoxModule,
    MatIconModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
  ],
  declarations: [SearchSelectComponent],
  exports: [SearchSelectComponent],
})
export class VaSearchSelectModule {}
