<ng-container *ngIf="formReady$ | async as fr; else loading">
  <div class="empty" *ngIf="fr && sections?.size === 0 && !error && (showActivitySettings$ | async) === false">
    {{ 'NOTIFICATIONS.SETTINGS.NO_ACCESS' | translate }}
  </div>

  <ng-container *ngIf="showActivitySettings$ | async">
    <atlas-panel *ngIf="activitySettingsURL$ | async as url">
      <atlas-panel-content class="atlas-navbar__beta-notifications-container">
        <div class="atlas-navbar__beta-notifications-container__description">
          <div>
            <glxy-badge>{{ 'NOTIFICATIONS.SETTINGS.BETA' | translate }}</glxy-badge>
            <span
              class="atlas-navbar__beta-notification-container_description-text"
              [innerHtml]="'NOTIFICATIONS.SETTINGS.ACTIVITY_SETTINGS_DESCRIPTION' | translate: { url: url }"
            ></span>
          </div>
        </div>
      </atlas-panel-content>
    </atlas-panel>
  </ng-container>

  <ng-container *ngFor="let typesByCategory of sections | keyvalue">
    <atlas-panel>
      <atlas-panel-header>
        <atlas-panel-title>
          <button
            atlasIconButton
            (click)="clickCategoryIcon(typesByCategory.key, $event)"
            class="atlas-navbar__notifications-form__category-icon"
          >
            <i class="material-icons">
              {{ getCategoryIcon(typesByCategory.key) }}
            </i>
          </button>
          {{ typesByCategory.key }}
        </atlas-panel-title>
        <atlas-panel-description>
          <span>
            {{ getCategoryEnabledState(typesByCategory.key) | translate }}
          </span>
          <div class="atlas-navbar__notifications-description__settings">
            <div class="atlas-navbar__notifications-description__settings__container">
              <span class="atlas-navbar__notifications-description__settings__container-items">
                {{ 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ALL_EVENTS' | translate }}
              </span>
              <mat-icon
                class="atlas-navbar__notifications-description__settings__container-items"
                [atlasTooltip]="'NOTIFICATIONS.SETTINGS.DESCRIPTION.ALL_EVENTS_TOOLTIP' | translate"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="atlas-navbar__notifications-description__settings__container">
              <span class="atlas-navbar__notifications-description__settings__container-items">
                {{ 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ONLY_MY_EVENTS' | translate }}
              </span>
              <mat-icon
                class="atlas-navbar__notifications-description__settings__container-items"
                [atlasTooltip]="'NOTIFICATIONS.SETTINGS.DESCRIPTION.ONLY_MY_EVENTS_TOOLTIP' | translate"
              >
                info_outline
              </mat-icon>
            </div>
          </div>
        </atlas-panel-description>
      </atlas-panel-header>
      <atlas-panel-content>
        <section class="atlas-navbar__notifications-form__checkbox-section" *ngFor="let t of typesByCategory.value">
          <div class="atlas-navbar__notifications-form__form-field atlas-navbar__notifications-form__panel-content">
            <div>
              <div>{{ t.name }}</div>
              <div class="atlas-navbar__notifications-form__description">
                {{ t.description }}
              </div>
            </div>
            <div class="atlas-navbar__notifications-form__options">
              <div class="atlas-navbar__notifications-form__option-container">
                <span class="atlas-navbar__notifications-form__option-container__title">
                  {{ 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ALL_EVENTS' | translate }}
                </span>
                <span class="atlas-navbar__notifications-form__option-container__options">
                  <atlas-checkbox
                    *ngFor="let medium of availableMedium | keyvalue"
                    class="atlas-navbar__notifications-form__option"
                    [ngModel]="
                      checked.get(checkboxId('partner', t.notificationTypeId, medium.value)) &&
                      isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)
                    "
                    (ngModelChange)="checked.set(checkboxId('partner', t.notificationTypeId, medium.value), $event)"
                    [disabled]="!isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)"
                    [atlasTooltip]="
                      (getTranslationKeyForMedium(medium.value) | translate) +
                      ' ' +
                      ('NOTIFICATIONS.SETTINGS.UNAVAILABLE' | translate)
                    "
                    [atlasTooltipDisabled]="isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)"
                  >
                    {{ getTranslationKeyForMedium(medium.value) | translate }}
                  </atlas-checkbox>
                </span>
              </div>
              <div class="atlas-navbar__notifications-form__settings-separator"></div>
              <div class="atlas-navbar__notifications-form__option-container">
                <span class="atlas-navbar__notifications-form__option-container__title">
                  {{ 'NOTIFICATIONS.SETTINGS.DESCRIPTION.ONLY_MY_EVENTS' | translate }}
                </span>
                <span class="atlas-navbar__notifications-form__option-container__options">
                  <atlas-checkbox
                    *ngFor="let medium of availableMedium | keyvalue"
                    class="atlas-navbar__notifications-form__option"
                    [ngModel]="
                      checked.get(checkboxId('user', t.notificationTypeId, medium.value)) &&
                      isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)
                    "
                    (ngModelChange)="checked.set(checkboxId('user', t.notificationTypeId, medium.value), $event)"
                    [disabled]="!isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)"
                    [atlasTooltip]="
                      (getTranslationKeyForMedium(medium.value) | translate) +
                      ' ' +
                      ('NOTIFICATIONS.SETTINGS.UNAVAILABLE' | translate)
                    "
                    [atlasTooltipDisabled]="isMediumEnabled(typesByCategory.key, t.notificationTypeId, medium.value)"
                  >
                    {{ getTranslationKeyForMedium(medium.value) | translate }}
                  </atlas-checkbox>
                </span>
              </div>
            </div>
          </div>
        </section>
      </atlas-panel-content>
    </atlas-panel>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="atlas-navbar__loading" *ngIf="!error">
    <atlas-loader [size]="24"></atlas-loader>
  </div>
  <div class="atlas-navbar__empty" *ngIf="error">
    <div class="atlas-navbar__empty__icon">
      <i class="material-icons">error</i>
    </div>
    {{ 'NOTIFICATIONS.SETTINGS.LOAD_FAILURE' | translate }}
  </div>
</ng-template>
