import { ConnectedPosition } from '@angular/cdk/overlay';

export const PopoverPositions: Record<string, ConnectedPosition> = {
  Top: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: ['top'],
  },
  Bottom: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: ['bottom'],
  },
  Left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: ['left'],
  },
  Right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: ['right'],
  },
  TopRight: {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: ['top', 'corner-left'],
  },
  TopLeft: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: ['top', 'corner-right'],
  },
  BottomRight: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: ['bottom', 'corner-left'],
  },
  BottomLeft: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: ['bottom', 'corner-right'],
  },
};

export const DEFAULT_POSITIONS: ConnectedPosition[] = [
  {
    ...PopoverPositions.Top,
  },
  {
    ...PopoverPositions.Bottom,
  },
  {
    ...PopoverPositions.Left,
  },
  {
    ...PopoverPositions.Right,
  },
  {
    ...PopoverPositions.TopRight,
  },
  {
    ...PopoverPositions.TopLeft,
  },
  {
    ...PopoverPositions.BottomRight,
  },
  {
    ...PopoverPositions.BottomLeft,
  },
];
