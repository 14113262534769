import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subscription } from 'rxjs';

import { coerceCssValue } from '@vendasta/galaxy/utility/coerce-css-value';
import { ComponentWithSizesComponent } from '@vendasta/galaxy/utility/component-with-sizes';

import { ErrorDirective } from './directives/error';

let nextUniqueId = 0;

/**
 * Injection token that can be used to inject an instances of `FormFieldComponent`. It serves
 * as alternative token to the actual `FormFieldComponent` class which would cause unnecessary
 * retention of the `FormFieldComponent` class and its component metadata.
 */
export const GLXY_FORM_FIELD = new InjectionToken<FormFieldComponent>('FormFieldComponent');

// Input naming and autofill
// https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
// https://developers.google.com/web/fundamentals/design-and-ux/input/forms

// controlling autofill styles
// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
// https://twitter.com/colmtuite/status/1402913361977921537

// aria labels
// https://www.w3.org/WAI/tutorials/forms/labels/

@Component({
  selector: 'glxy-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: [
    './form-field.component.scss',
    './input-styles/text-input.scss',
    './input-styles/mat-checkbox.scss',
    './input-styles/mat-chip-grid.scss',
    './input-styles/mat-date-range.scss',
    './input-styles/mat-radio-group.scss',
    './input-styles/mat-select.scss',
    './input-styles/mat-slider.scss',
    './input-styles/select.scss',
    './input-styles/textarea.scss',
    './input-styles/div-contenteditable.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: GLXY_FORM_FIELD, useExisting: FormFieldComponent }],
})
export class FormFieldComponent extends ComponentWithSizesComponent implements OnInit, AfterContentInit, OnDestroy {
  @HostBinding('class') class = 'glxy-form-field';

  // ComponentWithSizes adds a `size` input that controls
  // the size of the component.
  //
  // @Input() size: 'large' | 'default' | 'small' = 'default'

  /**
   * Optional. Adjust the bottom margin of the component.
   * `default` = 24px bottom margin
   * `small` = 16px bottom margin
   * `none`|false = 0 bottom margin
   */
  @Input() bottomSpacing?: 'default' | 'small' | 'none' | false = 'default';

  /**
   * Optional. Text to show before the input in the input wrapper
   */
  @Input() prefixText?: string;

  /**
   * Optional. Text to show after the input in the input wrapper
   */
  @Input() suffixText?: string;

  /**
   * Optional. Icon to show before the the input in the input wrapper
   */
  @Input() prefixIcon?: string;

  /**
   * Optional. Icon to show after the the input in the input wrapper
   */
  @Input() suffixIcon?: string;

  /**
   * Visually hide or show the label while keeping the label in the DOM for
   * accessibility.
   */
  @Input({ transform: booleanAttribute }) showLabel = true;

  /**
   * Force the form field to show as disabled.
   * The form field will automatically show as disabled if the wrapped input is also disabled.
   */
  @Input({ transform: booleanAttribute }) disabled = false;

  /**
   * Force the form field to show as error.
   * The form field will automatically show as error if the wrapped input has a validation error or there is a <glxy-error> inside the form-field.
   */
  @Input({ transform: booleanAttribute }) forceError = false;

  /**
   * Force the form field to show as required.
   * The form field will automatically show as required if the wrapped input is also required.
   */
  @Input({ transform: booleanAttribute }) required = false;

  /**
   * Force the form field to show required validation error.
   * The form field will automatically show required validation error if the wrapped input also has a required validation error.
   */
  @Input({ transform: booleanAttribute }) forceRequiredError = false;

  /**
   * Hide the required tag in the form's label
   */
  @Input({ transform: booleanAttribute }) hideRequiredLabel = false;

  /**
   * Set the max width of the form field. A shorthand for setting the max-width via css
   */
  @Input() maxWidth?: number | string;

  /**
   * Set the min width of the form field. A shorthand for setting the min-width via css
   */
  @Input() minWidth?: number | string;

  /**
   * Hide or show a light grey container around Material Radio Buttons, Checkboxes, and Sliders inside a form field component
   */
  @HostBinding('class.show-input-decoration')
  @Input({ transform: booleanAttribute })
  showInputDecoration = false;

  /**
   * Experimental. Use the form field in a vertical layout with the label beside the input
   */
  @HostBinding('class.horizontal-layout')
  @Input({ transform: booleanAttribute })
  horizontalLayout = false;

  @HostBinding('class.bottom-spacing--default')
  get bottomSpacingDefault(): boolean {
    return this.bottomSpacing === 'default';
  }

  @HostBinding('class.bottom-spacing--small')
  get bottomSpacingSmall(): boolean {
    return this.bottomSpacing === 'small';
  }

  @HostBinding('class.glxy-form-field-disbled')
  get disabledState(): boolean {
    return this._control?.disabled || this.disabled;
  }

  @HostBinding('class.glxy-form-field-required')
  get requiredState(): boolean {
    return this._control?.required || this.required;
  }

  @HostBinding('style.max-width')
  get getMaxWidth(): string | null {
    if (this.maxWidth) return coerceCssValue(this.maxWidth);
    return null;
  }

  @HostBinding('style.min-width')
  get getMinWidth(): string | null {
    if (this.minWidth) return coerceCssValue(this.minWidth);
    return null;
  }

  @HostBinding('class.glxy-form-field-autofilled')
  get autofillState(): boolean {
    if (this._control?.autofilled) return true;
    return false;
  }

  @HostBinding('class.glxy-form-field-invalid')
  get errorState(): boolean {
    // Show error state if either the matInput's _control says it's in an error state or there are <glxy-error> children visible inside the component
    return (
      (this._errorChildren && this._errorChildren?.length > 0) ||
      this._control?.errorState ||
      this.forceRequiredError ||
      this.forceError
    );
  }

  @ContentChildren(ErrorDirective, { descendants: true }) _errorChildren?: QueryList<ErrorDirective>;

  @ContentChild(MatFormFieldControl) _formFieldControl?: MatFormFieldControl<any>;

  /** Gets the current form field control */
  // Taken from Mat-Input
  // https://github.com/angular/components/blob/master/src/material/form-field/form-field.ts#L273-L279
  get _control(): MatFormFieldControl<any> | undefined {
    return this._explicitFormFieldControl || this._formFieldControl;
  }
  set _control(value) {
    this._explicitFormFieldControl = value;
  }

  get hasInputRequiredError(): boolean {
    return (
      (this._control?.ngControl?.control?.hasError('required') && this._control?.ngControl?.control?.touched) ||
      this.forceRequiredError
    );
  }

  private _explicitFormFieldControl?: MatFormFieldControl<any>;
  private subscriptions: Subscription[] = [];
  private _changeDetectorRef = inject(ChangeDetectorRef);
  private _elementRef = inject(ElementRef);
  public id = '';

  ngOnInit(): void {
    this.id = this._control?.id ? this._control.id : `glxy-form-field-${nextUniqueId++}`;
  }

  ngAfterContentInit(): void {
    this.setupControlAndSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setupControlAndSubscriptions(): void {
    const control = this._control;
    if (control?.controlType) {
      this._elementRef.nativeElement.classList.add(`glxy-form-field-type-${control.controlType}`);
    }
    if (control?.ngControl && control?.ngControl.valueChanges) {
      this.subscriptions.push(
        control.ngControl.valueChanges.pipe().subscribe(() => this._changeDetectorRef.markForCheck()),
      );
    }
    if (control?.stateChanges) {
      this.subscriptions.push(control.stateChanges.pipe().subscribe(() => this._changeDetectorRef.markForCheck()));
    }
  }
}
