import { Inject, Injectable, Optional } from '@angular/core';
import { LEXICON_ROOT_COMPONENT, LexiconApiService } from '@galaxy/lexicon';
import { getLocale, setLanguage } from '@vendasta/galaxy/i18n';
import { Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AtlasDataService } from './data.service';
import { LanguageMap, LanguageMapping } from './language';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { languageDirectionality } from '@vendasta/galaxy/i18n/src/browser-locale/browser-locale';

@Injectable({ providedIn: 'root' })
export class AtlasLanguageService {
  private language$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private internalLanguage$: Observable<string> = this.language$$.asObservable();
  public language$: Observable<string> = this.dataService.language$.pipe(
    switchMap((language: string) => {
      this.language$$.next(language);
      return this.internalLanguage$;
    }),
    shareReplay(1),
  );

  public languageList$: Observable<LanguageMapping[]>;

  constructor(
    private dataService: AtlasDataService,
    @Optional() @Inject(LexiconApiService) lexiconService: LexiconApiService,
    @Optional() @Inject(LEXICON_ROOT_COMPONENT) lexiconRootComponent: string,
  ) {
    this.languageList$ = lexiconService
      ? lexiconService
          .getSupportedLanguages({
            componentName: lexiconRootComponent || '',
          })
          .pipe(
            map((response) => {
              if (response && response.languages) {
                return response.languages
                  .map((l) => ({
                    code: l.languageCode,
                    label: l.languageName,
                    total: l.languageStatistics.total,
                    translated: l.languageStatistics.translated,
                  }))
                  .filter((l) => l.code !== 'meow');
              }
            }),
          )
      : of(LanguageMap);

    this.language$.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe((language) => {
      window.document.body.setAttribute('dir', languageDirectionality(language));
    });
  }

  getLanguage(): string {
    return getLocale();
  }

  setLanguage(language: string, updateConfig = true): void {
    setLanguage(language);
    this.language$$.next(language);
    if (updateConfig) {
      this.dataService
        .setLanguage(language)
        .pipe(take(1))
        .subscribe(() => {
          window.location.reload();
        });
    } else {
      // Reload immediately if we do not need to wait to update the user's language
      window.location.reload();
    }
  }
}
