import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';
import { LexiconModule } from '@galaxy/lexicon';
import { VaBreadcrumbsModule } from '../va-breadcrumbs/va-breadcrumbs.module';
import { VaFilterModule } from '../va-filter/filter.module';
import { VaProductNavItemComponent } from './va-product-nav-item.component';
import { VaProductNavComponent, VaNavCustomSideContentDirective } from './va-product-nav.component';
import { MobileActionBarComponent } from './mobile-action-bar.component';
import { VaProductNavService } from './va-product-nav.service';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';
import { AtlasModule } from '@galaxy/atlas';
import { GalaxyBadgeModule } from '@vendasta/galaxy/badge';

const materialModules = [
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatButtonModule,
  MatMenuModule,
  MatRadioModule,
  MatBadgeModule,
  MatInputModule,
  MatChipsModule,
];

@NgModule({
  declarations: [
    VaProductNavComponent,
    VaProductNavItemComponent,
    VaNavCustomSideContentDirective,
    MobileActionBarComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    VaBreadcrumbsModule,
    VaFilterModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    materialModules,
    AtlasModule,
    GalaxyBadgeModule,
  ],
  exports: [VaProductNavComponent, VaProductNavItemComponent, VaNavCustomSideContentDirective],
  providers: [],
})
export class VaProductNavModule {
  static forRoot(): ModuleWithProviders<VaProductNavModule> {
    return {
      ngModule: VaProductNavModule,
      providers: [VaProductNavService],
    };
  }
}
