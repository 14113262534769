import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { VaBreadcrumbsComponent } from './va-breadcrumbs.component';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';

@NgModule({
  declarations: [VaBreadcrumbsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
  ],
  exports: [VaBreadcrumbsComponent],
})
export class VaBreadcrumbsModule {}
