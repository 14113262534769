<ng-content></ng-content>
<div
  *ngIf="collapsible"
  class="atlas-navbar__panel-header__dropdown-icon"
  [class.atlas-navbar__panel-header__dropdown-icon--collapsed]="collapsed"
>
  <button atlasIconButton (click)="collapseClicked($event)">
    <i class="material-icons">keyboard_arrow_up</i>
  </button>
</div>
