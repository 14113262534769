import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VaMultilineComponent } from './va-multiline.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [VaMultilineComponent],
  exports: [VaMultilineComponent],
})
export class VaMultilineModule {}
