import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AtlasMenuService {
  private closeMenuEventSource = new Subject<string>();

  closeMenuEvent$ = this.closeMenuEventSource.asObservable();

  closeMenu(id: string): void {
    this.closeMenuEventSource.next(id);
  }
}
