import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import baseTranslation from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from '../constants';
import { VaSearchBoxModule } from '../search-box/va-search-box.module';
import { VaItemsAndStatusModule } from '../va-items-and-status/va-items-and-status.module';
import { VaMultiSelectModule } from '../va-multi-select/va-multi-select.module';
import { VaSearchSelectModule } from '../va-search-select/va-search-select.module';
import { AppliedFiltersBarComponent } from './applied-filters-bar.component';
import { ExportFilterDialogComponent } from './export-filter-dialog.component';
import { FilterContainerComponent } from './filter-container.component';
import { FilterComponent } from './filter.component';
import { CheckboxFilterFieldComponent } from './filters/checkbox/filter-field-checkbox.component';
import { DatePickerFilterFieldComponent } from './filters/date-picker/filter-field-date-picker.component';
import { FilterFieldComponent } from './filters/filter-field.component';
import { FilterFieldDirective } from './filters/filter.directive';
import { ItemsAndStatusFilterFieldComponent } from './filters/items-and-status/filter-field-items-and-status.component';
import { MultiItemsAndStatusFilterFieldComponent } from './filters/multi-items-and-status/filter-field-multi-items-and-status.component';
import { MultiSelectFilterFieldComponent } from './filters/multi-select/filter-field-multi-select.component';
import { RadioFilterFieldComponent } from './filters/radio/filter-field-radio.component';
import { RangeFilterFieldComponent } from './filters/range/filter-field-range.component';
import { SearchSelectFilterFieldComponent } from './filters/search-select/filter-field-search-select.component';
import { TabbedFilterFieldComponent } from './filters/tabbed/filter-field-tabbed.component';
import { TextFilterFieldComponent } from './filters/text/filter-field-text.component';
import { ImportFilterDialogComponent } from './import-filter-dialog.component';
import { ManageSavedFiltersDialogComponent } from './manage-saved-filters-dialog.component';
import { SaveAppliedFiltersDialogComponent } from './save-applied-filters-dialog.component';
import { SavedFiltersMenuComponent } from './saved-filters-menu.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    VaSearchBoxModule,
    VaSearchSelectModule,
    VaItemsAndStatusModule,
    VaMultiSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GalaxyFormFieldModule,
    MatFormFieldModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    MatMomentDateModule,
    LayoutModule,
    MatTooltipModule,
    GalaxyWrapModule,
    MatChipsModule,
  ],
  declarations: [
    FilterComponent,
    FilterFieldDirective,
    FilterContainerComponent,
    FilterFieldComponent,
    AppliedFiltersBarComponent,
    CheckboxFilterFieldComponent,
    DatePickerFilterFieldComponent,
    SearchSelectFilterFieldComponent,
    ItemsAndStatusFilterFieldComponent,
    MultiItemsAndStatusFilterFieldComponent,
    MultiSelectFilterFieldComponent,
    TextFilterFieldComponent,
    TabbedFilterFieldComponent,
    RadioFilterFieldComponent,
    SaveAppliedFiltersDialogComponent,
    ManageSavedFiltersDialogComponent,
    SavedFiltersMenuComponent,
    RangeFilterFieldComponent,
    ExportFilterDialogComponent,
    ImportFilterDialogComponent,
  ],
  exports: [
    FilterComponent,
    FilterContainerComponent,
    AppliedFiltersBarComponent,
    SavedFiltersMenuComponent,
    FilterFieldDirective,
    ExportFilterDialogComponent,
    ImportFilterDialogComponent,
  ],
  providers: [],
})
export class VaFilterModule {}
