import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnDefinition, GroupDefinition, Pinned } from './advanced-column-organizer';
import { take } from 'rxjs/operators';
import { AdvancedColumnOrganizerComponent } from './advanced-column-organizer.compontent';

export interface DialogData {
  saveName: string | undefined;
  columnConfigs: ColumnDefinition[];
  groupConfigs: GroupDefinition[];
  useI18NTranslations: boolean;
}

@Component({
  selector: 'va-mat-table-advanced-column-organizer-button',
  template: '<button mat-stroked-button (click)="openManager()"><ng-content  /></button>',
})
export class AdvancedColumnOrganizerButtonComponent implements OnInit {
  @Input() saveName?: string;
  @Input() columnConfigs: ColumnDefinition[];
  @Input() groupConfigs: GroupDefinition[];
  @Input() useI18NTranslations?: boolean;
  @Output() columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.emitInitialVisibleColumns();
  }

  emitInitialVisibleColumns(): void {
    window.requestAnimationFrame(() => {
      if (this.saveName) {
        const storedVisibleColumns: string | null = localStorage.getItem(`${this.saveName}-visible-column-ids`);
        if (storedVisibleColumns) {
          try {
            const visibleColumns: string[] = JSON.parse(storedVisibleColumns);
            if (visibleColumns) {
              this.columnsChange.emit(visibleColumns);
              return;
            }
          } catch (error) {
            console.error('Could not parse storedVisibleColumns', error);
          }
        }
      }
      if (this.columnConfigs) {
        const cols = this.columnConfigs
          .filter((col) => !col.hidden && col.pinned != Pinned.PINNED_LEFT && col.pinned != Pinned.PINNED_RIGHT)
          .map((col) => col.id);
        this.columnsChange.emit(cols);
      }
    });
  }

  openManager(): void {
    const dialogData: DialogData = {
      saveName: this.saveName,
      columnConfigs: this.columnConfigs,
      groupConfigs: this.groupConfigs,
      useI18NTranslations: !!this.useI18NTranslations,
    };
    const dialogRef = this.dialog.open(AdvancedColumnOrganizerComponent, {
      width: '800px',
      panelClass: 'advanced-column-organizer-panel',
      autoFocus: false,
      data: dialogData,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((save) => {
        if (save) {
          this.columnsChange.emit(dialogRef.componentInstance.selectedColumns);
        }
      });
  }
}
