import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { VaButtonModule } from '../wrappers/va-button/va-button.module';
import { VaCheckboxModule } from '../wrappers/va-checkbox/va-checkbox.module';
import { VaChipModule } from '../wrappers/va-chip/va-chip.module';
import { VaExpandModule } from '../wrappers/va-expand/va-expand.module';
import { VaMenuModule } from '../wrappers/va-menu/va-menu.module';
import { VaMultilineModule } from '../wrappers/va-multiline/va-multiline.module';
import { VaSwitchModule } from '../wrappers/va-switch/va-switch.module';

import { TranslateModule } from '@ngx-translate/core';
import { CellDirective } from './cells/cell.directive';
import { ColumnSelectorComponent } from './column-selector.component';
import { TableComponent } from './table.component';
import {
  AsObjectCellPipe,
  AsTextCellPipe,
  GetMaxWidthPipe,
  GetMinWidthPipe,
  GetSortingIndicatorClassPipe,
  IsSortedPropertyPipe,
  RowStatusCssPipe,
  TextDirectionPipe,
} from './table.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatListModule,
    MatCardModule,
    VaButtonModule,
    VaCheckboxModule,
    VaExpandModule,
    VaMultilineModule,
    VaSwitchModule,
    VaMenuModule,
    VaChipModule,
    TranslateModule,
  ],
  declarations: [
    TableComponent,
    ColumnSelectorComponent,
    CellDirective,
    GetSortingIndicatorClassPipe,
    AsTextCellPipe,
    AsObjectCellPipe,
    GetMinWidthPipe,
    GetMaxWidthPipe,
    RowStatusCssPipe,
    TextDirectionPipe,
    IsSortedPropertyPipe,
  ],
  exports: [TableComponent, ColumnSelectorComponent],
})
export class VaTableModule {}
