import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AvatarComponent, defaultBackgroundColor } from './avatar.component';
export { AvatarComponent, defaultBackgroundColor };

import { AvatarGroupComponent, AvatarGroupItem } from './avatar-group/avatar-group.component';
export { AvatarGroupComponent, AvatarGroupItem };

export const MODULE_IMPORTS = [CommonModule, MatIconModule, MatMenuModule, MatTooltipModule];

export const MODULE_DECLARATIONS = [AvatarComponent, AvatarGroupComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: MODULE_DECLARATIONS,
})
export class GalaxyAvatarModule {}
