/*
NOTE:
This is the expected interface of the fields inputted into this component from the va-filter2-table
component. Changing any fields here would require changing fields in the va-filter2-table
definitions respective interface. If the interface changes you might have to do some mapping or update
the implementation of the organizer to maintain functionality.
/frontend/angular/projects/va-filter2-table/src/lib/va-table-definition/va-table-definition.ts
*/
export enum Pinned {
  PINNED_UNSET = 0,
  PINNED_LEFT = 1,
  PINNED_RIGHT = 2,
}

export interface ColumnDefinition {
  id: string;
  displayName: string;
  pinned?: Pinned;
  hidden?: boolean;
}

export interface ColumnInfo {
  id: string;
  name: string;
  isVisible: boolean;
  pinned?: Pinned;
}

export interface GroupDefinition {
  id: string;
  name: string;
  columns: string[];
}

export interface GroupInfo {
  id: string;
  name: string;
  columns: ColumnInfo[];
}
