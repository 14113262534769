import { Injectable } from '@angular/core';
import { EnvironmentService, Environment } from '@galaxy/core';

const demoHost = 'https://www.smblogin-demo.com';
const prodHost = 'https://www.smblogin.com';

@Injectable({ providedIn: 'root' })
export class HostService {
  private _httpsHost: string;

  constructor(private environmentService: EnvironmentService) {}

  httpsHost(): string {
    if (this._httpsHost) {
      return this._httpsHost;
    }

    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        this._httpsHost = demoHost;
        break;
      case Environment.TEST:
        this._httpsHost = demoHost;
        break;
      case Environment.DEMO:
        this._httpsHost = demoHost;
        break;
      case Environment.PROD:
        this._httpsHost = prodHost;
        break;
    }
    return this._httpsHost;
  }
}
