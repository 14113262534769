import { Component } from '@angular/core';
import { AtlasDataService } from '../../../core/src';
import { UserSwitcherData } from '@vendasta/atlas';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'atlas-user-switcher-modal',
  templateUrl: './user-switcher-modal.component.html',
  styleUrl: './user-switcher-modal.component.scss',
})
export class UserSwitcherModalComponent {
  constructor(private dataService: AtlasDataService, private dialogRef: MatDialogRef<UserSwitcherModalComponent>) {}

  userData$: Observable<UserSwitcherData[]> = this.dataService.userSwitcherData$;

  closeDialog(): void {
    this.dialogRef.close();
  }
}
