import { Theming, UITheme } from '@vendasta/atlas';

export function buildTheming(theme?: UITheme) {
  if (theme === UITheme.UI_THEME_LIGHT) {
    return new Theming({
      primaryColor: '#fff',
      primaryHoverColor: '#f0f0f0',
      primaryActiveColor: '#e1e1e1',
      secondaryColor: 'rgba(0, 0, 0, .04)',
      secondaryHoverColor: 'rgba(0, 0, 0, .04)',
      secondaryActiveColor: 'rgba(0, 0, 0, .1)',
      fontColor: 'rgba(0, 0, 0, 0.54)',
      fontDisabledColor: 'rgba(0, 0, 0, 0.25)',
      accentsColor: '#000',
      accentsActiveColor: '#057ec1',
      focusColor: '#bbdefb',
      borderColor: '#dadada',
    });
  } else {
    // theme is undefined, or dark mode is set
    return new Theming({
      primaryColor: '#212121',
      primaryHoverColor: '#303030',
      primaryActiveColor: '#3f3f3f',
      secondaryColor: 'rgba(255, 255, 255, .04)',
      secondaryHoverColor: 'rgba(255, 255, 255, .04)',
      secondaryActiveColor: 'rgba(255, 255, 255, .1)',
      fontColor: 'rgba(255, 255, 255, 0.7)',
      fontDisabledColor: 'rgba(255,255,255, 0.50)',
      accentsColor: '#fff',
      accentsActiveColor: '#057ec1',
      focusColor: '#616161',
      borderColor: '#9a9b9a',
    });
  }
}
