import { Injectable } from '@angular/core';
import { Environment } from '@galaxy/core';
import { Realtime } from './interface';
import { query, collection, Query, Firestore } from 'firebase/firestore';

@Injectable()
export class FirestoreDB<T> implements Realtime<T> {
  newChangeQuery(firestore: Firestore, env: Environment, email: string): Query<T[]> {
    const environment = env === Environment.PROD ? 'prod' : 'demo';
    // firebase requires this to be something, however we can subscribe to a value change of an unknown document.
    email = email || '(unknown)';

    return query(collection(firestore, `env/${environment}/user/${email}/notification`)) as Query<T[]>;
  }
}
