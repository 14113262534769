import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabComponent } from './tab.component';

@Component({
  selector: 'atlas-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements AfterContentInit, OnDestroy {
  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  @ContentChildren(TabComponent, { read: ElementRef }) _tabs: QueryList<ElementRef>;
  public tabLabels: string[] = [];
  private _destroyed$$: Subject<void> = new Subject<void>();

  constructor(private renderer: Renderer2) {}

  ngAfterContentInit(): void {
    this.tabLabels = this._tabs.map((tab: ElementRef) => tab.nativeElement.attributes.getNamedItem('label').value);
    this._tabs.changes.pipe(takeUntil(this._destroyed$$)).subscribe((tabs: ElementRef[]) => {
      this.tabLabels = tabs.map((tab: ElementRef) => tab.nativeElement.attributes.getNamedItem('label').value);
    });
  }

  setSelectedTab(selectedTab: string): void {
    this._tabs.forEach((tab: ElementRef) => {
      this.renderer.setStyle(
        tab.nativeElement,
        'display',
        tab.nativeElement.attributes.getNamedItem('label').value === selectedTab ? 'inherit' : 'none',
      );
    });
    this.selectionChange.emit(selectedTab);
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._destroyed$$.complete();
  }
}
