import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class ModalService {
  dialog = inject(MatDialog);

  openList<T, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, {
      width: '800px',
      height: '90vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  openInformational<T, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, {
      width: '800px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  openFullscreen<T, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
